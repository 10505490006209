import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';

import {environment} from '../../environment';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {


    constructor(private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe ) {
    }

    checkCountrysOrganizations(countryCode: string) {
        console.log('checkCountrysOrganizations');
        return this.httpService.getPublic(environment.AUTH_ROOT_URL + '/organizations?country=eq.' + countryCode.toUpperCase(), null, true)
            .pipe(map(r => {
                console.log(r);
                return r ;
            }));
    }

    getOrganizationDetails(id: string) {
        console.log('checkCountrysOrganizations');
        return this.httpService.getPublic(environment.AUTH_ROOT_URL + '/organizations?organization=eq.' + id, null, true)
            .pipe(map(r => {
                console.log(r);
                return r ;
            }));
    }
}
