import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {ScreeningService} from '../../../services/screening.service';
import {TranslateService} from '@ngx-translate/core';
import {SingleSetting} from '../../../models/single.setting.model';
import {Subscription} from 'rxjs';
import {FileData} from '../filedata.model';
import {VisitorService} from '../../../services/visitor.service';
import {ImageBrowserComponent} from '../image-browser/image-browser.component';
import {Location} from '@angular/common';
import {TranslationsCommonService} from '../../../services/translations.common.service';

@Component({
  selector: 'app-doctor-reference-modal',
  templateUrl: './doctor-reference-modal.component.html',
  styleUrls: ['./doctor-reference-modal.component.scss'],
})
export class DoctorReferenceModalComponent implements OnInit, OnDestroy {
  @Input() testType: string;
  @Input() student: string;
  @Input() referenceId: string;
  @Input() testId: string;
  @Input() createMode: boolean;

  creatNoteDisabled = false;


  NEW_NOTE_SYMBOL = 'NEW_NOTE';

  fileName = '';
  fileType = '';
  base64String = '';
  description = '';

  isLoading = false;
  isUploaded = false;

  DOCTOR_REFERENCE_ID = '';

  referenceData = null;
  referenceFiles = [];

  noteSaveSubmitVisible = false;

  doctor_reference_notes = '';

  is_urgent = false;
  is_followup = false;

  currentNoteId = '';
  notesToDisplay = [];

  UPDATE_NOTE_doctor_reference_section_name= 'UPDATE_NOTE_doctor_reference_' + this.testType + '_' + this.student; // for locating submit section calls
  CREATE_doctor_reference_section_name = 'CREATE_doctor_reference_' + this.testType + '_' + this.student; // for locating submit section calls
  UPDATE_doctor_reference_section_name = 'UPDATE_doctor_reference_' + this.testType + '_' + this.student; // for locating submit section calls
  NOTE_ADDITIONAL_OR_EDIT_section = 'NOTE_ADDITIONAL_OR_EDIT_section_' + this.currentNoteId + this.testType + '_' + this.student; // for locating submit section calls

  submitSectionChangedSub: Subscription;
  fileLoadedChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private screeningService: ScreeningService,
              private translate: TranslateService,
              private visitorService: VisitorService,
              private translationsCommonService: TranslationsCommonService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionChangedSub) {
      this.submitSectionChangedSub.unsubscribe();
    }

    if (this.fileLoadedChangedSub) {
      this.fileLoadedChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.referenceId){
      this.DOCTOR_REFERENCE_ID = this.referenceId;
      this.loadFiles();
      this.loadReference();
    }

    // tslint:disable-next-line:max-line-length
    this.CREATE_doctor_reference_section_name = 'CREATE_doctor_reference_' + this.testType + '_' + this.student; // for locating submit section calls
    // tslint:disable-next-line:max-line-length
    this.UPDATE_doctor_reference_section_name = 'UPDATE_doctor_reference_' + this.testType + '_' + this.student; // for locating submit section calls

    this.submitSectionChangedSub = this.coreService.submitSectionsChanged.subscribe(update => {
      update.map(i => {
        if (i.submitBroadcasted && i.sectionName === this.CREATE_doctor_reference_section_name) {
            this.screeningService.screening_reference_create(this.student,
                                                             this.testType,
                                                             this.doctor_reference_notes,
                                                             this.testId, this.is_urgent, this.is_followup).subscribe(doctorReference => {
            this.DOCTOR_REFERENCE_ID = doctorReference.referral;
            console.log('received doctor reference: ', this.DOCTOR_REFERENCE_ID);
            this.createMode = false;
            this.creatNoteDisabled = true;

            this.currentNoteId = '';
            this.doctor_reference_notes = '';
            this.loadReference();
          });
        }

        if (i.cancelBroadcasted && i.sectionName === this.CREATE_doctor_reference_section_name) {
          this.modalCtrl.dismiss();
        }

        if (i.cancelBroadcasted && i.sectionName === this.UPDATE_doctor_reference_section_name) {
          this.modalCtrl.dismiss();
        }

        // if (i.sectionName === this.UPDATE_NOTE_doctor_reference_section_name){
        //   if (i.submitBroadcasted) {
        //     // save note
        //     this.screeningService.doctorReferenceUpdateNote(this.DOCTOR_REFERENCE_ID, this.doctor_reference_notes).subscribe(r => {
        //       // hide submit section
        //       this.noteSaveSubmitVisible = false;
        //
        //       // reload data to form
        //       this.loadReference();
        //
        //       // display success toast
        //       this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
        //     }, err => {
        //       console.log(err);
        //       this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
        //       this.isLoading = false;
        //     });
        //   }
        //
        //   if (i.cancelBroadcasted) {
        //     // restore original value
        //     this.doctor_reference_notes = this.referenceData.notes;
        //
        //     // hide submit section
        //     this.noteSaveSubmitVisible = false;
        //   }
        // }



        if (i.sectionName === this.NOTE_ADDITIONAL_OR_EDIT_section){
          if (i.submitBroadcasted) {
            // save note


            if(this.currentNoteId === this.NEW_NOTE_SYMBOL) {
              this.screeningService.doctorReferenceAddNote(this.DOCTOR_REFERENCE_ID, this.doctor_reference_notes).subscribe(r => {
                // hide submit section
                this.noteSaveSubmitVisible = false;
                this.currentNoteId = '';

                // reload data to form
                this.loadReference();

                // display success toast
                this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
              }, err => {
                console.log(err);
                this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
                this.isLoading = false;
              });
            } else {
              this.screeningService.doctorReferenceUpdateNote(this.currentNoteId, this.doctor_reference_notes).subscribe(r => {
                // hide submit section
                this.noteSaveSubmitVisible = false;
                this.currentNoteId = '';

                // reload data to form
                this.loadReference();

                // display success toast
                this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
              }, err => {
                console.log(err);
                this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
                this.isLoading = false;
              });
            }
          }

          if (i.cancelBroadcasted) {
            this.currentNoteId = '';
          }
        }
      });
    });

    this.fileLoadedChangedSub = this.coreService.fileLoadedChanged.subscribe(file => {
      console.log('DoctorReferenceModalComponent.fileLoadedChangedSub');
      this.uploadFileToDb(file);
    });
  }


  // checkIfNotesAvailable() {
  //   if(this.referenceData.notes) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  toggleIsUrgent() {
    if (this.DOCTOR_REFERENCE_ID == '') {
      this.is_urgent = !this.is_urgent;
    }
  }


  toggleIFollowup() {
    if (this.DOCTOR_REFERENCE_ID == '') {
      this.is_followup = !this.is_followup;
    }
  }


  // decideReferenceEditDisabled() {
  //
  //   let isEditDisabled = true;
  //   if (this.DOCTOR_REFERENCE_ID == '') {
  //     isEditDisabled = false;
  //   }
  //
  //   // if (this.DOCTOR_REFERENCE_ID !== '') {
  //   //
  //   //   if (this.referenceData) {
  //   //     if (this.referenceData.status === 'CLOSED') {
  //   //     } else {
  //   //       isEditDisabled = false;
  //   //     }
  //   //   } else {
  //   //     isEditDisabled = false;
  //   //   }
  //   //
  //   // } else {
  //   //   isEditDisabled = false;
  //   // }
  //
  //   return isEditDisabled;
  // }

  decideReferenceEditDisabled() {

    let isEditDisabled = true;

    if (this.DOCTOR_REFERENCE_ID !== '') {

      if (this.referenceData) {
        if (this.referenceData.status === 'CLOSED') {
        } else {
          isEditDisabled = false;
        }
      } else {
        isEditDisabled = false;
      }

    } else {
      isEditDisabled = false;
    }

    return isEditDisabled;
  }

  onNoteUpdated() {


    if (!this.decideReferenceEditDisabled()) {

      if (this.DOCTOR_REFERENCE_ID !== ''){
        this.noteSaveSubmitVisible = true;
      }
    }
    // if (this.DOCTOR_REFERENCE_ID !== '' && this.referenceData.status!=='CLOSED') {
    //   this.noteSaveSubmitVisible = true;
    // }
  }

  startEditingNote(noteId: string) {
    this.doctor_reference_notes = this.referenceData.notes.find(x => x.id === noteId).note;
    this.currentNoteId = noteId;
  }

  loadReference() {
    console.log('this.referenceId: ', this.DOCTOR_REFERENCE_ID);
    this.screeningService.getReference(this.DOCTOR_REFERENCE_ID).subscribe(r => {

      this.isLoading = false;
      this.referenceData = r[0];

      if ( this.referenceData.notes.length > 0) {
        //this.doctor_reference_notes = this.referenceData.notes[0].note;
        this.notesToDisplay = this.referenceData.notes;
        this.is_urgent = this.referenceData.is_urgent;
        this.is_followup = this.referenceData.is_followup;
      }

      console.log('referenceData:');
      console.log(this.referenceData);
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching reference failed ', err);
      this.isLoading = false;
    });
  }

  loadFiles() {
    this.screeningService.getDoctorReferenceFiles(this.DOCTOR_REFERENCE_ID).subscribe(r => {
      this.referenceFiles = r.reverse().slice();
    });
  }

  closeReference() {
    console.log('closeReference: ' + this.referenceData.referral);

    this.screeningService.closeReference(this.referenceData.referral).subscribe(r => {
      this.referenceData = r;
      this.loadFiles();
      this.loadReference();
    });
  }

  uploadFileToDb(uploadedFile: FileData) {
    this.isLoading = true;

    this.screeningService.saveDoctorReferenceFile(
        this.DOCTOR_REFERENCE_ID,
        uploadedFile.file_name,
        uploadedFile.file_type,
        uploadedFile.file_description,
        uploadedFile.base64string).subscribe(r => {

      this.isLoading = false;
      //this.showSucessBadge();

      //this.uploadVisible = false;

      this.base64String = '';
      this.fileType = '';
      this.fileName = '';
      this.description = '';

      this.loadFiles();

      this.coreService.broadcastToastMessage('File uploaded', false);
    }, err => {
      console.log(err);
      this.isLoading = false;
      this.coreService.broadcastErrorMessage('File upload failed ', err);
    });
  }

  startAddingAdditionalNote() {
    this.currentNoteId = this.NEW_NOTE_SYMBOL;
    this.doctor_reference_notes = '';
  }

  openImageBrowser(fileId: string) {
    this.modalCtrl
        .create({
          component: ImageBrowserComponent,
          componentProps: { fileId, section: 'reference' },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  getItemColor(currentColor: string){
    if (currentColor === 'WHITE') {
      return 'black;';
    } else {
      return currentColor;
    }
  }

  getRefStatusColor(status: string){
    if (status === 'OPEN'){
      return 'green';
    }else if (status === 'CLOSE'){
      return 'orange';
    } else {
      return 'black';
    }
  }
}
