import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {VaccineService} from '../../../services/vaccine.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {VisitorService} from '../../../services/visitor.service';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-modal-vaccine-lots-add',
  templateUrl: './modal-vaccine-lots-add.component.html',
  styleUrls: ['./modal-vaccine-lots-add.component.scss'],
})
export class ModalVaccineLotsAddComponent implements OnInit, OnDestroy {

  availableVaccines = [];
  availableProducers = [];
  producersFiltered = [];

  isLoading = false;
  selectedLot = '';

  producerSearchPhrase = '';
  selectedProducer = '';
  selectedExpMonth = '';
  selectedExpYear = '';
  selectedVaccines = [];

  producerFormVisible = false;
  newProducerName = '';
  newProducerCode = '';

  formName = 'addVaccineLot' + this.coreService.generateFormNamePostfix();
  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private vaccineService: VaccineService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private translate: TranslateService,
              private translationsCommonService: TranslationsCommonService) {

    location.onUrlChange((url, state) => {
      this.ngOnInit();
    });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = true;
    this.vaccineService.getVaccines().subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.availableVaccines = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching vaccines failed ', err);
      this.isLoading = false;
    });

    this.vaccineService.getVaccineProducers().subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.availableProducers = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching producers failed ', err);
      this.isLoading = false;
    });

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              //this.router.navigateByUrl('/vaccine-lots'  );
              this.modalCtrl.dismiss();
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.anAddLot();
            }
          }
        }
    );
  }

  updateFilteredProducers() {
    const f1 = this.availableProducers.filter(x => x.producer.toLowerCase().includes(this.producerSearchPhrase.toLowerCase()));
    const f2 = this.availableProducers.filter(x => x.name.toLowerCase().includes(this.producerSearchPhrase.toLowerCase()));
    const f3 = f1.concat(f2);

    const uniqueItems = [...new Set(f3)];

    this.producersFiltered = uniqueItems.slice();
    console.log('producers filtered');
    console.log(this.producersFiltered);
    this.selectedProducer = '';

    if (this.producerFormVisible){
      if (this.producersFiltered.length > 0) {
        this.producerFormVisible = false;
      }
    }
  }

  anAddLot() {
    console.log('onAddLot');
    this.isLoading = true;


    console.log('selectedExpMonth.length:', this.selectedExpMonth.length);
    let expMonthToUse = '';
    if (this.selectedExpMonth.toString().length === 1) {
      expMonthToUse = '0' + this.selectedExpMonth;
    }else{
      expMonthToUse = this.selectedExpMonth;
    }

    console.log('expMonthToUse:', expMonthToUse);

    this.vaccineService.addVaccineLot(
        this.selectedLot,
        expMonthToUse + '/' + this.selectedExpYear,
        this.selectedProducer,
        this.selectedVaccines
    ).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);

      this.vaccineService.broadcastInfoAboutAddedLot(r);

      this.modalCtrl.dismiss();
      //this.router.navigateByUrl('/vaccine-lots');
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
      this.isLoading = false;
    });
  }

  checkIfFormValid() {
    return this.selectedLot !== '' &&
        this.selectedProducer !== '' &&
        this.selectedVaccines.length > 0 &&
        this.selectedExpMonth !== '' &&
        this.selectedExpYear !== '';
  }

  toggleVaccineSelection(vaccine: string) {
    // this.justLoaded = false;
    console.log('toggle vaccine: ', vaccine);

    console.log('local selected vaccines');
    console.log(this.selectedVaccines);

    const index = this.selectedVaccines.indexOf(vaccine);
    if (index < 0){
      this.selectedVaccines.push(vaccine);
    } else {
      this.selectedVaccines.splice(index, 1);
    }
  }

  onAddProducer() {
    console.log('onAddProducer');
    this.isLoading = true;
    this.vaccineService.addProducer(
        this.newProducerCode,
        this.newProducerName
    ).subscribe(r => {
      console.log(r);
      this.isLoading = false;

      const newProducerItem = {producer: this.newProducerCode, name: this.newProducerName};

      this.availableProducers.push(newProducerItem);
      this.selectedProducer = this.newProducerCode;

      const arr = [];
      arr.push(newProducerItem);
      this.producersFiltered = arr.slice();
      this.producerFormVisible = false;

      this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
      this.isLoading = false;
    });
  }

  showProducerForm() {
    this.producerFormVisible = true;
    this.newProducerCode = this.producerSearchPhrase.toUpperCase();
  }
}
