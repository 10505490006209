import { Component, OnInit } from '@angular/core';
import {CoreService} from '../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../services/http.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-lecture-menu',
  templateUrl: './lecture-menu.component.html',
  styleUrls: ['./lecture-menu.component.scss'],
})
export class LectureMenuComponent implements OnInit {

  constructor(private coreService: CoreService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private httpService: HttpService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }
  }

}
