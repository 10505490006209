import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingController, ModalController} from '@ionic/angular';
import {DatePipe, Location} from '@angular/common';
import {HttpService} from '../../../services/http.service';
import {School} from '../school.model';
import {SchoolsService} from '../../../services/schools.service';

@Component({
  selector: 'app-school-multi-selector',
  templateUrl: './school-multi-selector.component.html',
  styleUrls: ['./school-multi-selector.component.scss'],
})
export class SchoolMultiSelectorComponent implements OnInit, OnDestroy {
  @Input() allSelected = false;

  formJustLoaded = true;

  justLoaded = true;
  private availableSchoolsSub: Subscription;
  private selectedSchoolsSub: Subscription;
  private schoolsAvailableChangedSub: Subscription;

  availableSchools = [];
  selectedSchools = [];
  isLoading = false;
  touched = false;

  constructor(private coreService: CoreService,
              private schoolsService: SchoolsService,
              private route: ActivatedRoute,
              private router: Router,
              private loadingCtrl: LoadingController,
              private modalCtrl: ModalController,
              private location: Location,
              private datepipe: DatePipe,
              private httpService: HttpService) {

      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });
  }

  ngOnDestroy() {
    if (this.availableSchoolsSub) {
      this.availableSchoolsSub.unsubscribe();
    }

    if (this.selectedSchoolsSub) {
      this.selectedSchoolsSub.unsubscribe();
    }

      if (this.schoolsAvailableChangedSub) {
          this.schoolsAvailableChangedSub.unsubscribe();
      }

  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    console.log('school-selector ngOnInit');

    this.isLoading = true;
    this.availableSchoolsSub = this.schoolsService.schoolsAvailableChanged
        .subscribe(
            (availableSchools: School[]) => {
              this.availableSchools = availableSchools;

              console.log('schoolsAvailableChanged - in subscription response');
              console.log(availableSchools);
              this.isLoading = false;
            }
        );

      this.schoolsAvailableChangedSub = this.schoolsService.schoolsAvailableChanged
        .subscribe(
            (availableSchools: School[]) => {
              this.availableSchools = availableSchools;

              console.log('schoolsAvailableChanged - in subscription response');
              console.log(availableSchools);

              if (this.allSelected && this.formJustLoaded) {
                  // select all and broadast selection
                  this.selectedSchools = [];
                  this.availableSchools.map(x => {
                      this.selectedSchools.push(x.school_code);
                  });
                  this.schoolsService.setSelectedSchools(this.selectedSchools);
              }

              this.isLoading = false;
              this.formJustLoaded = false;
            }
        );


    this.schoolsService.schoolsSelectedChanged
        .subscribe(
            (selectedSchools: School[]) => {

                this.isLoading = true;
                const formattedNewSchools = [];
                selectedSchools.map(x => {
                    formattedNewSchools.push(x.school_code);
                });

                this.selectedSchools = formattedNewSchools;
                this.isLoading = false;
              // console.log('school-selector schoolsSelectedChanged - in subscription response');
              // console.log(this.selectedSchools);
            }
        );
  }

    checkIfSchoolSelected(school: School){
      const x = this.selectedSchools.find(i => i === school.school_code);
      if (x){
          return true;
      }else{
          return false;
      }
    }

    toggleSchoolSelection(school: School) {
        this.justLoaded = false;
        console.log('toggle school: ', school.school_code);
        this.coreService.broadcastLoadingState();

        setTimeout(() => {
            const index = this.selectedSchools.indexOf(school.school_code);
            if (index < 0) {
                this.selectedSchools.push(school.school_code);
            } else {
                this.selectedSchools.splice(index, 1);
            }

            console.log('local selected schools');
            console.log(this.selectedSchools);

            this.schoolsService.setSelectedSchoolsByCode(this.selectedSchools);
        }, 200);
    }
}
