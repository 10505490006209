import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Student} from '../models/student.model';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from './http.service';
import {Visitor} from '../components/shared/visitor.model';
import {Event} from '../components/shared/event.model';
import {DatePipe} from '@angular/common';
import {School} from '../components/shared/school.model';
import {SchoolClass} from '../components/shared/schoolclass.model';
import { EventEmitter } from '@angular/core';
import {AssignedClass} from '../components/shared/assignedclass.model';
import {DatePickerData} from '../components/shared/datepickerdata.model';
import {SubmitSectionData} from '../components/shared/submitsectiondata.model';
import {FileData} from '../components/shared/filedata.model';
import {Toast} from '../models/toast.model';
import {SingleSetting} from '../models/single.setting.model';
import {isNumeric} from 'rxjs/internal-compatibility';
import { isObservable } from 'rxjs';
import {CommonService} from './common.service';
import {DataSourceInformation} from '../models/datasource.information.model';
import {FileUploadStatus} from '../components/shared/fileuploadstatus.model';
import {TileTypeVisibility} from '../components/shared/tiletestvisibility.model';
import {environment} from '../../environment';
import {version} from '../../version';

@Injectable({
    providedIn: 'root'
})
export class CoreService {

    DATE_FORMAT = 'yyyy-MM-dd';
    TIME_FORMAT = 'HH:mm';
    DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
    DATETIME_FORMAT_ICS = 'yyyyMMdd\'T\'HHmmss';

    STUDENT_LIST_LIMIT = 15;

    version = '';

    ///RESOURCES: https://capacitorjs.com/docs/guides/splash-screens-and-icons

    private _events: Event[] = [];
    private _visitorsAvailable: Visitor[] = [];

    constructor(private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
        // this.loadAvailableStudents().subscribe(s =>{
        //     this.availableStudents = s;
        // });a

        this.version = version.app_version;
    }

    classesAvailableChanged = new EventEmitter<SchoolClass[]>();
    classesSelectedChanged = new EventEmitter<SchoolClass[]>();
    selectedGenderChanged = new EventEmitter<string[]>();
    selectedDateChanged = new EventEmitter<string>();
    datePickerDatesChanged = new EventEmitter<DatePickerData[]>();
    submitSectionsChanged = new EventEmitter<SubmitSectionData[]>();
    fileLoadedChanged = new EventEmitter<FileData>();
    fileSavingResultSub = new EventEmitter<FileUploadStatus>();

    textareaValuChangedSub = new EventEmitter<string>();


    errorMessageChanged = new EventEmitter<string>();
    toastMessageChanged = new EventEmitter<Toast>();
    loadingStateChanged = new EventEmitter<boolean>();
    singleSettingValueChanged = new EventEmitter<SingleSetting>();
    studentChanged = new EventEmitter<string>();
    studentsLoadedChanged = new EventEmitter<any[]>();
    dataSourceIndicatorChanged = new EventEmitter<DataSourceInformation>();



    tileVisibilityChanged = new EventEmitter<TileTypeVisibility>();

    CalendarSyncChanged = new EventEmitter<boolean>();

    RefreshChanged = new EventEmitter<boolean>();

    coreService_stateMessageChanged = new EventEmitter<string>();

    private _classesAvailable: SchoolClass[] = [];
    private _classesSelected: SchoolClass[] = [];
    private _selectedDate = '';
    private _datePickerDates: DatePickerData[] = [];
    private _submitSectionData: SubmitSectionData[] = [];

    availableStudents = [];

    generateFormNamePostfix() {
        return new Date().getTime() + '_' + Math.floor( Math.random() * 9999);
    }


    setSingleValueSetting(singleSetting: SingleSetting) {
        this.singleSettingValueChanged.emit(singleSetting);
    }

    brodcastTestTileVisibiltyChange(tileVisibility: TileTypeVisibility) {
        console.log('brodcastTestTileVisibiltyChange: ');
        this.tileVisibilityChanged.emit(tileVisibility);
    }

    broadcastDataSourceInformation(datasource, message: string,  isError: boolean = false) {
        console.log('broadcastToastMessage: ', message, isError);
        this.dataSourceIndicatorChanged.emit(new DataSourceInformation(datasource, message, isError));
    }

    broadcastStudentUpdate(studentId: string) {
        this.studentChanged.emit(studentId);
    }

    broadcastLoadingState() {
        this.loadingStateChanged.emit(true);
    }

    broadcastTextareaValueChanged(newVal: string) {
            this.textareaValuChangedSub.emit(newVal);
    }

    broadcastFileUploadStatus(fileUploadStatus: FileUploadStatus) {
        this.fileSavingResultSub.emit(fileUploadStatus);
    }

    broadcastToastMessage(message: string, isError: boolean = false) {
        console.log('broadcastToastMessage: ', message, isError);
        //this.toastMessageChanged.emit(new Toast(message, isError));
    }

    broadcastErrorMessage(description: string,  err: any) {
        console.log('broadcastErrorMessage: ', description, err);

        const errorMessage = this.formatDbErrorMessage(err);
        this.errorMessageChanged.emit(errorMessage);

        //disable toast error message because we force showing error window
        //this.toastMessageChanged.emit(new Toast(description, true));
    }

    broadcastRefreshRequest() {
        this.RefreshChanged.emit(true);
    }

    dataURItoBlob(dataURI: string) {
        // convert base64 to raw binary data held in a string
        let byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        let ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        let blob = new Blob([ab], {type: mimeString});
        return blob;
    }

    findFileIcon(datattype: string) {

        let iconToUse = 'image-outline';
        switch (datattype) {
            case 'image': {
                iconToUse = '';
                break;
            }
            case 'video': {
                iconToUse = 'videocam-outline';
                break;
            }
            case 'audio': {
                iconToUse = 'volume-high-outline';
                break;
            }
            case 'text': {
                iconToUse = 'document-outline';
                break;
            }
            case 'pdf': {
                iconToUse = 'document-outline';
                break;
            }
        }

        return iconToUse;
    }

    formatDbErrorMessage(err: any) {

        console.log('formatDbErrorMessage: ');
        console.log(err);
        const NODATA_MESSAGE = 'N/A';

        let hint = 'Please check your internet connection';
        if (err.error != null) {
            if (err.error.hint != null) {
                hint = err.error.hint;
            } else if ( err.error.message){
                hint = err.error.message;
            }
        }


        let details = NODATA_MESSAGE;
        console.log(err.error);
        if (err.error != null) {
            console.log('xxx');
            if (err.error.details !== null) {
                details = err.error.details;
            }
        }

        let code = NODATA_MESSAGE;
        if (err.status) {
            code = err.status;
        }

        let message = NODATA_MESSAGE;
        if (err.statusText) {
            message = err.statusText;
        }



        let url = NODATA_MESSAGE;
        if (err.url) {
            url = err.url;
        }


        if (message === 'Unknown Error') {
            message = err.message;
            hint = 'Please check your internet connection';
        }


        console.log('hint: ', hint);
        console.log('details: ', details);
        console.log('code: ', code);
        console.log('message: ', message);
        console.log('url: ', url);

        const formattedMessage = '<span class="colorToastError"  ><b>' + message + '</b></span>' +
            '<br /><b>Hint:</b> ' + hint +
            '<br /><b>Details:</b> ' + details +
            '<br /><b>Code:</b> ' + code +
            '<br /><b>Url:</b> ' + url;

        return formattedMessage;
    }

    // getLocations(event_type) {
    //     console.log('getLocations');
    //     return this.httpService.get (environment.URL_ROOT + '/getEventLocations?event_type=fts.{' + event_type +  '}')
    //         .pipe(map(r => {
    //             console.log(r);
    //             return r;
    //         }));
    // }

    // addLocation(event_location: string, location_name: string, event_type) {
    //
    //     const postData = {
    //         _event_location: event_location,
    //         _location_name: location_name,
    //         _events: [event_type]
    //     };
    //
    //     console.log(postData);
    //
    //     return this.httpService.post(environment.URL_ROOT + '/rpc/addEventLocation', postData)
    //         .pipe(map(r => {
    //             console.log(r);
    //             return r;
    //         }));
    // }

    saveStudentFile(studentId: string, fileName: string, fileType: string, description: string, base64String: string) {
        const postData = {
            _student: studentId,
            _filename: fileName,
            _description: description,
            _blob: base64String
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/StudentFileAdd', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getStudentFiles(studentId: string) {
        console.log('getStudentFiles, studentId:' + studentId);
        return this.httpService.get(environment.URL_ROOT + '/StudentFiles?student=eq.' + studentId)  // previously: "getStudentFiles"
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during file upload: ', error.message);
                    return error.message;
                }));
    }

    update_imported_row(id: string, parameter_name: string, parameter_value: string) {
        const postData = {
            row_id: id,
            _column: parameter_name,
            _value: parameter_value,
            __visitor_assign_scope: 'SCHOOL',
            __missing_class: 'CREATE'
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/update_imported_row', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    process_imported_students(batch: string) {

        console.log('process_imported_students');
        const postData = {
            batch_id: batch,
            __missing_class: 'CREATE',
            __visitor_assign_scope: 'SCHOOL',
            __parents_action: 'CREATE'
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/process_imported_students', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    csv_import_students(file_name: string, base64: string) {
        const postData = {
            _blob: base64
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/csv_import_students', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    registerFile(newFile: FileData) {
        console.log('registerFile:');
        console.log(newFile);

        // this._fileLoaded = newFile;
        this.fileLoadedChanged.emit(newFile);
    }

    setSubmitSectionUpdate(submitSectionData: SubmitSectionData) {
        // date: string, descriptionLabel: string
        console.log('setSubmitSectionUpdate:');
        console.log(submitSectionData);

        // const itemToUpdate = this._submitSectionData.find(x => x.sectionName === submitSectionData.sectionName);
        //
        // console.log('itemToUpdate');
        // console.log(itemToUpdate);
        // const newArray = [];
        //
        // if (itemToUpdate) {  // https://stackoverflow.com/questions/28975896/is-there-a-way-to-check-for-both-null-and-undefined
        //     itemToUpdate.isDisabled = submitSectionData.isDisabled;
        //     itemToUpdate.cancelBroadcasted = submitSectionData.cancelBroadcasted;
        //     itemToUpdate.submitBroadcasted = submitSectionData.submitBroadcasted;
        //
        //     newArray.push(itemToUpdate);
        // } else {
        //     //this._submitSectionData.push(submitSectionData);
        //
        //     newArray.push(submitSectionData);
        // }
        const newArray = [];
        newArray.push(submitSectionData);
        this.submitSectionsChanged.emit(newArray.slice());
    }

    changeBackgroundInputColor(inputId: string, inputActive: boolean, label = null) {
        console.log('changeBackgroundInputColor');
        console.log(inputId);
        const input = document.getElementById(inputId);
      //  const inputValue = (input as HTMLInputElement).value;

      //  console.log('inputValue: ', inputValue);
        console.log(input);

        console.log('label: ', label);

        if (inputActive) {
            input.style.backgroundColor = 'white';
            input.style.color = 'black';
            input.style.border = '1px solid black;';
        } else {
            input.style.background = 'none';
            input.style.color = 'black';
            input.style.border = '1px solid gray';
        }
    }


    getFile(fileId: string, section: string) {

        let url = environment.URL_ROOT + '/StudentFiles?file=eq.' + fileId;

        if (section === 'student'){
            url = environment.URL_ROOT + '/StudentFiles?file=eq.' + fileId;
        } else if (section === 'reference') {
            url = environment.URL_ROOT + '/GetReferralFiles?file=eq.' + fileId;
        } else if (section === 'visitor') {
            url = environment.URL_ROOT + '/VisitorFiles?file=eq.' + fileId;
        } else if (section === 'subject') {
            url = environment.URL_ROOT + '/SubjectFiles?file=eq.' + fileId;
        }
        console.log('getFile');
        return this.httpService.get(url)
            .pipe(map(r => {
                console.log(r);
                return r[0];
            }));
    }

    setDatePickerDate(datepickerData: DatePickerData) {
        // date: string, descriptionLabel: string
        console.log('setDatePickerDate:');
        console.log(datepickerData);

        const itemToUpdate = this._datePickerDates.find(x => x.descriptionLabel === datepickerData.descriptionLabel);

        const newDatePickerDates = []; // to prevent sending multiple dates when it's not needed

        if (itemToUpdate != null) {
            itemToUpdate.date = datepickerData.date;
            newDatePickerDates.push(itemToUpdate);
        } else {
            this._datePickerDates.push(datepickerData);
            newDatePickerDates.push(datepickerData);
        }

        this.datePickerDatesChanged.emit(newDatePickerDates.slice());
    }

    setSelectedDate(date: string) {
        console.log('setSelectedDate:');
        console.log(date);
        this._selectedDate = date;
        this.selectedDateChanged.emit(this._selectedDate);
    }

    setSelectedGenders(genders: string[]) {
        console.log('setSelectedGenders: ', genders);
        this.selectedGenderChanged.emit(genders);
    }

    addParent(student: string, first_name: string, last_name: string, email: string, phonePrefix: string, phone: string, notes: string) {

        const postData = {
            _student: student,
            _first_name: first_name,
            _last_name: last_name,
            _email: email,
            _mobile: { prefix: phonePrefix, number: phone },
            _notes: notes
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/addParent', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addLecture(subjectId: string, selectedClasses: string[], startDt: string, endDt: string, notes: string) {
        const classesString = this.buildIdsString(selectedClasses);
        const postData = {
            _classes: classesString,
            _subject: subjectId,
            _start_dt: startDt,
            _end_dt: endDt,
            _notes: notes
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/AddLecture', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    buildIdsString(itemsSelected: string[], brackets: string = '{}') {
        let itemsString = '';
        let ind = 1;
        itemsSelected.map(x => {
            itemsString = itemsString + x;
            if (ind < itemsSelected.length){
                itemsString = itemsString + ',';
            }

            ind ++;
        });

        if (brackets === '{}') {
            const itemIds = '{' + itemsString + '}';
            return itemIds;
        } else {
            const itemIds = '[' + itemsString + ']';
            return itemIds;
        }
    }

    getParents(studentId: string) {
        console.log('getParents');
        return this.httpService.get(environment.URL_ROOT + '/StudentsParents?student?student=eq.' + studentId) //#test ready
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    getHomeStudentForms(studentId: string) {
        console.log('getHomeStudentForms');
        return this.httpService.get(environment.URL_ROOT + '/HomeStudentForms?student?student=eq.' + studentId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    saveSubjectFile(subjectId: string, fileName: string, fileType: string, description: string, base64String: string) {
        const postData = {
            _subject: subjectId,
            _filename: fileName,
            _description: description,
            _blob: base64String
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/LecturesSubjectFileAdd', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getSubjectFiles(subjectId: string) {
        console.log('getSubjects');
        return this.httpService.get(environment.URL_ROOT + '/GetSubjectFiles?subject=eq.' + subjectId)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during file upload: ', error.message);
                    return error.message;
                }));
    }

    getSubjects() {
        console.log('getSubjects');
        return this.httpService.get(environment.URL_ROOT + '/GetLectureSubjects')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    disputeMarkRead(disputeId: string) {
        const postData = {
            is_read: 'true'
        };

        console.log(postData);
        return this.httpService.patch(environment.URL_ROOT + '/Disputes?dispute=eq.' + disputeId, postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getDisputeMessages(eventId: string) {
        console.log('getDisputeMessages');
        return this.httpService.get(environment.URL_ROOT + '/Disputes?event=eq.' + eventId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addDisputeMessage(message: string, eventId: string) {
        const postData = {
            event: eventId,
            author: 'v1',
            notes: message
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/Disputes', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

///////////////////////////////////////////////////////////////////////////////
// STUDENTS
//////////////////////////////////////////////////////////////////////////////
    getStudentVBookNEW(studentId: string, showEmpty: boolean = true) {
        console.log('getStudentVBookNEW');

        const postData = {
            _student: studentId
        };

        let url = environment.URL_ROOT + '/rpc/GetStudentVaccineBook';
        if (showEmpty === false) {
            url = environment.URL_ROOT + '/rpc/GetStudentVaccineBook?shot_date=not.is.null';
        }

        console.log(url);

        return this.httpService.post(url, postData).pipe(map(vbookData => {
            console.log(vbookData);
            return vbookData;
        }));
    }

    getStudent(studentId: string) {
        console.log('getStudent: ', studentId);
        return this.httpService.get (environment.URL_ROOT + '/Students?student=eq.' + studentId)
            .pipe(map(studentData => {

                console.log('studentData from api:');
                console.log(studentData);
                return studentData;
            }, err => {
                console.log(err);
            }));
    }

    getStudents(schoolCode: string) {
        console.log('getStudents: school_code:', schoolCode);
        return this.httpService.get (environment.URL_ROOT + '/Students?school=eq.' + schoolCode)
            .pipe(map(studentData => {
                // console.log(studentData);
                return studentData;
            }));
    }


    // getStudentsGlobal(params: string) {
    //     console.log('getStudentsGlobal: params:', params);
    //     return this.httpService.get (environment.URL_ROOT + '/students?' + params)
    //         .pipe(map(studentData => {
    //            console.log(studentData);
    //            return studentData;
    //         }));
    // }

    getStudentsGlobal() {
        console.log('getStudentsGlobal');

        // TODO: list ALL students (when GET:getStudents will be ready in db)
        // const students: string[] = [];
        // students.push('s1', 's2', 's3');


        return this.httpService.get (environment.URL_ROOT + '/Students' , null)
            .pipe(
                map(r => {
                    return r;
                })
            );

        // return this.httpService.get(environment.URL_ROOT + '/students' , null)
        //     .then(response => {
        //         console.log('/students:');
        //         console.log(response);
        //         response.subscribe(rr => {
        //             console.log('result from subscribe (getStudentsGlobal)');
        //             console.log(rr);
        //
        //             if (rr === undefined) {
        //                 console.log('rr is undefined');
        //             } else {
        //
        //                 this.studentsLoadedChanged.emit(rr);
        //                 return rr;
        //
        //                 // rr.then( x2 => {
        //                 //     return x2;
        //                 // });
        //             }
        //         });
           // });
    }



    getStudentsBySchool(schoolCode: string) {
        const postData = {
            _school: schoolCode
        };

        console.log('getStudentsBySchool postData:');
        console.log(postData);


        // return this.httpService.post_hashed(environment.URL_ROOT + '/rpc/GetStudentsBySchool', postData)
        //     .pipe(
        //         map(r => {
        //             this.studentsLoadedChanged.emit(r);
        //             return r;
        //         })
        //     );



        return this.httpService.post_hashed(environment.URL_ROOT + '/rpc/GetStudentsBySchool', postData)
                .then(response => {
                        // console.log('loading aGetStudentsBySchool:');
                        // console.log(response);
                        response.subscribe(rr => {
                            console.log('result from subscribe (coreService.post_hashed)');
                            console.log(rr);

                            if (rr === undefined) {
                                console.log('rr is undefined');
                            } else {
                                console.log('rr:');
                                console.log(rr);

                                if (rr.data) {
                                    //return this.processAvailableSchools(rr);
                                    this.studentsLoadedChanged.emit(rr.data);
                                    return rr.data;
                                } else {
                                    rr.then(x2 => {
                                      //  return this.processAvailableSchools(x2);
                                        this.studentsLoadedChanged.emit(x2.data);
                                        return x2;
                                    });
                                }
                            }
                        });
                    });

    }




    loadEvents(showOnlyVisible: boolean = true, event_type: string = null) {
        console.log('loading events');

        let parameterToUse = 'visible=eq.true';
        if (showOnlyVisible === false){
            parameterToUse = ''; // 'visible=eq.both';
        }

        if (event_type !== null && event_type !== ''){
            parameterToUse  = parameterToUse + '&event_type_raw=eq.' + event_type;
        }

        const urlToUse = environment.URL_ROOT + '/Events?' + parameterToUse;
        console.log('urlToUse: ', urlToUse);

        return this.httpService.get (urlToUse)
            .pipe(
                map(r => {
                    return r;
                })
            );
    }

    loadAllEvents() {
        console.log('loading all events');

        const urlToUse = environment.URL_ROOT + '/Events?';
        console.log('urlToUse: ', urlToUse);

        return this.httpService.get (urlToUse)
            .pipe(
                map(r => {
                    return r;
                })
            );
    }

    hideEvent(eventId: string) {
        const postData = {
            _event: eventId,
            updates: {
                visible: false
            }
        };

        return this.httpService.post(environment.URL_ROOT + '/rpc/setEvent', postData)
            .pipe(
                map(r => {
                    console.log(r);
                    this.CalendarSyncChanged.emit(true);
                    return r;
                })
            );
    }

    updateEvent(event: Event){
        const startDate = this.datepipe.transform(event.start_dt, this.DATETIME_FORMAT);
        let endDate = null;
        if (event.end_dt === null) {

            const postData = {  start_dt: startDate,
                                duration: event.duration,
                                // name: event.name,
                                // event_location: event.location,
                                notes: event.notes
                             };

            console.log('POSTING DATA:...');
            console.log(postData);

            return this.httpService.patch(environment.URL_ROOT + '/TCalendarEvents?event=eq.' + event.event, postData)
                .pipe(
                    map(r => {
                        console.log(r);
                        this.CalendarSyncChanged.emit(true);
                        return r;
                    })
                );
        } else {
            endDate = this.datepipe.transform(event.end_dt, this.DATETIME_FORMAT);

            const postData = {
                                start_dt: startDate,
                                end_dt: endDate,
                                // name: event.name,
                                // event_location: event.location,
                                notes: event.notes
                             };

            console.log('POSTING DATA:...');
            console.log(postData);

            return this.httpService.patch(environment.URL_ROOT + '/TCalendarEvents?event=eq.' + event.event, postData)
                .pipe(
                    map(r => {
                        console.log(r);
                        this.CalendarSyncChanged.emit(true);
                        return r;
                    })
                );
        }
    }

    // getVisitors() {
    //     console.log('getVisitors');
    //
    //     return this.httpService.get (environment.URL_ROOT + '/TVisitors')
    //         .pipe(
    //             map(r => {
    //                 console.log(r);
    //
    //                 const newVisitors: Visitor[] = [];
    //                 this._visitorsAvailable = r.map(x => {
    //                     newVisitors.push(new Visitor([], x.visitor, x.first_name, x.last_name));
    //                 });
    //
    //                 this._visitorsAvailable = newVisitors;
    //                 console.log('_visitorsAvailable:');
    //                 console.log(this._visitorsAvailable);
    //                 return this._visitorsAvailable;
    //             })
    //         );
    // }

    getEvent(eventId) {
        console.log('getEvent');
        return this.httpService.get(environment.URL_ROOT + '/Events?event=eq.' + eventId)
            .pipe(
                map(r => {
                    console.log(r[0]);
                    return r[0];
                })
            );
    }

//////////////////////// CLASSES////////////////////////////////////////////
    loadClasses(school: string) {

        const postData = {
            _school: school
        };

        console.log('loadClasses...');
        console.log(postData);

        const url = environment.URL_ROOT + '/rpc/GetClassesOfSchool';
        console.log(url);

        return this.httpService.post(url, postData)
            .pipe(
                map(r => {
                    this.processClasses(r);
                    return r;
                })
            );


        // return this.httpService.post_hashed(url, postData)
        //     .then(response => {
        //         console.log('loading available classes:');
        //         console.log(response);
        //
        //         response.subscribe(rr => {
        //             console.log('result from subscribe (load Classes)');
        //             console.log(rr);
        //
        //             if (rr === undefined) {
        //                 console.log('rr is undefined');
        //             } else {
        //                 if (rr.data) {
        //                     this.processClasses(rr);
        //                     return rr;
        //                 } else {
        //                     rr.then( x2 => {
        //                         this.processClasses(x2);
        //                         return x2;
        //                     });
        //                 }
        //             }
        //         });
        //     });
    }

    private processClasses(rr: any) {
        const availableClasses = [];

        console.log('CLASSES.RRRRRR');
        console.log(rr);

        // this.broadcastToastMessage(rr.message, false);
        this.broadcastDataSourceInformation(rr.dataSource, rr.message, false);
        rr[0].classes.map(x => {
            // availableClasses.push(new SchoolClass(x.class, x.class_name, x.class_letter, x.class_number, x.students_count));
            availableClasses.push(new SchoolClass(x.class, x.class_name, x.class_letter, x.class_number, x.students_count));
        });


        // rr.data[0].classes.map(x => {
        //    // availableClasses.push(new SchoolClass(x.class, x.class_name, x.class_letter, x.class_number, x.students_count));
        //     availableClasses.push(new SchoolClass(x.class, x.class_name, x.class_letter, x.class_number, x.students_count));
        // });

        // // TODO: remove this sorting by class_name (solved in db already?)
        availableClasses.sort((a, b) => (a.class_name > b.class_name) ? 1 : -1);

        console.log('availableClasses length: ', availableClasses.length);
//                            console.log(availableClasses);

        this._classesAvailable = availableClasses;
        console.log('emitting classesAvailableChanged');
        this.classesAvailableChanged.emit(this._classesAvailable.slice());

        console.log('_classesAvailable length: ', this._classesAvailable.length);

//                            console.log(this._classesAvailable);

        console.log('_classesSelected length: ', this._classesSelected.length);
    }


    setSelectedClasses(newListOfSelectedClasses: string[]) {
        console.log('setSelectedClasses:');
        console.log(newListOfSelectedClasses);

        console.log('_classesAvailable length: ', this._classesAvailable.length);
//        console.log(this._classesAvailable);

        const newSelectedClasses = [];

        newListOfSelectedClasses.map(x => {
            const classToAdd = this._classesAvailable.find(i => i.class_code === x);
            console.log('CLASS TO ADD:');
            console.log(classToAdd);
            if (classToAdd && classToAdd !== undefined) {
                newSelectedClasses.push(classToAdd);
            }
        });

        this._classesSelected = newSelectedClasses;

        this.classesSelectedChanged.emit(this._classesSelected.slice());
        console.log('_classesSelected:');
        console.log(this._classesSelected);
    }
//////////////////////// SCHOOLS////////////////////////////////////////////





    getAlternatingListItemClass(index: number, existingClasses: string = '', customClassPrimary: string = '', customClassAlt: string = '') {

        let classesToUse = existingClasses;

        let classPrimary = 'coreListRow itemBubble';
        let classAlt = 'coreListRowAlt';

        if (customClassPrimary !== '') { classPrimary = customClassPrimary; }
        if (customClassAlt !== '') { classAlt = customClassAlt; }

        if (index % 2 > 0) {
            classesToUse = classesToUse + ' ' + classAlt;
        } else {
            classesToUse = classesToUse + ' ' + classPrimary;
        }

        return classesToUse;
    }

    universalValidate(dataType: string, lengthRequired: number, settingValue: string){

        let isValid = false;
        console.log('universalValidate settingValue: ', settingValue);
        console.log('universalValidate dataType: ', dataType);
        console.log('universalValidate lengthRequired: ', lengthRequired);

        let typeValidation = false;
        let lengthValidation = false;
        switch (dataType) {
            case 'number':
                if (isNumeric(settingValue)) {
                    typeValidation = true;
                }
                break;
            case 'string':
                typeValidation = true;
                break;
        }

        if (settingValue.toString().length >= lengthRequired) {
            lengthValidation = true;
        }

        isValid = typeValidation && lengthValidation;
        console.log('universalValidate validation result: ', isValid);
        return isValid;
    }

    getVersionFromDownloadServer(isStage: boolean) {
        // console.log('getVersionFromDownloadServer');

        let url = 'https://scoolmed.com/apk/version.html';
        if (isStage) {
            url = 'https://scoolmed.com/apk/version-stage.html';
        }

        return this.httpService.get_CIVILIAN(url)
            .pipe(map(r => {
//                    console.log(r);
                    return r;
                }));
    }

    deleteEvent(eventId: string) {
        return this.httpService.delete(environment.URL_ROOT + '/TCalendarEvents?event=eq.' + eventId)
            .pipe(map(r => {
                console.log(r);
                this.CalendarSyncChanged.emit(true);
                return r;
            }));
    }



    getLastArrayIndex(array: any){
        const len = array.length;
        let res = 0;

        if (len === 1){
            res = 0;
        } else {
            res = len - 1;
        }

        return res;
        //return 0;
    }

    formatTestDate(testDate: string) {
        return testDate.substring(5, 7) + '/' + testDate.substring(2, 4);
    }

}
