import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-backbutton-section',
  templateUrl: './backbutton-section.component.html',
  styleUrls: ['./backbutton-section.component.scss'],
})
export class BackbuttonSectionComponent implements OnInit {

  @Input() URL: string;
  @Input() BUTTON_TEXT: string;
  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {}

  redirectBack() {
    this.router.navigateByUrl(this.URL);
  }

}
