import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreService} from '../../services/core.service';
import {Router} from '@angular/router';
import {LoadingController, Platform} from '@ionic/angular';
import {NgForm} from '@angular/forms';
import {HttpService} from '../../services/http.service';
import {CookieService} from 'ngx-cookie-service';
import {Subscription} from 'rxjs';
import {RegistrationService} from '../../services/registration.service';
import {Location} from '@angular/common';
import {environment} from '../../../environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

    txtPhoneCountryCode = '357';
    txtPhoneNumber = '';
    message = '';


    feedback = '';

    mobileSend = false;
    loginBtnDisabled = false;
    resendBtnDisabled;

    otp = '';

    phoneError = '';
    phoneInit = false;
    phoneChecked = false;
    sendBtnDisabled = false;


    countries = [];
    prefix = 357;
    number = '';
    token;
    loading;


    platformMobile = true;
    private loginStateSub: Subscription;

  // https://github.com/xUnholy/cordova-device-accounts-demo/blob/master/src/app/home/home.page.ts
  constructor(
      private coreService: CoreService,
      public router: Router,
      private loadingCtrl: LoadingController,
      private httpService: HttpService,
      private cookieService: CookieService,
      private location: Location,
      private platform: Platform,
      private registrationService: RegistrationService
  //    private deviceAccounts: DeviceAccounts
  ) {

      location.onUrlChange((url, state) => {
          this.ngOnInit();
      });

      if (this.platform.is('desktop') || this.platform.is('mobileweb')  ) {
          this.platformMobile = false;
      } else {
          this.platformMobile = true;
      }
  }

  ngOnDestroy() {
        if (this.loginStateSub) {
            this.loginStateSub.unsubscribe();
        }
  }

  ngOnInit() {
      this.txtPhoneCountryCode = '';
      this.txtPhoneNumber = '';

      this.listPhoneCountries();
  }

    executeCountrySearch() {
        console.log('executing countrySearch');
    }

    listPhoneCountries() {
        this.httpService.listPhoneCountries().subscribe(countries => {
// @ts-ignore
            this.countries = countries;
        }, err => {
            console.log(err);
            this.message = err.message;
        });

        this.txtPhoneCountryCode = '357';
    }

    submitOtp() {
        this.httpService.login(this.otp, this.txtPhoneCountryCode, this.txtPhoneNumber).subscribe(token => {

                            localStorage.clear();
                            this.httpService.setLanguage(token).subscribe(langRes => {
                                console.log(langRes);
                                this.router.navigateByUrl('/home');

            }, err => {
                console.log(err);
                this.message = err.message;
         });
        });
    }

    requestOtp() {

        if (this.validatePhone() === true){

            console.log(this.txtPhoneCountryCode, this.txtPhoneNumber);

            this.httpService.login0(this.txtPhoneCountryCode, this.txtPhoneNumber).subscribe(otp => {


                this.otp = otp;
                this.mobileSend = true;


            }, err => {
                console.log(err);
                this.message = err.message;
            });
        }
    }

    validatePhone() {
        this.phoneChecked = false;
        this.phoneError = '';
        this.sendBtnDisabled = true;

        let phoneValid = false;
        // if (phoneInit) {
        if (this.txtPhoneNumber.length === 0) {
            this.phoneError = 'Phone number is required';
        } else if (this.txtPhoneNumber.length <= 3) {
            this.phoneError = 'The phone number must be at least 4 digits long';
        } else if (this.txtPhoneNumber.length >= 15) {
            this.phoneError = 'The phone number can be up to 15 digits long';
        } else if (!(/^\d+$/.test(this.txtPhoneNumber.replace(/\s/g, '')))) {
            this.phoneError = 'The phone number can only contain numbers';
        } else if (this.txtPhoneCountryCode === '') {
            this.phoneError = 'You have to choose country code';
        } else {
            phoneValid = true;
        }

        this.phoneChecked = true;
        this.sendBtnDisabled = false;
        console.log('phone error:' + this.phoneError);

        return phoneValid;
        // } else {
        //  phoneInit = true;
        // }
    }

    getOTPresendButtonClasses() {
        let initialClasses = 'text-decoration-underline resend-link cursor-pointer ';


        if (this.loading) {
            initialClasses = initialClasses + ' ' + 'active-link';
        }

        if (this.resendBtnDisabled) {
            initialClasses = initialClasses + ' ' + '    class=" {loading ? \'active-link\' : \'\'} {resendBtnDisabled ? \'disable-link\' : \'\'}"\n';
        }


        return initialClasses;
    }


    openAppInSystemBrowser(){
        const url = environment.WEB_APP_URL;
        window.open(url, '_system', 'location=yes');
    }
}
