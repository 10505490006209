import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class VisitorService {

    constructor(private coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    getVisitorPhoneNumbers(listId: string) {
        console.log('getVisitorPhoneNumbers');
        return this.httpService.get(environment.URL_ROOT + '/TFormsListEntries?address_list=eq.' + listId)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getVisitorPhoneNumbers: ', error.message);
                    return error.message;
                }));
    }

    getListDetails(listId: string) {
        console.log('getListDetails');
        return this.httpService.get(environment.URL_ROOT + '/TFormsAddressLists?address_list=eq.' + listId)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getListDetails: ', error.message);
                    return error.message;
                }));
    }

    getVisitorFormsets() {
        console.log('getVisitorFormsets');
        return this.httpService.get(environment.URL_ROOT + '/GetFormsets')
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getVisitorFormsets: ', error.message);
                    return error.message;
                }));
    }

    getVisitorFormsetDetails(formset: string) {
        console.log('getVisitorFormsetDetails');
        return this.httpService.get(environment.URL_ROOT + '/GetFormsets?formset=eq.' + formset)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getVisitorFormsetDetails: ', error.message);
                    return error.message;
                }));
    }



    getVisitorSmsTemplateDetails(id: string) {
        console.log('getVisitorSmsTemplateDetails');
        return this.httpService.get(environment.URL_ROOT + '/TSmsTemplates?id=eq.' + id)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getVisitorSmsTemplateDetails: ', error.message);
                    return error.message;
                }));
    }

    getVisitorSmsTemplates() {
        console.log('getVisitorSmsTemplates');
        return this.httpService.get(environment.URL_ROOT + '/TSmsTemplates')
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getVisitorSmsTemplates: ', error.message);
                    return error.message;
                }));
    }

    getVisitorPhoneNumberLists() {
        console.log('getVisitorPhoneNumberLists');
        return this.httpService.get(environment.URL_ROOT + '/TFormsAddressLists')
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getVisitorPhoneNumberLists: ', error.message);
                    return error.message;
                }));
    }



    saveVisitorFile(visitor: string, fileName: string, fileType: string, description: string, base64String: string) {
        const postData = {
            _visitor: visitor,
            _filename: fileName,
            _description: description,
            _blob: base64String
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/VisitorFileAdd', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getVisitorFiles() {
        console.log('getVisitorFiles');
        return this.httpService.get(environment.URL_ROOT + '/VisitorFiles')
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getVisitorFiles: ', error.message);
                    return error.message;
                }));
    }


    registerInExistingOrg(COUNTRY: string,
                          ORGID: string,
             first_name: string,
             last_name: string,
             email: string,
             phonePrefix: string,
             phone: string,
             idCard: string) {

        console.log('register');

        //if (joinMode) {

            const postData = {
                _email: email,
                _first_name: first_name,
                _last_name: last_name,
                _mobile: { prefix: phonePrefix, number: phone },
                _id_card: idCard,
                _lang: 'EN',
                _orgdata: { ORGID: ORGID}
            };

            console.log(postData);
            return this.httpService.postPublic(environment.AUTH_ROOT_URL + '/rpc/OnboardMe', postData, true)
                .pipe(map(r => {
                    return r;
                }));
    }

    registerInNewOrg(
                          newOrgName: string,
                          COUNTRY: string,
                          ORGID: string,
                          first_name: string,
                          last_name: string,
                          email: string,
                          phonePrefix: string,
                          phone: string,
                          idCard: string) {

        console.log('register');

        //if (joinMode) {

        const postData = {
            _email: email,
            _first_name: first_name,
            _last_name: last_name,
            _mobile: { prefix: phonePrefix, number: phone },
            _id_card: idCard,
            _lang: 'EN',
            _orgdata: { ORGCT: COUNTRY.toUpperCase(), ORGNAME: newOrgName }
        };

        console.log(postData);
        return this.httpService.postPublic(environment.AUTH_ROOT_URL + '/rpc/OnboardMe', postData, true)
            .pipe(map(r => {
                return r;
            }));
    }

    CreateSMSCampaign(_formset: string, _address_list: string, send_at: string) {

        let sendAtToUse: any = null;
        if (send_at === ''){
            sendAtToUse = send_at;
        }

        const postData = {
            _address_list: _address_list,
            _formset: _formset,
            _send_at: send_at
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/CreateSMSCampaign', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addNewPublicForm(form_name: string, html_tpl_id: string, requires_email: boolean, requires_sms: boolean, email_subject: string, is_public: boolean, list_id: string) {
        const postData = {
            _form_name: form_name,
            _html_tpl_id: html_tpl_id,
            _data: null,
            _notify_by_email: requires_email,
            _notify_by_sms: requires_sms,
            _email_subject: email_subject,
            _is_public: is_public,
            _list_id: list_id
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/CreateForm', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addNewSmsTemplste(message: string, formset: string, notes: string) {
        const postData = {
            formset,
            message,
            notes
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/TSmsTemplates', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addNewPhoneList(list_name: string) {
        const postData = {
            list_name
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/TFormsAddressLists', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    upsertFormset(formset: string, formset_form_name: string, formset_email_subject: string, formset_is_public: boolean, formset_notify_by_email: boolean, formset_notify_by_sms: boolean) {
        const postData = {
            _formset: formset,
            _form_name: formset_form_name,
            _email_subject: formset_email_subject,
            _notify_by_email: formset_notify_by_email,
            _notify_by_sms: formset_notify_by_sms
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/CreateOrUpdateFormset', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addNewFormListEntry(address_list: string, mobile: string, email: string, full_name: string) {
        const postData = {
            address_list,
            mobile,
            email,
            full_name
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/TFormsListEntries', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    deletePhoneNumber(list_entry: string) {
        return this.httpService.delete(environment.URL_ROOT + '/TFormsListEntries?list_entry=eq.' + list_entry)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    deleteContactList(address_list: string) {

        return this.httpService.delete(environment.URL_ROOT + '/TFormsAddressLists?address_list=eq.' + address_list)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }
}
