import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {ScreeningService} from '../../services/screening.service';
import {TranslateService} from '@ngx-translate/core';
import {DoctorReferenceModalComponent} from '../shared/doctor-reference-modal/doctor-reference-modal.component';
import {Location} from "@angular/common";

@Component({
  selector: 'app-screenings-missing',
  templateUrl: './screenings-missing.component.html',
  styleUrls: ['./screenings-missing.component.scss'],
})
export class ScreeningsMissingComponent implements OnInit {

  missingScreenings = [];
  isLoading = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private location: Location,
              private httpService: HttpService,
              private screeningService: ScreeningService,
              private translate: TranslateService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }


  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = true;
    this.loadMissingScreenings();
  }

  loadMissingScreenings() {

    this.screeningService.getMissingScreenings().subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.missingScreenings = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching missing screenings failed ', err);
      this.isLoading = false;
    });
  }

}
