import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CoreService} from '../../services/core.service';
import {ScreeningService} from '../../services/screening.service';
import {SchoolsService} from '../../services/schools.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../services/http.service';
import {VaccineService} from '../../services/vaccine.service';
import {Location} from '@angular/common';
import {ModalController} from '@ionic/angular';
import {School} from '../shared/school.model';
import {SchoolClass} from '../shared/schoolclass.model';
import {ModalActionStudentsComponent} from '../shared/modal-action-students/modal-action-students.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-screening-per-school',
  templateUrl: './screening-per-school.component.html',
  styleUrls: ['./screening-per-school.component.scss'],
})
export class ScreeningPerSchoolComponent implements OnInit {


  SCREENINGS_PER_SCHOOL = [];
  SCHOOLS = [];

  TRANSLATIONS = null;
  constructor(private coreService: CoreService,
              private screeningService: ScreeningService,
              private schoolsService: SchoolsService,
              private route: ActivatedRoute,
              public router: Router,
              private httpService: HttpService,
              private vaccineService: VaccineService,
              private location: Location,
              private changeRef: ChangeDetectorRef,
              private translate: TranslateService,
              private modalCtrl: ModalController) {

    location.onUrlChange((url, state) => {
      console.log('URL CHANGE DETECTED!');
      console.log(url);

    });
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.loadSchools();
    this.loadSchoolsScreenings();
  }

  loadSchools() {
    this.schoolsService.getSchools().subscribe(r => {
      console.log(r);

      this.SCHOOLS = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching schools failed ', err);
    });
  }

  loadSchoolsScreenings() {
    this.screeningService.getScreeningsPerSchool().subscribe(r => {
      console.log(r);

      this.SCREENINGS_PER_SCHOOL = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching screenings per schools ', err);
    });
  }


  loadPage(pageName: string) {
    console.log('loading page: ' + pageName);
    this.router.navigateByUrl('/' + pageName);
  }
}
