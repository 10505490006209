import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {Calendar} from '@ionic-native/calendar/ngx';
import {CoreService} from '../../services/core.service';
import {HttpService} from '../../services/http.service';
import {Location} from "@angular/common";

@Component({
  selector: 'app-lecture-subjects',
  templateUrl: './lecture-subjects.component.html',
  styleUrls: ['./lecture-subjects.component.scss'],
})
export class LectureSubjectsComponent implements OnInit {
  subjects = [];

  constructor(private router: Router,
              public navCtrl: NavController,
              //     private deeplinks: Deeplinks,
              private calendar: Calendar,
              private plt: Platform,
              private coreService: CoreService,
              private location: Location,
              private httpService: HttpService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {

    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.coreService.getSubjects().subscribe(r => {
      this.subjects = r;
    });
  }

  newLecture(subjectId: string){
    this.router.navigateByUrl('/lecture-new/' + subjectId);
  }

  newFile(subjectId: string){
    this.router.navigateByUrl('/lecture-subject-file/' + subjectId);
  }
}
