import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

    constructor(private coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }


    sendSms(phoneNumber: string, messaege: string) {
        console.log('sendSms');
        const postData = {
            _phone: phoneNumber,
            msg: messaege
        };

        return this.httpService.post(environment.URL_ROOT + '/rpc/sm_send_sms', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }
}
