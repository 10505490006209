import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ScreeningService} from '../../../services/screening.service';
import {ModalController} from '@ionic/angular';
import {ScolioDetails} from '../../../models/scolio.details.model';
import {SingleSetting} from '../../../models/single.setting.model';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../services/core.service';
import {isNumeric} from 'rxjs/internal-compatibility';
import {Location} from "@angular/common";


@Component({
  selector: 'app-single-value-selector',
  templateUrl: './single-value-selector.component.html',
  styleUrls: ['./single-value-selector.component.scss'],
})
export class SingleValueSelectorComponent implements OnInit, OnDestroy {
  @Input() settingName: string;
  @Input() displayName: string;
  @Input() dataType: string;
  @Input() lengthRequired: number;
  settingValue = '';

  submitSectionChangedSub: Subscription;
  isValid = true;
  constructor(
      private screeningService: ScreeningService,
      private coreService: CoreService,
      private location: Location,
      private modalCtrl: ModalController) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  validate(){

    this.isValid = false;

    console.log('single setting validation value: ', this.settingValue);
    console.log('validation dataType: ', this.dataType);
    console.log('validation lengthRequired: ', this.lengthRequired);

    let typeValidation = false;
    let lengthValidation = false;
    switch (this.dataType) {
      case 'number':
        if (isNumeric(this.settingValue)) {
          typeValidation = true;
        }
        break;
      case 'string':
        typeValidation = true;
        break;
    }

    if (this.settingValue.toString().length >= this.lengthRequired) {
      lengthValidation = true;
    }

    this.isValid = typeValidation && lengthValidation;
    console.log('single setting validation result: ', this.isValid);
  }

  ngOnDestroy() {
    if (this.submitSectionChangedSub) {
      this.submitSectionChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.submitSectionChangedSub = this.coreService.submitSectionsChanged.subscribe(update => {
      update.map(i => {
        if (i.submitBroadcasted && i.sectionName === this.settingName) {
          this.coreService.setSingleValueSetting(new SingleSetting(this.settingName, this.settingValue));
          this.modalCtrl.dismiss();
        }

        if (i.cancelBroadcasted && i.sectionName === this.settingName) {
          this.modalCtrl.dismiss();
        }
      });
    });

    this.validate();
  }
}
