import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {VisitorService} from '../../services/visitor.service';
import {Location} from '@angular/common';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {CallModalComponent} from '../shared/call-modal/call-modal.component';

@Component({
  selector: 'app-visitor-publicforms-details',
  templateUrl: './visitor-publicforms-details.component.html',
  styleUrls: ['./visitor-publicforms-details.component.scss'],
})
export class VisitorPublicformsDetailsComponent implements OnInit {

  listId = '';

  LIST_DETAILS: any = [];

  numbersList = [];
  msgOperationResult = '';
  newNumberVisible = true;
  isAdded = false;
  formName = 'addPublicForm' + this.coreService.generateFormNamePostfix();
  form_name = '';

  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private visitorService: VisitorService,
              private location: Location,
              private modalCtrl: ModalController,
              private httpService: HttpService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.listId = this.route.snapshot.paramMap.get('listId');

    this.getVisitorPhoneNumbers();
    this.getListDetails();

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {

          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null) {
            // if (submitSectionItem.cancelBroadcasted === true) {
            //   this.router.navigateByUrl('/student-details/' + this.studentId);
            // }

            if (submitSectionItem.submitBroadcasted === true) {
              this.onAdd();
            }
          }
        }
    );
  }

  onAdd() {
    // this.visitorService
    //     .addNewPhoneNumber(+this.listId, this.form_name)
    //     .subscribe(res => {
    //       // this.newNumberVisible = false;
    //       this.msgOperationResult = 'Operation complete!';
    //       this.getVisitorPhoneNumbers();
    //       this.coreService.broadcastToastMessage('Number added', false);
    //
    //       this.form_name = '';
    //
    //     }, err => {
    //       console.log(err);
    //       this.coreService.broadcastErrorMessage('Adding number failed ', err);
    //     });
  }

  isFormValid() {
    return this.form_name.length > 0 ;
  }

  showSucessBadge() {
    this.isAdded = true;
    setTimeout(() => {
      this.isAdded = false;
    }, 2000);
  }

  getListDetails() {
    this.visitorService.getListDetails(this.listId).subscribe((r: any[]) => {
      console.log('getListDetails');
      this.LIST_DETAILS = r[0];
    });
  }

  getVisitorPhoneNumbers() {
    this.visitorService.getVisitorPhoneNumbers(this.listId).subscribe((r: any[]) => {
      console.log('getVisitorPhoneNumberLists');
      console.log(r[0]);
      this.numbersList = r.reverse().slice();
    });
  }

  showCallOptions(phoneNumber: string, firstName: string, lastName: string) {
    console.log('showCallOptions', phoneNumber);

    this.modalCtrl
        .create({
          component: CallModalComponent,
          componentProps: { phoneNumber, firstName, lastName }
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  showEmailOptions(email: string) {
    console.log('showEmailOptions', email);
  }

  deleteNumber(phoneNumber: string) {
    // this.visitorService
    //     .deletePhoneNumber(+this.listId, phoneNumber)
    //     .subscribe(res => {
    //       // this.newNumberVisible = false;
    //       this.msgOperationResult = 'Operation complete!';
    //       this.getVisitorPhoneNumbers();
    //       this.coreService.broadcastToastMessage('Number deleted', false);
    //
    //       this.form_name = '';
    //
    //     }, err => {
    //       console.log(err);
    //       this.coreService.broadcastErrorMessage('Deleting number failed ', err);
    //     });
  }
}
