import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreService} from '../../services/core.service';
import {Student} from '../../models/student.model';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../services/http.service';
import {School} from '../shared/school.model';
import {Subscription} from 'rxjs';
import {SchoolClass} from '../shared/schoolclass.model';
import {ResourcesService} from '../../services/resources.service';
import {GenderAvatarComponent} from '../shared/gender-avatar/gender-avatar.component';
import {newArray} from '@angular/compiler/src/util';
import {ScreeningService} from '../../services/screening.service';
import {VaccineService} from '../../services/vaccine.service';
import { Location } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CallModalComponent} from '../shared/call-modal/call-modal.component';
import {ModalActionStudentsComponent} from '../shared/modal-action-students/modal-action-students.component';
import { fromEvent } from 'rxjs';
import { map, sampleTime } from 'rxjs/operators';
import {SchoolsService} from '../../services/schools.service';
import {IdentityService} from '../../services/identity.service';

@Component({
selector: 'app-students',
templateUrl: './students.component.html',
styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit, OnDestroy  {

schools = [];
schoolsFiltered = [];
STUDENT_LIST_LIMIT_TO_USE = 0;
scrollCounterArray = [];

public STUDENT_LIST_MODES = {
DEFAULT: 'default',
VACCINE:  'vaccine',
VBOOK: 'vbook',
GLOBAL_SEARCH: 'global',
SCREENING_REPORT: 'screening_report',
FORM: 'form'
};

initialSchoolCode = '';
selectedActionButtonText = 'action with selected...';
selectedSchoolCode = null;

selectedClasses = [];
searchPhrase = '';
schoolCode = '';
classId = '';

isLoading = false;
loadingFinished = false;

students = null;
studentsInitial = [];

selectedStudents = [];

isMultipleSelected = false;

mode =  'default';

studentIds = [];

vaccine_lot = null;
student_icons = null;
student_syringes = null;

private selectedSchoolsSub: Subscription;
private selectedClassesSub: Subscription;
private vaccineLotSelectedSub: Subscription;
private studentUpdatedSub: Subscription;
private studentsLoadedSub: Subscription;
private schoolsAvailableChangedSub: Subscription;

TRANSLATIONS = null;
constructor(private coreService: CoreService,
private screeningService: ScreeningService,
private schoolsService: SchoolsService,
private route: ActivatedRoute,
public router: Router,
private httpService: HttpService,
private vaccineService: VaccineService,
private location: Location,
private changeRef: ChangeDetectorRef,
private identityService: IdentityService,
private modalCtrl: ModalController) {

location.onUrlChange((url, state) => {
console.log('URL CHANGE DETECTED!');
console.log(url);

if (this.mode === this.STUDENT_LIST_MODES.VACCINE) {
//this.loadSyringes();
} else if (url.indexOf('/students') > -1) {
//this.loadScreeningIcons();
}

//    this.ngOnInit();

// this.students = this.students.slice();
});
}

decideInfinityScrollSpinnerVisibility2() {
if (this.students) {
return this.students.length > this.coreService.STUDENT_LIST_LIMIT;
} else {
return false;
}
}

onScrollEnter(elementId: string){
console.log('onScrollEnter called by: ', elementId);

let xLength = 0;

if (this.optimizeStudentsForRendering(this.students)){
xLength = this.optimizeStudentsForRendering(this.students).length;
}
console.log('xLength: ', xLength);
console.log('STUDENT_LIST_LIMIT_TO_USE: ', this.STUDENT_LIST_LIMIT_TO_USE);
console.log('students.length: ', this.students.length);

if (this.students.length > xLength) {
console.log('updating limit');
this.STUDENT_LIST_LIMIT_TO_USE = this.STUDENT_LIST_LIMIT_TO_USE + this.coreService.STUDENT_LIST_LIMIT;
//  this.scrollCounterArray.push(this.scrollCounterArray.length);
this.scrollCounterArray.push(1);
}else{
console.log('not updating limit');
}

// remove element from DOM
const input = document.getElementById(elementId);
console.log('infinite spinner element id: ', elementId);
console.log(input);

input.remove();
}

ngOnDestroy() {
if (this.selectedSchoolsSub) {
this.selectedSchoolsSub.unsubscribe();
}

if (this.selectedClassesSub) {
this.selectedClassesSub.unsubscribe();
}

if (this.vaccineLotSelectedSub) {
this.vaccineLotSelectedSub.unsubscribe();
}

if (this.studentUpdatedSub) {
this.studentUpdatedSub.unsubscribe();
}

if (this.studentsLoadedSub) {
this.studentsLoadedSub.unsubscribe();
}

if (this.schoolsAvailableChangedSub) {
this.schoolsAvailableChangedSub.unsubscribe();
}
}

ngOnInit() {

if (!this.httpService.isLoggedIn()){
this.router.navigateByUrl('/login');
}

this.STUDENT_LIST_LIMIT_TO_USE = this.coreService.STUDENT_LIST_LIMIT;

this.mode = this.STUDENT_LIST_MODES.DEFAULT;


this.classId = this.route.snapshot.paramMap.get('classId');

console.log('classId:' + this.classId);
this.schoolCode = this.route.snapshot.paramMap.get('school');

const modeFromParam = this.route.snapshot.paramMap.get('mode');
if (modeFromParam) {
this.mode = modeFromParam;

// if (this.mode === this.STUDENT_LIST_MODES.GLOBAL_SEARCH){
//     this.isLoading = false;
// }
        }

console.log('school: ', this.schoolCode);
if (this.schoolCode !== null) {
console.log('load students from ', this.schoolCode);

this.initialSchoolCode = this.schoolCode;
this.selectedSchoolCode = this.schoolCode;
const schoolArray = [];
schoolArray.push(this.initialSchoolCode);
this.schoolsService.setSelectedSchools(schoolArray);
this.loadStudents();
} else {
this.loadStudents();
}

this.studentUpdatedSub = this.coreService.studentChanged.subscribe(studentId => {
// reaload studentData
// console.log('studentChanged event received. LOADING SYRINGES:');

if (this.mode === this.STUDENT_LIST_MODES.VACCINE) {
this.loadSyringes();
} else if (this.mode === this.STUDENT_LIST_MODES.FORM) {
this.loadForms();
} else {
this.loadScreeningIcons(studentId);
}
        });

this.selectedSchoolsSub = this.schoolsService.schoolsSelectedChanged
.subscribe(
(selectedSchools: School[]) => {
if (selectedSchools[0] != null){
this.selectedSchoolCode = selectedSchools[0].school_id;
}

console.log(this.selectedSchoolCode);
console.log('schoolsSelectedChanged');

this.isLoading = true;
this.loadStudents();
}
);

this.selectedClassesSub = this.coreService.classesSelectedChanged
.subscribe(
(classes: SchoolClass[]) => {

let newStudentsList = [];
classes.map(cl => {
const studentsInCurrentClass = this.studentsInitial.filter(s => s.data.class === cl.class_code);
newStudentsList = newStudentsList.concat(studentsInCurrentClass);
});

this.searchPhrase = '';
this.isMultipleSelected = false;
this.students = newStudentsList.slice();
});

this.vaccineLotSelectedSub = this.vaccineService.vaccineLotSelectedChanged.subscribe(lotSelected => {
this.vaccine_lot = lotSelected;
this.loadSyringes();
});

this.studentsLoadedSub = this.coreService.studentsLoadedChanged.subscribe(students => {

this.scrollCounterArray.push(this.scrollCounterArray.length + 1);
console.log('students component: studentsLoadedChanged::');
console.log(students);



this.processStudents(students);
// if (students.data) {
//     this.processStudents(students);
// } else {
//     students.then(r => {
//         this.processStudents(r);
//     });
// }

        });



if (!this.httpService.isLoggedIn()) {
} else {
this.identityService.profile().subscribe(res => {
if (res !== null){
if (res.length > 0) {
this.schoolsService.loadSchools(res[0].username);
}
        }
}, err => {
console.log(err);
});
}


this.schoolsAvailableChangedSub = this.schoolsService.schoolsAvailableChanged
.subscribe(
(availableSchools: School[]) => {
this.schools = availableSchools;
this.schoolsFiltered = this.schools.slice();
}
);

console.log('NGONINIT STUDENTS.COMPONENT');
}

private processStudents(rr: any) {
this.coreService.availableStudents = rr;
//this.coreService.availableStudents = rr.data;
//this.coreService.broadcastToastMessage(rr.message, false);
this.coreService.broadcastDataSourceInformation(rr.dataSource, rr.message, false);

this.loadStudentsGeneric(this.coreService.availableStudents);
this.isLoading = false;
}

getListClass() {
let defaultClasses = 'bg-none';

if (this.mode === this.STUDENT_LIST_MODES.VACCINE && this.vaccine_lot === null) {
defaultClasses = 'bg-none bluredSection';
}

return defaultClasses;
}

decideIcon(studentId: string, testType: string) {

const item = this.student_icons.find(x => x.student == studentId);
if (item) {
const itemIcon = item.icons.find(x => x.icon === testType);
if (itemIcon) {
return true;
} else {
return false;
}
        } else {
return false;
}
}

getIconColor(studentId: string, testType: string) {
let colorToReturn = '#b6b6b6';
try {
if (this.student_icons !== null) {
const item = this.student_icons.find(x => x.student == studentId); // @ts-ignore

if (item !== undefined) {
switch (testType) {
// @ts-ignore
case  this.screeningService.TEST_TYPES.AUDIO:
const itemIconAudio = item.icons.find(x => x.icon === 'AUDIO');
if (itemIconAudio) {
if (itemIconAudio.tested === true) {
colorToReturn = '#51a596';
}
        }
break;
case  this.screeningService.TEST_TYPES.CHROMA:
const itemIconChroma = item.icons.find(x => x.icon === 'CHROMA');
if (itemIconChroma) {
if (itemIconChroma.tested === true) {
colorToReturn = '#51a596';
}
        }
break;

case  this.screeningService.TEST_TYPES.VISION:
const itemIconVision = item.icons.find(x => x.icon === 'VISION');
if (itemIconVision) {
if (itemIconVision.tested === true) {
colorToReturn = '#51a596';
}
        }
break;

case  this.screeningService.TEST_TYPES.SCOLIO:
const itemIconScolio = item.icons.find(x => x.icon === 'SCOLIO');
if (itemIconScolio) {
if (itemIconScolio.tested === true) {
colorToReturn = '#51a596';
}
        }
break;
}
        }
}
        } catch   {
console.log('CATCHING ERROR');
colorToReturn = 'gray';
}

return colorToReturn;
}

getSyringeColor(studentId: number) {

let colorToReturn = 'coreMenuIconWhite';

if (this.student_syringes !== null) {

const syringesOfTheCurrrentStudent = this.student_syringes.filter(x => x.student === studentId); // @ts-ignore
const colors = [];

syringesOfTheCurrrentStudent.map(s => {
colors.push(s.color.toLowerCase());
});

if (colors.indexOf(this.vaccineService.SYRINGE_COLORS.WHITE) > -1) {
colorToReturn = 'coreMenuIconWhite';
}

if (colors.indexOf(this.vaccineService.SYRINGE_COLORS.GREEN) > -1) {
colorToReturn = 'coreMenuIconGreen';
}

if (colors.indexOf(this.vaccineService.SYRINGE_COLORS.ORANGE) > -1) {
colorToReturn = 'coreMenuIconOrange';
}

if (colors.indexOf(this.vaccineService.SYRINGE_COLORS.RED) > -1) {
colorToReturn = 'coreMenuIconRed';
}
        }

return colorToReturn;
}

printGenderCounters() {
if (this.students != null && this.studentsInitial != null) {
if (this.students.length > 0) {
const girlsTotalCount = this.studentsInitial.filter(x => x.data.gender === 'F').length;
const boysTotalCount = this.studentsInitial.filter(x => x.data.gender === 'M').length;

const girlsCount = this.students.filter(x => x.data.gender === 'F').length;
const boysCount = this.students.filter(x => x.data.gender === 'M').length;

return ' <br />[' + this.students.length + '/' + this.studentsInitial.length + ' <span class="colorMaleOnBg">' + boysCount + '/' + boysTotalCount + '</span> <span style="color: lightpink">' + girlsCount + '/' + girlsTotalCount + '</span>]';
} else {
return '';
}

        } else {
return '';
}
}

reLoadStudent(studentId: string) {
this.coreService.getStudent(this.selectedSchoolCode).subscribe((r) => {
const itemToUpdate =  this.students.find(x => x.studentId === studentId);
itemToUpdate.data = {...r[0]};
console.log('reLoadStudent');
console.log(itemToUpdate);
});
}

loadStudents() {
this.isLoading = true;

if (this.selectedSchoolCode !== null) {
// this.coreService.getStudentsBySchool(this.selectedSchoolCode).then(r => {
//     console.log('students updated in a subscription studentsLoadedChangedSub');
//     // this.coreService.availableStudents = r;
//     // this.loadStudentsGeneric(this.coreService.availableStudents);
// });


this.coreService.getStudentsBySchool(this.selectedSchoolCode);
// .subscribe(r => {
//     console.log('students updated in a subscription studentsLoadedChangedSub');
//     // this.coreService.availableStudents = r;
//     // this.loadStudentsGeneric(this.coreService.availableStudents);
// });
} else {
this.coreService.getStudentsGlobal().subscribe(r => {

if (this.mode === this.STUDENT_LIST_MODES.SCREENING_REPORT) {
console.log('screening report MODE');

const scRepSchool =  this.route.snapshot.paramMap.get('school');
const scRepClass =  this.route.snapshot.paramMap.get('class');
const scRepDate =  this.route.snapshot.paramMap.get('date');

this.screeningService.getScreeningReports(scRepSchool, scRepClass, scRepDate).subscribe(reports => {
console.log('getScreeningReports: ');
console.log(reports);

const studentsId = [];

const newStudentsInitial = [];
reports.map(x => {
studentsId.push(x.student);

newStudentsInitial.push(this.studentsInitial.filter(y => y.data.student === x.student));
});

this.studentsInitial = newStudentsInitial;
});
}

console.log('getStudentsGlobal result: ');
console.log(r);

this.coreService.availableStudents = r;
this.loadStudentsGeneric(this.coreService.availableStudents);
});
}
}

loadStudentsGeneric(r: any[]){

console.log('availableStudents (from heavis service:');
// console.log(r);

console.log('selectedSchoolCode:');
console.log(this.selectedSchoolCode);

const studentsList = [];
this.studentIds = [];
console.log('array l: ', r.length);
for (let a = 0; a < r.length; a++) {
studentsList.push({ studentId: r[a].student , data: {...r[a]}, isChecked: false });
this.studentIds.push(r[a].student);
}

this.students = []; //studentsList.slice();
this.studentsInitial = studentsList.slice();
this.isLoading = false;

// this.studentsInitial.map(x => {
//     this.studentIds.push(x.studentId);
// });


console.log('STUDENTS INITIAL LENGTH: ', this.studentsInitial.length);
console.log('STUDENTIDS LENGTH: ', this.studentIds.length);

this.loadScreeningIcons();
this.isLoading = false;

if (this.selectedSchoolCode !== null){
this.students = this.studentsInitial.slice();
console.log('HHHHH');
}

this.STUDENT_LIST_LIMIT_TO_USE = this.STUDENT_LIST_LIMIT_TO_USE + this.coreService.STUDENT_LIST_LIMIT;
}

loadScreeningIcons(studentId: number = null) {

let studentIdsToFetch = this.studentIds;
if (studentId !== null) {
studentIdsToFetch = [];
studentIdsToFetch.push(studentId);
}

console.log('studentIdsToFetch count: ', studentIdsToFetch.length);
// console.log(studentIdsToFetch);

this.screeningService.getStudentsScreeningIcons(this.schoolCode).subscribe(r => {
if (studentId != null) {
console.log('studentIdsToFetch: updating only id' + studentId);
const itemToUpdateIndex = this.student_icons.findIndex(x => x.student == studentId);

const newValue = r.find(x => x.student == studentId);
this.student_icons[itemToUpdateIndex] = newValue;

} else {
this.student_icons = r;
}

this.changeRef.detectChanges();
}, err => {
console.log(err);
this.coreService.broadcastErrorMessage('loadScreeningIcons failed ', err);
this.isLoading = false;
});
}

loadForms(studentId: string = null) {
let studentIdsToFetch = this.studentIds;
if (studentId !== null) {
studentIdsToFetch = [];
studentIdsToFetch.push(studentId);
}

console.log('FORM MODE: studentIdsToFetch');
console.log(studentIdsToFetch);
this.vaccineService.getStudentsSyringes(studentIdsToFetch, this.vaccine_lot.lot_id).subscribe(r => {
if (studentId !== null) {
console.log('FORM MODE:  studentIdsToFetch: updating only id' + studentId);
const itemToUpdateIndex = this.student_syringes.findIndex(x => x.student === +studentId);

const newValue = r.find(x => x.student === + studentId);
this.student_syringes[itemToUpdateIndex] = newValue;

} else {
this.student_syringes = r;
// this.students = this.students.slice();
}

this.changeRef.detectChanges();
}, err => {
console.log(err);
this.coreService.broadcastErrorMessage('FORM MODE: loading forms failed ', err);
this.isLoading = false;
});
}

loadSyringes(studentId: number = null) {
let studentIdsToFetch = this.studentIds;
if (studentId !== null) {
    studentIdsToFetch = [];
        studentIdsToFetch.push(studentId);
        }

        console.log('studentIdsToFetch');
        console.log(studentIdsToFetch);
    this.vaccineService.getStudentsSyringes(studentIdsToFetch, this.vaccine_lot.lot_id).subscribe(r => {
        if (studentId !== null) {
        console.log('studentIdsToFetch: updating only id' + studentId);
        const itemToUpdateIndex = this.student_syringes.findIndex(x => x.student === +studentId);

        const newValue = r.find(x => x.student === + studentId);
        this.student_syringes[itemToUpdateIndex] = newValue;

        } else {
        this.student_syringes = r;
    // this.students = this.students.slice();
}

this.changeRef.detectChanges();
}, err => {
console.log(err);
this.coreService.broadcastErrorMessage('loadSyringes failed ', err);
            this.isLoading = false;
        });
    }

    onSelectedActionButtonClicked() {
        this.selectedActionButtonText = 'button clicked!';
        this.selectedActionButtonText = this.students.filter(x => x.isChecked === true).length + ' selected';

        const selectedStudentIds = [];

        let girlsCount = 0;
        let boysCount = 0;

        this.students.filter(x => x.isChecked === true).map(x => {
            selectedStudentIds.push(x.data.student);
            if(x.data.gender === 'M') { boysCount++; }
            if(x.data.gender === 'F') { girlsCount++; }
        });

        this.modalCtrl
            .create({
                component: ModalActionStudentsComponent,
        componentProps: { studentIds: selectedStudentIds, girlsCount, boysCount }
        })
.then(modalEl => {
modalEl.present();
return modalEl.onDidDismiss();
});
}

getSelectedCount() {
return this.students.filter(x => x.isChecked === true).length;
}

shouldShowActionButton() {
let showButton = false;
if (this.students != null){
if (this.students.length > 0) {
showButton = this.getSelectedCount() > 0;
}
        }

return showButton;
}

search() {
this.isLoading = true;

console.log('search delayed...');
setTimeout(() => {

console.log('initiating search...');
if (this.mode === this.STUDENT_LIST_MODES.GLOBAL_SEARCH) {
if (this.searchPhrase.length > 3) {
this.searchDb();
}
} else {
this.students = [];
const searchPhraseToUse = this.searchPhrase.toLowerCase();

let searchRes_firstName = this.studentsInitial.filter(x => x.data.first_name.toLowerCase().indexOf(searchPhraseToUse) >= 0);
let searchRes_lastName = this.studentsInitial.filter(x => x.data.last_name.toLowerCase().indexOf(searchPhraseToUse) >= 0);

const allMerged = searchRes_firstName.concat(searchRes_lastName);

const uniqueItems = [...new Set(allMerged)];
this.students = uniqueItems;

this.isLoading = false;
}

// this.displayMore();

}, 1000);
}

searchDb() {
this.isLoading = true;
this.students = [];
const searchPhraseToUse = this.searchPhrase.toLowerCase();

//        console.log(this.studentsInitial);
let searchRes_firstName = this.studentsInitial.filter(x => x.data.first_name.toLowerCase().indexOf(searchPhraseToUse) >= 0);
let searchRes_lastName = this.studentsInitial.filter(x => x.data.last_name.toLowerCase().indexOf(searchPhraseToUse) >= 0);
let searchRes_school =[] ;//this.studentsInitial.filter(x => x.data.school.toLowerCase().indexOf(searchPhraseToUse) >= 0);
let searchRes_class = this.studentsInitial.filter(x => x.data.Class.toLowerCase().indexOf(searchPhraseToUse) >= 0);

const allMerged = searchRes_firstName.concat(searchRes_lastName).concat(searchRes_school).concat(searchRes_class);

const uniqueItems = [...new Set(allMerged)];
this.students = uniqueItems;

this.isLoading = false;
}

onLoadStudents() {
}

loadStudentDetails(studentId: string) {
console.log(studentId);
if (this.mode === this.STUDENT_LIST_MODES.VACCINE) {
if (this.vaccine_lot !== null) {
this.router.navigateByUrl('/vaccine-shot/' + this.vaccine_lot.lot + '/' + studentId);
} else {
this.router.navigateByUrl('/vaccine-shot/' + studentId);
}
        } else if (this.mode === this.STUDENT_LIST_MODES.VBOOK) {
this.router.navigateByUrl('/student-vbook/' + studentId);
} else {
this.router.navigateByUrl('/student-details/' + studentId);
}
}

alterStudentSelection(index: string) {
const s = this.students[index];

if (s.isChecked === true) {
s.isChecked = false;
} else {
s.isChecked = true;
}

const xxx = this.students;

xxx[index] = { ...this.students[index], isChecked: s.isChecked };

this.students = xxx;
}

toggleMultipleSelection() {
this.isMultipleSelected = !this.isMultipleSelected;

const updatedStudents = [];
for (let a = 0; a < this.students.length; a++) {
updatedStudents.push({ ...this.students[a], isChecked: this.isMultipleSelected });
}

this.students = updatedStudents;
}

optimizeStudentsForRendering(students) {

if(this.STUDENT_LIST_LIMIT_TO_USE === 0){
this.STUDENT_LIST_LIMIT_TO_USE = this.coreService.STUDENT_LIST_LIMIT
}

if (students) {
if (students.length > this.STUDENT_LIST_LIMIT_TO_USE) {
return students.slice(0, this.STUDENT_LIST_LIMIT_TO_USE);

} else {
return students;
}
        } else {
return students;
}
}


expandInfo(itemId: string) {
const input = document.getElementById(itemId);
const inputStyle = (input as HTMLInputElement).style;

inputStyle.display = 'block';
}

collapseInfo(itemId: string) {
const input = document.getElementById(itemId);
const inputStyle = (input as HTMLInputElement).style;

inputStyle.display = 'none';
}

showStudents(schoolId: string) {
console.log('showStudents');
this.router.navigateByUrl('/students/school/' + schoolId);
}
        }
