import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {VaccineService} from '../../services/vaccine.service';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe, Location} from '@angular/common';
import {TranslationsCommonService} from '../../services/translations.common.service';

@Component({
  selector: 'app-vaccine-shot',
  templateUrl: './vaccine-shot.component.html',
  styleUrls: ['./vaccine-shot.component.scss'],
})
export class VaccineShotComponent implements OnInit, OnDestroy {
  studentId = '';
  lot = null;
  lotId = '';
  notes = '';
  //availableLots = [];
  lotChipsVisible = true;
  finishVisible = false;
  selectedLot = null;
  studentData = null;
  confirmSectionName = 'xxxx';
  confirmMode = false;

  TRANSLATIONS = null;

  submitSectionSub: Subscription;
  vaccineLotSelectedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private vaccineService: VaccineService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              private location: Location,
              private translationsCommonService: TranslationsCommonService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.vaccineLotSelectedSub) {
      this.vaccineLotSelectedSub.unsubscribe();
    }
  }

  ngOnInit() {

    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.studentId = this.route.snapshot.paramMap.get('studentId');
    this.lot = this.route.snapshot.paramMap.get('lot');

    this.coreService.getStudent(this.studentId).subscribe(r => {
      console.log('STUDENT DATA');
      console.log(r);
      this.studentData = r[0];
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_LOADING_FAILED, err);
    });

    if (this.lot !== null) {
      this.vaccineService.getVaccineLots('?lot=eq.' + this.lot).subscribe(r => {
        this.selectedLot = r[0];
        this.lotChipsVisible = false;
        console.log(this.selectedLot);
      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_LOADING_FAILED, err);
      });
    } else {
      this.vaccineLotSelectedSub = this.vaccineService.vaccineLotSelectedChanged.subscribe(lotSelected => {
        this.selectedLot = lotSelected;

        this.lotChipsVisible = false;
        console.log(this.selectedLot);
      });
    }

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          submitSectionDataItems.map(i => {
            console.log('i:', i);
            console.log('sectionName: ', i.sectionName);

            if (i.sectionName === this.confirmSectionName) {
              if (i.submitBroadcasted) {
                  this.vaccineService.vaccineSaveShotNEW(this.studentId, this.selectedLot.lot_id, this.notes).subscribe(r => {
                    console.log('SHOT CONFIRMED!');

                    // disable all vaccination controls
                    this.confirmMode = false;
                    this.lotChipsVisible = false;

                    // broadcast student update - so appropriate listeners can update their syringes
                    // TODO: change this: broadcast only 1 message for 1 syringe update for 1 student [local syringe db will be updated]
                    this.coreService.broadcastStudentUpdate(this.studentId);

                    // display success toast
                    this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
                    this.finishVisible = true;
                  }, err => {
                    console.log(err);
                    this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
                  });
              }

              if (i.cancelBroadcasted){
                this.confirmMode = false;
                this.lotChipsVisible = true;
              }
            }
          });
        });
  }

  goback() {
    console.log('goback clicked, location:');
    this.location.back();
  }

  shotStudent(lot) {
    console.log('shooting student: ', this.studentId);
    console.log('with vaccine lot: ');
    console.log(lot);

    this.confirmSectionName = 'CONFIRM_VACCINE-student_' + this.studentId + '_lot_' + lot.lot;
    this.confirmMode = true;
  }
}
