import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CoreService} from "../../services/core.service";
import {ScreeningService} from "../../services/screening.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../services/http.service";
import {VaccineService} from "../../services/vaccine.service";
import {Location} from "@angular/common";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-screening-report',
  templateUrl: './screening-report.component.html',
  styleUrls: ['./screening-report.component.scss'],
})
export class ScreeningReportComponent implements OnInit {


  report_type = 'byDate';

  reportsInitial = [];
  uniqueDates = [];
  uniqueSchools = [];

  constructor(
              private coreService: CoreService,
              private screeningService: ScreeningService,
              private route: ActivatedRoute,
              private router: Router,
              private httpService: HttpService,
              private vaccineService: VaccineService,
              private location: Location,
              private changeRef: ChangeDetectorRef,
              private modalCtrl: ModalController) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {

    this.screeningService.getAllScreeningReports().subscribe(reports => {
      console.log('getScreeningReports: ');
      console.log(reports);

      this.reportsInitial = reports;
      const allDates = [];
      const allSchools = [];

      this.reportsInitial.map(r => {
        allDates.push(r.date);
        allSchools.push(r.school);
      })

      this.uniqueDates = [...new Set(allDates)];
      this.uniqueSchools = [...new Set(allSchools)];
    });
  }

  toggleReport(newReportType: string) {
      this.report_type = newReportType;
  }

  toggleVisibility(elementId: string) {

    console.log('toggleVisibility');

    const iconClosedId = elementId + '_icon_closed';
    const iconOpenId = elementId + '_icon_open';

    const input = document.getElementById(elementId);
    const inputStyle = (input as HTMLInputElement).style;

    if (inputStyle.display === 'block') {
      inputStyle.display = 'none';
    } else {
      inputStyle.display = 'block';
    }

    this.toggleIconVisibility(iconClosedId);
    this.toggleIconVisibility(iconOpenId);
  }

  toggleIconVisibility(elementId: string) {

    console.log('toggleVisibility');

    const input = document.getElementById(elementId);
    const inputStyle = (input as HTMLInputElement).style;

    if (inputStyle.display === 'block') {
      inputStyle.display = 'none';
    } else {
      inputStyle.display = 'block';
    }
  }

  toggleClassVisibility(cssClassName: string) {

    console.log('toggleVisibility');

    const inputs = document.getElementsByClassName(cssClassName);
    console.log(inputs);

    for(let i = 0, len = inputs.length; i < len; i++)
    {
      console.log('elementId: ',  inputs[i].id);

      // @ts-ignore
      if ( inputs[i].style.display === 'block' ) {
        // @ts-ignore
        inputs[i].style.display = 'none';
      } else {
        // @ts-ignore
        inputs[i].style.display = 'block';
      }
    }

    const iconClosedId = cssClassName + '_icon_closed';
    const iconOpenId = cssClassName + '_icon_open';

    this.toggleIconVisibility(iconClosedId);
    this.toggleIconVisibility(iconOpenId);
  }

  schoolReportsByDate(date: string) {
    const schoolReports = [];
    this.reportsInitial.map(x => {
      if(x.date === date) {
        schoolReports.push(x);
      }
    });

    return schoolReports;
  }


  dateReportsBySchool(school: string) {
    const schoolReports = [];
    this.reportsInitial.map(x => {
      if(x.school === school) {
        schoolReports.push(x);
      }
    });

    return schoolReports;
  }

  printGenderCounters() {
    if (this.reportsInitial != null && this.reportsInitial != null) {
      if (this.reportsInitial.length > 0) {

        let totalCount = 0;
        let girlsTotalCount = 0;
        let boysTotalCount = 0

        this.reportsInitial.map(x1 => {
          x1.classes.map(x2 => {
            x2.students.map(x3 => {
              if(x3.gender === 'M') { boysTotalCount++; }
              if(x3.gender === 'F') { girlsTotalCount++; }

              totalCount ++;
            });
          });
        });

        return  ' <br />[' + totalCount + ' <span style="color: dodgerblue">' + boysTotalCount +    '</span> <span style="color: lightpink">' + girlsTotalCount  +'</span>]';
      } else {
        return '';
      }

    } else {
      return '';
    }
  }

  checkTest(performedTests: any[], currentTest: string) {
    if(performedTests.find(x => x === currentTest)) {
      return 'color: white;';
    } else {
      return 'color: gray;';
    }
//    return performedTests.find(x => x === currentTest);
  }
}
