import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CoreService} from '../../../services/core.service';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {Location} from '@angular/common';
import {HttpService} from '../../../services/http.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-modal-textarea',
  templateUrl: './modal-textarea.component.html',
  styleUrls: ['./modal-textarea.component.scss'],
})
export class ModalTextareaComponent implements OnInit, OnDestroy {
  @Input() message: string;
  @Input() formName: string;



  private submitSectionSub: Subscription;

  constructor(private modalCtrl: ModalController,
              private coreService: CoreService,
              private callNumber: CallNumber,
              private clipboard: Clipboard,
              private location: Location,
              private httpService: HttpService,
              private router: Router
  ) {

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(submitSectionDataItems => {
      console.log('submitSectionSub: ');
      console.log(submitSectionDataItems);
      const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);
      if (submitSectionItem != null) {
        if (submitSectionItem.cancelBroadcasted === true) {
          this.modalCtrl.dismiss();
        }

        if (submitSectionItem.submitBroadcasted === true) {
          this.coreService.broadcastTextareaValueChanged(this.message);
          this.modalCtrl.dismiss();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    console.log('modal-textarea: ngOnInit');
    console.log('message: ' + this.message);
  }

  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
