import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {QuickMenuComponent} from '../quick-menu/quick-menu.component';
import {NavigationEnd, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {filter} from 'rxjs/operators';
import {CommonService} from '../../../services/common.service';
import {Subscription} from 'rxjs';
import {Datasources} from '../../../models/datasource.types';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {environment} from '../../../../environment';

@Component({
  selector: 'app-quick-menu-icon',
  templateUrl: './quick-menu-icon.component.html',
  styleUrls: ['./quick-menu-icon.component.scss'],
})
export class QuickMenuIconComponent implements OnInit, OnDestroy {

    version = '0.0.0';
    versionFromDownloadSite = '';
    siteName = '';

    currentUrl: string;
    dataSourceIndicator = '';

    dataSourceMessageVisible = false;
    dataSourceMessage = '';

    httpErrorReceived = false;
    httpErrorMessageVisible = false;
    httpErrorMessage = '';

    stateMessage = '';


    isStaging = false;

    private dataSourceIndicatorChangedSub: Subscription;
    private errorMessageChangedSub: Subscription;

    private httpService_stateMessageChangedSub: Subscription;

    private httpResponseCodeReceivedSub: Subscription;
    icon_httSuccessVisible = false;
    icon_httFailVisible = false;

    constructor(
      private modalCtrl: ModalController,
      private router: Router,
      private coreService: CoreService,
      private translate: TranslateService,
      private location: Location,
      private commonService: CommonService,
      private httpService: HttpService) {
        router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          console.log('prev:', event.url);
          this.currentUrl = event.url;
        });

        location.onUrlChange((url, state) => {
            //
        });


        if (environment.URL_ROOT.indexOf('sapi') > -1 || environment.AUTH_ROOT_URL.indexOf('spublic') > -1 ) {
            this.isStaging = true;
        }

  }

  ngOnDestroy() {
      if (this.dataSourceIndicatorChangedSub) {
          this.dataSourceIndicatorChangedSub.unsubscribe();
      }

      if (this.errorMessageChangedSub) {
          this.errorMessageChangedSub.unsubscribe();
      }

      if (this.httpResponseCodeReceivedSub) {
          this.httpResponseCodeReceivedSub.unsubscribe();
      }

      if (this.httpService_stateMessageChangedSub) {
          this.httpService_stateMessageChangedSub.unsubscribe();
      }
  }

  ngOnInit() {
    this.version = this.coreService.version;
    this.siteName = environment.SITE_NAME;

    if (this.versionFromDownloadSite === ''){
        this.coreService.getVersionFromDownloadServer(this.isStaging).subscribe(r => {
           this.versionFromDownloadSite = r;
        });
    }

    this.httpService_stateMessageChangedSub = this.httpService.httpService_stateMessageChanged.subscribe(stateMessage => {
        this.stateMessage = stateMessage.shortMessage;

        setTimeout(() => {
            this.stateMessage = '';
        }, 1000);
    });

    this.httpResponseCodeReceivedSub = this.httpService.httpResponseCodeReceived.subscribe(httpData => {

        // console.log('quick-menu-icon: received httpResponseCodeReceivedSub');
        const code = +httpData.status;

        if (code >= 200 && code < 300) {
            this.icon_httSuccessVisible = true;
        } else {
                if (code >= 400) {
                    this.icon_httFailVisible = true;
                    this.coreService.broadcastErrorMessage('HTTP request failed:', httpData.data);
                }
        }

        setTimeout(z => {
            this.icon_httSuccessVisible = false;
            this.icon_httFailVisible = false;
        }, 750);
    });

    this.dataSourceIndicatorChangedSub = this.coreService.dataSourceIndicatorChanged.subscribe(dsUpdate => {
       // display appropriate icon
        this.dataSourceIndicator = dsUpdate.datasource;
        this.dataSourceMessage = dsUpdate.message;
    });

    this.errorMessageChangedSub = this.coreService.errorMessageChanged
          .subscribe(
              errorMessage  => {

                  if (errorMessage.includes('401')) {
                      console.log('redirect to login');
                     // this.router.navigateByUrl('/login');
                  }

//                  console.log('errorMessageChangedSub: ');
//                  console.log(errorMessage);
                  this.httpErrorMessage = errorMessage;

                  this.httpErrorReceived = true;
                  this.httpErrorMessageVisible = true;
              }
          );
  }

  openQuickMenu() {
      this.router.navigateByUrl( '/quick-menu/' + encodeURIComponent(this.currentUrl));
  }

  closeErrorMessage() {
      this.httpErrorMessageVisible = false;
      // this.httpErrorMessage = '';
      this.httpErrorReceived = false;
  }

  closeDataSourceMessage() {
        this.dataSourceMessageVisible = false;
        // this.dataSourceMessage = '';
  }

  openSettings() {
      this.router.navigateByUrl( '/settings');
  }

}
