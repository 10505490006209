export class School {
    constructor(
        par1: string,
        par2: string,
        par3: string,
        par4: string
    ) {
        this.school_id = par1;
        this.school_code = par2;
        this.school_name = par3;
        this.students_count = par4;
    }

    public school_id: string;
    public school_code: string;
    public school_name: string;
    public  students_count: string;
}
