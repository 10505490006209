import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {LectureService} from '../../services/lecture.service';
import {DocumentsService} from '../../services/documents.service';
import {DatePipe, Location} from '@angular/common';

@Component({
  selector: 'app-documents-forms-sms-add',
  templateUrl: './documents-forms-sms-add.component.html',
  styleUrls: ['./documents-forms-sms-add.component.scss'],
})
export class DocumentsFormsSmsAddComponent implements OnInit {
  formName = 'DocuemntsFormSmsTemplateNew' + this.coreService.generateFormNamePostfix();
  template_name = '';
  template_msg = '';
  template_notes = '';
  formVisible = true;
  id = '0';

  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private documentsService: DocumentsService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id != '0') {
      this.loadTemplate();
    }

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.location.back();
            }

            if (submitSectionItem.submitBroadcasted === true) {


              if (this.id != '0') {
                this.documentsService.updateSmsTemplate(this.id, this.template_name, this.template_msg, this.template_notes ).subscribe(r => {
                  console.log(r);
                  this.coreService.broadcastToastMessage('Subject Added', false);

                  this.clearForm();

                  this.documentsService.broadcastDocumentsUpdate();

                  this.formVisible = false;
                }, err => {
                  console.log(err);
                  this.coreService.broadcastErrorMessage('Adding Subject failed ', err);
                });
              } else {
                this.documentsService.addSmsTemplate(this.template_name, this.template_msg, this.template_notes ).subscribe(r => {
                  console.log(r);
                  this.coreService.broadcastToastMessage('Subject Added', false);

                  this.clearForm();

                  this.documentsService.broadcastDocumentsUpdate();

                  this.formVisible = false;
                }, err => {
                  console.log(err);
                  this.coreService.broadcastErrorMessage('Adding Subject failed ', err);
                });
              }
            }
          }
        }
    );
  }

  loadTemplate(){
    this.documentsService.getSmsTemplate(this.id).subscribe(r => {
      console.log(r);

      this.template_msg = r[0].message;
      this.template_name = r[0].name;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Loading template failed ', err);
    });
  }

  clearForm() {
    this.template_name = '';
    this.template_msg = '';
  }

  formIsDisabled() {
    if (this.id == '0'){
      return this.template_name === '' || this.template_msg === '' || this.template_notes === '';
    } else {
     return  this.template_msg === '' || this.template_notes === '';
    }
  }
}
