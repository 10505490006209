import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingController, ModalController} from '@ionic/angular';
import {DatePipe, Location} from '@angular/common';
import {School} from '../school.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gender-selector',
  templateUrl: './gender-selector.component.html',
  styleUrls: ['./gender-selector.component.scss'],
})
export class GenderSelectorComponent implements OnInit, OnDestroy {
  @Input() isMultipleSelection = true;

  form: FormGroup;
  private selectedGenderSub: Subscription;

  availableGenders = ['M', 'F'];
  selectedGenders = [];

  constructor(private coreService: CoreService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private loadingCtrl: LoadingController,
              private modalCtrl: ModalController,
              private datepipe: DatePipe,
              private translate: TranslateService) {

      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });
  }

  ngOnDestroy() {
    if (this.selectedGenderSub) {
      this.selectedGenderSub.unsubscribe();
    }
  }

  ngOnInit() {
    console.log('gender-selector ngOnInit');
    this.form = new FormGroup({
      gender: new FormControl(null, {
        updateOn: 'blur',
        validators: [Validators.required]
      })
    });
  }

  toggleGender(gender: string) {
      // get index of selected gender
      const selectedGenderIndex = this.selectedGenders.indexOf(gender);

      // if it exists
      if (selectedGenderIndex > -1) {
          // remove it
          this.selectedGenders.splice(selectedGenderIndex, 1);
      } else {
          // otherwise - add it
          this.selectedGenders.push(gender);
          this.selectedGenders = this.selectedGenders.slice();
      }

      this.coreService.setSelectedGenders(this.selectedGenders);
  }

  // selectGender() {
  //   console.log('updateSelection');
  //   console.log('form.value.schools:');
  //   console.log(this.form.controls.gender.value);
  //
  //
  //   this.coreService.setSelectedGenders(this.selectedGenders);
  // }
}
