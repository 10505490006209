import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {VaccineService} from '../../../services/vaccine.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {VisitorService} from '../../../services/visitor.service';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import {PickerDateTimeComponent} from '../picker-date-time/picker-date-time.component';
import {ModalVaccineLotsAddComponent} from '../modal-vaccine-lots-add/modal-vaccine-lots-add.component';
import {Location} from '@angular/common';

@Component({
  selector: 'app-vaccine-lot-selector',
  templateUrl: './vaccine-lot-selector.component.html',
  styleUrls: ['./vaccine-lot-selector.component.scss'],
})
export class VaccineLotSelectorComponent implements OnInit, OnDestroy {
  studentId = '';
  lotId = '';
  notes = '';
  availableLots = [];
  lotChipsVisible = true;
  finishVisible = false;
  selectedLot = null;
  studentData = null;
  confirmSectionName = 'xxxx';
  confirmMode = false;

  showAllLots = false;

  TRANSLATIONS = null;

  submitSectionSub: Subscription;

  vaccineLotSelectedSub: Subscription;
  private vaccineLotsAvailableSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private vaccineService: VaccineService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              private location: Location,
              private translationsCommonService: TranslationsCommonService,
              private changeRef: ChangeDetectorRef) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.vaccineLotsAvailableSub) {
      this.vaccineLotsAvailableSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    console.log('loading lots');
    this.loadLots();


    this.vaccineLotsAvailableSub = this.vaccineService.vaccineLotsAvailableChanged.subscribe(r => {
        this.loadLots();
    });
  }

  openAddLotModal() {
    this.modalCtrl
        .create({
          component: ModalVaccineLotsAddComponent,
          componentProps: {  showHeader: false  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  selectLotForShot(lotId: string) {
    console.log('selectedLot: ' + lotId);
    const item = this.availableLots.find(x => x.lot === lotId);
    const index = this.availableLots.indexOf(item);
    console.log('index: ', index);
    this.selectedLot = this.availableLots.slice(index, index + 1)[0];

    this.vaccineService.broadcastSelectedLot(this.selectedLot);
  }

  loadLots() {
    this.vaccineService.getVaccineLots().subscribe(r => {
      this.availableLots = r.slice();

      this.changeRef.detectChanges();
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_LOADING_FAILED, err);
    });
  }
}
