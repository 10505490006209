import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {ScreeningService} from '../../services/screening.service';
import {TranslateService} from '@ngx-translate/core';
import {DoctorReferenceModalComponent} from '../shared/doctor-reference-modal/doctor-reference-modal.component';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';

@Component({
  selector: 'app-doctor-references',
  templateUrl: './doctor-references.component.html',
  styleUrls: ['./doctor-references.component.scss'],
})
export class DoctorReferencesComponent implements OnInit, OnDestroy {

  studentId = ''; // get param :studentId
  school = '';    // get param :school

  references = [];
  referencesFiltered = [];
  show_NoRefsInfo = false;

  searchPhrase = '';
  isLoading = false;
  showingClosed = false;

  doctorReferenceCreated: Subscription;
  doctorReferencesUpdated: Subscription;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private screeningService: ScreeningService,
              private translate: TranslateService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.doctorReferenceCreated) {
      this.doctorReferenceCreated.unsubscribe();
    }

    if (this.doctorReferencesUpdated) {
      this.doctorReferencesUpdated.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.studentId = this.route.snapshot.paramMap.get('studentId');
    this.school = this.route.snapshot.paramMap.get('school');
    this.isLoading = true;
    this.loadReferences();

    this.doctorReferenceCreated = this.screeningService.doctorReferenceCreated.subscribe(student => {
      if (student === this.studentId) {
        this.loadReferences();
      }
    });

    this.doctorReferencesUpdated = this.screeningService.doctorReferencesUpdated.subscribe(student => {
        this.loadReferences();
    });
  }

  loadReferences() {
      console.log('studentId: ', this.studentId);
      this.isLoading = true;
      this.screeningService.getStudentsReferences(this.studentId, this.showingClosed).subscribe(r => {
        console.log(r);
        this.isLoading = false;
        this.references = r;
        this.referencesFiltered = this.references.slice();

        if (this.referencesFiltered.length < 1) {
          this.show_NoRefsInfo = true;
        }

      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage('Fetching references failed ', err);
        this.isLoading = false;
      });
  }

  initiateDoctorReference(TEST_TYPE: string) {


    console.log('studentId: ' + this.studentId);
    this.modalCtrl
        .create({
          component: DoctorReferenceModalComponent,
          componentProps: { student: this.studentId, testType: TEST_TYPE, createMode: true }
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  openExistingDoctorReference(TEST_TYPE: string, referenceId: string) {

    this.modalCtrl
        .create({
          component: DoctorReferenceModalComponent,
          componentProps: { student: this.studentId, testType: TEST_TYPE, referenceId: referenceId, createMode: false }
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  filterItems() {
    const f1 = this.references.filter(x => x.test_type.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f2 = this.references.filter(x => x.notes.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f3 = this.references.filter(x => x.first_name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f4 = this.references.filter(x => x.last_name.toLowerCase().includes(this.searchPhrase.toLowerCase()));

    const f5 = f1.concat(f2).concat(f3).concat(f4);

    const uniqueItems = [...new Set(f5)];

    this.referencesFiltered = uniqueItems.slice();
    console.log('references filtered');
    console.log(this.referencesFiltered);
  }

  expandInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'block';
  }

  collapseInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'none';
  }

  toggleShowingClosed(showing: boolean) {
    this.showingClosed = showing;
    this.loadReferences();
  }

  getItemColor(currentColor: string){
    if (currentColor === 'WHITE') {
      return 'black;';
    } else {
      return currentColor;
    }
  }
}
