import {Component, Input, OnInit} from '@angular/core';
import {ScolioDetails} from '../../../models/scolio.details.model';
import {ScreeningService} from '../../../services/screening.service';
import {ModalController} from '@ionic/angular';
import {Location} from "@angular/common";

@Component({
  selector: 'app-modal-scolio-selector',
  templateUrl: './modal-scolio-selector.component.html',
  styleUrls: ['./modal-scolio-selector.component.scss'],
})
export class ModalScolioSelectorComponent implements OnInit {
  @Input() rangeMin: number;
  @Input() rangeMax: number;
  @Input() part: string;

  numbers: number[] = [];
  selectedSide = '';
  selectedNumber = '';

  constructor(
      private screeningService: ScreeningService,
      private location: Location,
      private modalCtrl: ModalController) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {
    console.log('rangeMin: ', this.rangeMin);
    console.log('rangeMax: ', this.rangeMax);
    console.log('part: ', this.part);

    for (let counter: number = this.rangeMin; counter <= this.rangeMax; counter++){
       this.numbers.push(counter);
    }
  }


  validateSelection(deg: string, dir: string) {

    if (deg === '0') {
      return true;
    } else {
      if (dir === '' || deg === '') {
        return false;
      } else {
        return true;
      }
    }
  }

  broadcastResult(deg: string, dir: string) {
    this.selectedNumber = deg;
    this.selectedSide = dir;
    console.log('selectedNumber: ', this.selectedNumber);

    const validationResult = this.validateSelection(deg, dir);
    if(validationResult){

      this.selectedSide = '';
      console.log('broadcasting result:');
      const result = new ScolioDetails(this.part, dir, deg);
      console.log(result);
      this.screeningService.setScolioSettings(result);
      this.modalCtrl.dismiss();
    }
  }

  getColor(side: string) {
    if (this.selectedSide === side) {
      return 'success';
    } else {
      if (this.selectedSide === '' && this.selectedNumber !== '') {
        return 'warning';
      } else {
        return 'primary';
      }
    }
  }
}
