import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CoreService} from '../../../services/core.service';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {HttpService} from '../../../services/http.service';
import {Router} from '@angular/router';
import {SmsModalComponent} from '../sms-modal/sms-modal.component';
import {Location} from "@angular/common";

@Component({
  selector: 'app-modal-action-students',
  templateUrl: './modal-action-students.component.html',
  styleUrls: ['./modal-action-students.component.scss'],
})
export class ModalActionStudentsComponent implements OnInit {
  @Input() studentIds: string[];
  @Input() girlsCount: number;
  @Input() boysCount: number;



  constructor(private modalCtrl: ModalController,
              private coreService: CoreService,
              private callNumber: CallNumber,
              private clipboard: Clipboard,
              private location: Location,
              private httpService: HttpService,
              private router: Router
  ) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    console.log('studentIds:');
    console.log(this.studentIds);

  }


  sendSms() {
    console.log('sendSms');
  }


  scheduleVaccination() {
    console.log('scheduleVaccination');
  }


  scheduleLecture() {
    console.log('scheduleLecture');
  }


  scheduleScreening() {
    console.log('scheduleScreening');
  }

  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

}
