import {Component, Inject, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingController, ModalController} from '@ionic/angular';
import {DatePipe, DOCUMENT, Location} from '@angular/common';
import {School} from '../school.model';
import {SchoolClass} from '../schoolclass.model';
import {HttpService} from '../../../services/http.service';
import {SchoolsService} from '../../../services/schools.service';

@Component({
  selector: 'app-class-selector',
  templateUrl: './class-selector.component.html',
  styleUrls: ['./class-selector.component.scss'],
})
export class ClassSelectorComponent implements OnInit, OnDestroy {
  @Input() showSchoolDropdown: boolean = true;
  @Input() schoolCodeToUse: string = '';
  @Input() classToPreselect: string = '';

  justLoaded = true;
  schoolCode = '';

  private availableClassesSub: Subscription;
  private selectedSchoolsSub: Subscription;
  private selectedClassesSub: Subscription;

  availableClasses = [];
  selectedClasses = [];

  selectedSchools = [];
  isLoading = false;
  touched = false;

  total_students_selected_count = 0;

  constructor(
      private coreService: CoreService,
      private schoolsService: SchoolsService,
      private route: ActivatedRoute,
      private router: Router,
      private loadingCtrl: LoadingController,
      private modalCtrl: ModalController,
      private location: Location,
      private datepipe: DatePipe,
      private httpService: HttpService) {

      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });
  }

  ngOnDestroy() {
    if (this.availableClassesSub) {
      this.availableClassesSub.unsubscribe();
    }

    if (this.selectedSchoolsSub) {
      this.selectedSchoolsSub.unsubscribe();
    }

    if (this.selectedClassesSub) {
      this.selectedClassesSub.unsubscribe();
    }
  }

  ngOnInit() {
      if (!this.httpService.isLoggedIn()){
          this.router.navigateByUrl('/login');
      }

      console.log('classToPreselect: ' + this.classToPreselect);

      setTimeout(() => {
          if (this.classToPreselect != null && this.classToPreselect !== ''){
              this.selectedClasses.push(+this.classToPreselect);

              this.coreService.setSelectedClasses(this.selectedClasses);
          }
      }, 1500);

      this.isLoading = true;
      console.log('school-selector ngOnInit');
      console.log('showSchoolDropdown: ', this.showSchoolDropdown);
      console.log('schoolCodeToUse: ', this.schoolCodeToUse);

      if (this.showSchoolDropdown === false) {

          this.coreService.loadClasses(this.schoolCodeToUse).subscribe(r => {
              const s = [];
              s.push(this.schoolCodeToUse.toLowerCase());
              this.schoolsService.setSelectedSchools(s);
          });

          // this.coreService.loadClasses(this.schoolCodeToUse).then(r => {
          //     const s = [];
          //     s.push(this.schoolCodeToUse.toLowerCase());
          //     this.schoolsService.setSelectedSchools(s);
          // });
      }

    this.availableClassesSub = this.coreService.classesAvailableChanged
        .subscribe(
            (availableClasses: School[]) => {
              this.availableClasses = availableClasses.slice();

              console.log('schoolsAvailableChanged - in subscription response');

              console.log('availableClasses length: ', availableClasses.length);
             console.log(availableClasses);
               this.isLoading = false;
            }
        );

    this.selectedClassesSub = this.coreService.classesSelectedChanged
        .subscribe(
            (classes: SchoolClass[]) => {
              console.log('classesSelectedChanged: ');
              console.log(classes);

                this.selectedClasses = [];
                this.total_students_selected_count = 0;
              classes.map(x => {
                this.selectedClasses.push(x.class_code);
                this.total_students_selected_count += + x.students_count;
              });
            }
        );

    this.selectedSchoolsSub = this.schoolsService.schoolsSelectedChanged
        .subscribe(
            (selectedSchools: School[]) => {
              this.selectedSchools = selectedSchools;
              console.log('class-selector schoolsSelectedChanged - in subscription response');
              console.log(selectedSchools);

              if (selectedSchools.length > 0){
                this.schoolCode = selectedSchools[0].school_id;
                console.log('SCHOOL_CODE: ', this.schoolCode);

                this.isLoading = true;

                  this.coreService.loadClasses(this.schoolCode).subscribe(r => {
                      //console.log('loadedClasses: ', r);
                      //this.isLoading = false;
                  });

                // this.coreService.loadClasses(this.schoolCode).then(r => {
                //   //console.log('loadedClasses: ', r);
                //   //this.isLoading = false;
                // });
              }
            }
        );
  }

  alterClassSelection(index: string) {
    console.log('in alterClassSelection');
  }

  toggleClassSelection(classCode: string) {
      this.justLoaded = false;
      console.log('toggle class: ', classCode);

      console.log('local selected classes');
      console.log(this.selectedClasses);

      const index = this.selectedClasses.indexOf(classCode);
      if ( index < 0){
          this.selectedClasses.push(classCode);
      } else {
          this.selectedClasses.splice(index, 1);
      }

      this.coreService.setSelectedClasses(this.selectedClasses);
  }

  selectAllClasses() {
      this.selectedClasses = [];
      this.availableClasses.map(c => {
          this.selectedClasses.push(c.class_code);
      });

      this.coreService.setSelectedClasses(this.selectedClasses);
  }

  clearClassSelection() {
      this.selectedClasses = [];
      this.total_students_selected_count = 0;
  }
}
