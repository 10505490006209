import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {StudentsComponent} from './components/students/students.component';
import {CommonModule} from '@angular/common';
import {StudentDetailsComponent} from './components/student-details/student-details.component';
import {LoginComponent} from './components/login/login.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {QuickMenuComponent} from './components/shared/quick-menu/quick-menu.component';
import {LectureSubjectsComponent} from './components/lecture-subjects/lecture-subjects.component';
import {LectureNewComponent} from './components/lecture-new/lecture-new.component';
import {LectureSubjectFileComponent} from './components/lecture-subject-file/lecture-subject-file.component';
import {StudentParentsComponent} from './components/student-parents/student-parents.component';
import {CalendarEventDetailsComponent} from './components/calendar-event-details/calendar-event-details.component';
import {LogoutComponent} from './components/logout/logout.component';
import {StudentsImportComponent} from './components/students-import/students-import.component';
import {StudentDocumentsComponent} from './components/student-documents/student-documents.component';
import {StudentVbookComponent} from './components/student-vbook/student-vbook.component';
import {HomePage} from './components/home/home.page';
import {VaccineLotsComponent} from './components/vaccine-lots/vaccine-lots.component';
import {DocumentsMyComponent} from './components/documents-my/documents-my.component';
import {ScreeningsComponent} from './components/screenings/screenings.component';
import {SchoolsComponent} from './components/schools/schools.component';
import {VaccineMenuComponent} from './components/vaccine-menu/vaccine-menu.component';
import {DoctorReferencesComponent} from './components/doctor-references/doctor-references.component';
import {VaccineShotComponent} from './components/vaccine-shot/vaccine-shot.component';
import {LectureSubjectNewComponent} from './components/lecture-subject-new/lecture-subject-new.component';
import {LectureMenuComponent} from './components/lecture-menu/lecture-menu.component';
import {ScreeningMenuComponent} from './components/screening-menu/screening-menu.component';
import {DocumentsFormsComponent} from './components/documents-forms/documents-forms.component';
import {DocumentsReportsComponent} from './components/documents-reports/documents-reports.component';
import {ScreeningAddComponent} from './components/screening-add/screening-add.component';
import {DocumentsFormsSmsAddComponent} from './components/documents-forms-sms-add/documents-forms-sms-add.component';
import {CacheTestComponent} from './components/cache-test/cache-test.component';
import {SettingsComponent} from './components/settings/settings.component';
import {ScreeningReportComponent} from './components/screening-report/screening-report.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {UpdateLogComponent} from './components/update-log/update-log.component';
import {ScreeningsMissingComponent} from './components/screenings-missing/screenings-missing.component';
import {SchoolsAddComponent} from './components/schools-add/schools-add.component';
import {StudentFormsComponent} from './components/student-forms/student-forms.component';
import {ScreeningPerSchoolComponent} from './components/screening-per-school/screening-per-school.component';
import {VisitorPublicformsIndexComponent} from './components/visitor-publicforms-index/visitor-publicforms-index.component';
import {VisitorPublicformsDetailsComponent} from './components/visitor-publicforms-details/visitor-publicforms-details.component';
import {DocumentsFormsHtmlAddComponent} from './components/documents-forms-html-add/documents-forms-html-add.component';
import {StudentsSchoolSelectComponent} from './components/students-school-select/students-school-select.component';
import {SchoolMenuComponent} from './components/school-menu/school-menu.component';
import {LoginManualComponent} from './components/login-manual/login-manual.component';

const routes: Routes = [
  {
    path: 'home',
    pathMatch: 'full',
    component: HomePage
  },
  {
    path: 'cache-test',
    pathMatch: 'full',
    component: CacheTestComponent
  },
  {
    path: 'update-log',
    pathMatch: 'full',
    component: UpdateLogComponent
  },
  {
    path: 'registration/:countryCode/:orgCode',
    pathMatch: 'full',
    component: RegistrationComponent
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'login-manual',
    pathMatch: 'full',
    component: LoginManualComponent
  },
  {
    path: 'logout',
    pathMatch: 'full',
    component: LogoutComponent
  },
  {
    path: 'schools',
    pathMatch: 'full',
    component: SchoolsComponent
  },
  {
    path: 'schools-add',
    pathMatch: 'full',
    component: SchoolsAddComponent
  },
  {
    path: 'screenings',
    pathMatch: 'full',
    component: ScreeningsComponent
  },
  {
    path: 'screening-menu',
    pathMatch: 'full',
    component: ScreeningMenuComponent
  },
  {
    path: 'screening-per-school',
    pathMatch: 'full',
    component: ScreeningPerSchoolComponent
  },
  {
    path: 'screening-add',
    pathMatch: 'full',
    component: ScreeningAddComponent
  },
  {
    path: 'screening-add/:school',
    pathMatch: 'full',
    component: ScreeningAddComponent
  },
  {
    path: 'doctor-references',
    pathMatch: 'full',
    component: DoctorReferencesComponent
  },
  {
    path: 'doctor-references/:studentId',
    pathMatch: 'full',
    component: DoctorReferencesComponent
  },
  {
    path: 'doctor-references/school/:school',
    pathMatch: 'full',
    component: DoctorReferencesComponent
  },
  {
    path: 'documents-my',
    pathMatch: 'full',
    component: DocumentsMyComponent
  },
  {
    path: 'documents-forms/:formType',
    pathMatch: 'full',
    component: DocumentsFormsComponent
  },
  {
    path: 'documents-forms-sms-add/:id',
    pathMatch: 'full',
    component: DocumentsFormsSmsAddComponent
  },
  {
    path: 'documents-forms-html-add/:id',
    pathMatch: 'full',
    component: DocumentsFormsHtmlAddComponent
  },
  {
    path: 'documents-reports',
    pathMatch: 'full',
    component: DocumentsReportsComponent
  },
  {
    path: 'vaccine-menu',
    pathMatch: 'full',
    component: VaccineMenuComponent
  },
  {
    path: 'vaccine-lots',
    pathMatch: 'full',
    component: VaccineLotsComponent
  },
  {
    path: 'vaccine-shot/:studentId',
    pathMatch: 'full',
    component: VaccineShotComponent
  },
  {
    path: 'vaccine-shot/:lot/:studentId',
    pathMatch: 'full',
    component: VaccineShotComponent
  },
  {
    path: 'calendar',
    pathMatch: 'full',
    component: CalendarComponent
  },
  {
    path: 'calendar/:event_type',
    pathMatch: 'full',
    component: CalendarComponent
  },
  {
    path: 'calendar/:event_type/:school_code',
    pathMatch: 'full',
    component: CalendarComponent
  },
  {
    path: 'calendar-event/:eventId',
    pathMatch: 'full',
    component: CalendarEventDetailsComponent
  },
  {
    path: 'students-import',
    pathMatch: 'full',
    component: StudentsImportComponent
  },
  {
    path: 'students/school-select',
    pathMatch: 'full',
    component: StudentsSchoolSelectComponent
  },
  {
    path: 'students/:mode',
    pathMatch: 'full',
    component: StudentsComponent
  },
  {
    path: 'students',
    pathMatch: 'full',
    component: StudentsComponent
  },
  {
    path: 'students/screening-report/:school/:class/:date',
    pathMatch: 'full',
    component: StudentsComponent
  },
  {
    path: 'screening-reports',
    pathMatch: 'full',
    component: ScreeningReportComponent
  },
  {
    path: 'screening-missing',
    pathMatch: 'full',
    component: ScreeningsMissingComponent
  },
  {
    path: 'students/school/:school/:classId',
    pathMatch: 'full',
    component: StudentsComponent
  },
  {
    path: 'students/school/:school',
    pathMatch: 'full',
    component: StudentsComponent
  },
  {
    path: 'students/school-menu/:school',
    pathMatch: 'full',
    component: SchoolMenuComponent
  },
  {
    path: 'student-details/:id',
    pathMatch: 'full',
    component: StudentDetailsComponent
  },
  {
    path: 'student-documents/:id',
    pathMatch: 'full',
    component: StudentDocumentsComponent
  },
  {
    path: 'student-forms/:id',
    pathMatch: 'full',
    component: StudentFormsComponent
  },
  {
    path: 'student-vbook/:id',
    pathMatch: 'full',
    component: StudentVbookComponent
  },
  {
    path: 'student-parents/:id',
    pathMatch: 'full',
    component: StudentParentsComponent
  },
  {
    path: 'quick-menu/:returnUrl',
    pathMatch: 'full',
    component: QuickMenuComponent
  },
  {
    path: 'quick-menu',
    pathMatch: 'full',
    component: QuickMenuComponent
  },
  {
    path: 'settings',
    pathMatch: 'full',
    component: SettingsComponent
  },
  {
    path: 'lecture-subjects',
    pathMatch: 'full',
    component: LectureSubjectsComponent
  },
  {
    path: 'lecture-subject-file/:subjectId',
    pathMatch: 'full',
    component: LectureSubjectFileComponent
  },
  {
    path: 'lecture-subject-new',
    pathMatch: 'full',
    component: LectureSubjectNewComponent
  },
  {
    path: 'lecture-menu',
    pathMatch: 'full',
    component: LectureMenuComponent
  },
  {
    path: 'lecture-new',
    pathMatch: 'full',
    component: LectureNewComponent
  },
  {
    path: 'lecture-new/:subjectId',
    pathMatch: 'full',
    component: LectureNewComponent
  },
  {
    path: 'visitor-publicforms-index/:listId',
    pathMatch: 'full',
    component: VisitorPublicformsIndexComponent
  },
  {
    path: 'visitor-publicforms-details/:formid',
    pathMatch: 'full',
    component: VisitorPublicformsDetailsComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
      CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
