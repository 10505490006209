import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {DocumentsService} from '../../services/documents.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {Location} from '@angular/common';
import {ModalTextareaComponent} from '../shared/modal-textarea/modal-textarea.component';
import {FileData} from '../shared/filedata.model';

@Component({
  selector: 'app-documents-forms-html-add',
  templateUrl: './documents-forms-html-add.component.html',
  styleUrls: ['./documents-forms-html-add.component.scss'],
})
export class DocumentsFormsHtmlAddComponent implements OnInit {
  formName = 'DocumentsFormsHtmlTemplateNew' + this.coreService.generateFormNamePostfix();
  template_name = '';
  template_msg = '';
  template_language = 'EL';
  template_notes = '';
  formVisible = true;
  id = '0';

  private submitSectionSub: Subscription;
  private textareaValuChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private documentsService: DocumentsService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.textareaValuChangedSub) {
      this.textareaValuChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id != '0') {
      this.loadTemplate();
    }

    this.textareaValuChangedSub = this.coreService.textareaValuChangedSub.subscribe(textareaNewValue => {
      console.log('newTextarea value: ' + textareaNewValue);
      this.template_msg = textareaNewValue;
    });

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.location.back();
            }

            if (submitSectionItem.submitBroadcasted === true) {

              const base64 = btoa(unescape(encodeURIComponent(this.template_msg))); // btoa(this.template_msg);

              if (this.id != '0') {
                this.documentsService.updateHtmlTemplate_htmlOnly(this.id, base64).subscribe(r => {
                  console.log(r);
                  this.coreService.broadcastToastMessage('Form updated', false);

                  this.clearForm();

                  this.documentsService.broadcastDocumentsUpdate();

                  this.formVisible = false;
                }, err => {
                  console.log(err);
                  this.coreService.broadcastErrorMessage('Updating form failed ', err);
                });

                this.documentsService.updateHtmlTemplate_notesOnly(this.id, this.template_notes).subscribe(r => {
                  console.log(r);
                  this.coreService.broadcastToastMessage('Form updated', false);

                  this.clearForm();

                  this.documentsService.broadcastDocumentsUpdate();

                  this.formVisible = false;
                }, err => {
                  console.log(err);
                  this.coreService.broadcastErrorMessage('Updating form failed ', err);
                });

              } else {
                 this.documentsService.addHtmlTemplate(this.template_name, base64, this.template_language, this.template_notes).subscribe(r => {
                  console.log(r);
                  this.coreService.broadcastToastMessage('form Added', false);
                  this.clearForm();
                  this.documentsService.broadcastDocumentsUpdate();
                  this.formVisible = false;
                }, err => {
                  console.log(err);
                  this.coreService.broadcastErrorMessage('Adding form failed ', err);
                });
              }
            }
          }
        }
    );
  }

  loadTemplate() {
    this.documentsService.getHtmlTemplate(this.id).subscribe(r => {
      console.log(r);

      this.template_name = r[0].name;
      this.template_msg = r[0].html.replace('\n', '');
      this.template_language = r[0].language;
      this.template_notes = r[0].notes;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Loading template failed ', err);
    });
  }

  clearForm() {
    this.template_name = '';
    this.template_msg = '';
    this.template_language = '';
    this.template_notes = '';
  }

  formIsDisabled() {
    if (this.id == '0') {
      return this.template_name === '' || this.template_msg === '' || this.template_notes === '' || this.template_language === '';
    } else {
      return  this.template_msg === '' || this.template_notes === '' || this.template_language === '';
    }
  }

  openTextAreaEditor() {
    this.modalCtrl
        .create({
          component: ModalTextareaComponent,
          componentProps: { message: this.template_msg, formName:  'textarea' },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
