import {Component, Input, OnInit} from '@angular/core';
import {Location} from "@angular/common";

@Component({
  selector: 'app-gender-avatar',
  templateUrl: './gender-avatar.component.html',
  styleUrls: ['./gender-avatar.component.scss'],
})
export class GenderAvatarComponent implements OnInit {
  @Input() gender = 'M';
  @Input() fontSize = 30;

  constructor(
      private location: Location) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {}
}
