import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {Observable, Subscription} from 'rxjs';
import {ImageBrowserComponent} from '../shared/image-browser/image-browser.component';
import {FilesystemDirectory, FilesystemEncoding, Plugins} from '@capacitor/core';
import {FileData} from '../shared/filedata.model';
import {ResourcesService} from '../../services/resources.service';
import {VisitorService} from '../../services/visitor.service';
import {Location} from '@angular/common';
import {FileUploadStatus} from '../shared/fileuploadstatus.model';
import {IdentityService} from '../../services/identity.service';
const { Filesystem } = Plugins;

@Component({
  selector: 'app-my-documents',
  templateUrl: './documents-my.component.html',
  styleUrls: ['./documents-my.component.scss'],
})
export class DocumentsMyComponent implements OnInit, OnDestroy {

  message = '';

  fileName = '';
  fileType = '';
  base64String = '';

  description = '';

  uploadVisible = false;

  isLoading = false;
  isUploaded = false;

  myFiles = [];
  TRANSLATIONS = null;

  visitorData = null;
  private fileLoadedChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private identityService: IdentityService,
              private visitorService: VisitorService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.fileLoadedChangedSub) {
      this.fileLoadedChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.loadFiles();

    this.identityService.profile().subscribe(res => {
      if (res !== null){
        if (res.length > 0) {
          this.visitorData = res[0];
        }
      }
    }, err => {
      console.log(err);
    });

    this.fileLoadedChangedSub = this.coreService.fileLoadedChanged.subscribe(file => {
      console.log('StudentDocumentsFileComponent.fileLoadedChangedSub');
      this.uploadFileToDb(file);
    });
  }

  // showSucessBadge() {
  //   this.isUploaded = true;
  //   setTimeout(() => {
  //     this.isUploaded = false;
  //   }, 2000);
  // }

  loadFiles() {
    this.visitorService.getVisitorFiles().subscribe(r => {
      this.myFiles = r.reverse().slice();
    });
  }

  deleteFile(itemData: any){
    console.log('deleting file');
    this.loadFiles();
    this.coreService.broadcastToastMessage('Deleting file (not implemented)', false);
  }

  uploadFileToDb( uploadedFile: FileData) {
    this.isLoading = true;

    this.visitorService.saveVisitorFile(
        this.visitorData.visitor,
        uploadedFile.file_name,
        uploadedFile.file_type,
        uploadedFile.file_description,
        uploadedFile.base64string).subscribe(r => {
      this.isLoading = false;
      // this.showSucessBadge();

      // this.uploadVisible = false;


      setTimeout(() => {
        this.uploadVisible = false;
      }, 2000);

      this.base64String = '';
      this.fileType = '';
      this.fileName = '';
      this.description = '';

      this.loadFiles();
      this.coreService.broadcastFileUploadStatus(new FileUploadStatus(this.fileName, 'File uploaded', true));

    }, err => {
      console.log(err);
      this.coreService.broadcastFileUploadStatus(new FileUploadStatus(this.fileName, 'File upload failed', false));
      this.isLoading = false;
    });
  }

  openImageBrowser(fileId: string) {
    this.modalCtrl
        .create({
          component: ImageBrowserComponent,
          componentProps: { fileId, section: 'visitor' },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
