import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PickerDateTimeComponent} from '../picker-date-time/picker-date-time.component';
import {ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../services/core.service';
import {HttpService} from '../../../services/http.service';
import {ResourcesService} from '../../../services/resources.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import {Location} from "@angular/common";

@Component({
  selector: 'app-picker-date-time-container',
  templateUrl: './picker-date-time-container.component.html',
  styleUrls: ['./picker-date-time-container.component.scss'],
})
export class PickerDateTimeContainerComponent implements OnInit, OnDestroy {
  @Input() initialDate: string;
  @Input() descriptionLabel: string;
  @Input() fromDate: string;
  @Input() icon: string;
  @Input() isInvalid = false;
  @Input() borderColor: string;
  @Input() dateType: string; // this is used to determine the color

  buttonText = '';

  private datePickerDatesChangedSub: Subscription;

  TRANSLATIONS = null;
  constructor(private modalCtrl: ModalController,
              private coreService: CoreService,
              private location: Location,
              private translate: TranslateService,
              private translationsCommonService: TranslationsCommonService) {

      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });
  }

  ngOnDestroy() {
      if (this.datePickerDatesChangedSub) {
          this.datePickerDatesChangedSub.unsubscribe();
      }
  }

  ngOnInit() {
      console.log('fromDate:');
      console.log(this.fromDate);

      console.log('initialDate: ', this.initialDate);

      if (this.initialDate === '' || this.initialDate === undefined){
              this.buttonText = this.translationsCommonService.TRNSL_DATE;
      } else {
          this.buttonText = this.initialDate;
      }

     // this.buttonText =  this.initialDate;
      this.datePickerDatesChangedSub = this.coreService.datePickerDatesChanged
          .subscribe(
              dates  => {
                  console.log('datePickerDatesChangedSub: ');
                  console.log(dates);

                  const dtToUpdate = dates.find(x => x.descriptionLabel === this.descriptionLabel);

                  if (dtToUpdate != null){
                      this.initialDate = dtToUpdate.date;
                      console.log('updating initialDate: ', this.initialDate);
                      this.buttonText = this.initialDate;
                  }
              }
          );
  }
    openDatePicker(date: string, label: string) {
       // this.selectedDate = date;

        this.modalCtrl
            .create({
                component: PickerDateTimeComponent,
                componentProps: { initialDate: this.initialDate, descriptionLabel: this.descriptionLabel, fromDate: this.fromDate  },
                cssClass: 'bg-none'
            })
            .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
            });
    }

    getClassToUse(defaultClass: string) {

      let classToUse = defaultClass;

      if (this.dateType === 'start') {
            classToUse = classToUse + '_Start';
      } else if (this.dateType === 'end') {

          classToUse = classToUse + '_End';
      }

      if (this.isInvalid === true) {
          classToUse = classToUse + ' ' + 'warning-field';
      }

      return classToUse;
    }
}
