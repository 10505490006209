import {AfterContentChecked, AfterViewChecked, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {ScreeningService} from '../../services/screening.service';
import {TranslateService} from '@ngx-translate/core';
import {School} from '../shared/school.model';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {Location} from '@angular/common';
import {SchoolsService} from '../../services/schools.service';

@Component({
  selector: 'app-screenings',
  templateUrl: './screenings.component.html',
  styleUrls: ['./screenings.component.scss'],
})
export class ScreeningsComponent implements OnInit, OnDestroy, AfterViewChecked, AfterContentChecked, AfterViewChecked {

  screenings = [];
  screeningsFiltered = [];
  currentlyDisplayedStudents = [];
  searchPhrase = '';
  isLoading = false;
  private selectedSchoolsSub: Subscription;
  private loadingStateChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private schoolsService: SchoolsService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private screeningService: ScreeningService,
              private translate: TranslateService,
              private zone: NgZone) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.selectedSchoolsSub) {
      this.selectedSchoolsSub.unsubscribe();
    }

    if (this.loadingStateChangedSub) {
      this.loadingStateChangedSub.unsubscribe();
    }
  }

  ngAfterViewChecked(){

    console.log('ngAfterViewChecked');
  }

  ngAfterContentChecked(){
    console.log('ngAfterContentChecked');
  }
  ngAfterViewInit(){
    console.log('ngAfterViewInit');
  }



  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = true;

    this.screeningService.getScreenings('').subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.screenings = r;

      let index = 0;
      this.screeningsFiltered.map(x => {
        this.currentlyDisplayedStudents[index] = [];
        index++;
      });

      console.log('currentlyDisplayedStudents');
      console.log(this.currentlyDisplayedStudents);

    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching screenings failed ', err);
      this.isLoading = false;
    });

    this.selectedSchoolsSub = this.schoolsService.schoolsSelectedChanged
        .subscribe(
            (selectedSchools: School[]) => {
              console.log('111.searchPhrase: ', this.searchPhrase);

              this.isLoading = true;
              console.log('111.screenings.selectedSchoolsSub');
              console.log(selectedSchools);

              console.log('111.screenings');
              console.log( this.screenings);
              let f = [];
              selectedSchools.map(s => {
                const f1 = this.screenings.filter(x => x.school.toString().toLowerCase().includes(s.school_id.toString().toLowerCase()));
                f = f.concat(f1);
              });

              const uniqueItems = [...new Set(f)];

              this.screeningsFiltered = uniqueItems.slice();
              console.log('screenings filtered');
              console.log(this.screeningsFiltered);
              this.isLoading = false;

              //this.searchPhrase = '';
            }
        );

    this.loadingStateChangedSub = this.coreService.loadingStateChanged.subscribe( x => {
              console.log('screening: received loadingStateChanged');
              this.isLoading = true;
            }
        );

  }

  filterItems() {
    console.log('searchPhrase: ', this.searchPhrase);

    // if (this.searchPhrase.length === 0){
    //     console.log('phrase length 0: cleaning result');
    //    this.screeningsFiltered = [];
    //    this.isLoading = false;
    //  } else {

    if (this.searchPhrase.length < 3 ){
        //console.log('searchPhrase too short. skipping search');

           console.log('searchPhrase too short: ', this.searchPhrase);
            this.screeningsFiltered = [];
            this.isLoading = false;
      } else {

        console.log('phrase length >2 : filtering...: ', this.searchPhrase);
        this.coreService.broadcastLoadingState();
        setTimeout(() => {

          this.schoolsService.setSelectedSchools([]); // clean school selection
          const f1 = this.screenings.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
          const f2 = this.screenings.filter(x => x.school.toLowerCase().includes(this.searchPhrase.toLowerCase()));
          //const f3 = this.screenings.filter(x => x.date.toLowerCase().includes(this.searchPhrase.toLowerCase()));

          // console.log('f1');
          // console.log(f1);
          //
          // console.log('f2');
          // console.log(f2);
          const f4 = f1.concat(f2); //.concat(f3);

          const uniqueItems = [...new Set(f4)];

          this.screeningsFiltered = uniqueItems.slice();
          console.log('screenings filtered');
          console.log(this.screeningsFiltered);

          this.isLoading = false;
        } , 200);
      }
   // }
  }

  testAvailable(test_types: string[], test: string) {
    return test_types.indexOf(test) > -1;
  }

  toggleStudentInfo(itemId: string, studentIds: string[], index: number) {

    console.log('itemId: ', itemId);
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    // loading spinner
    const inputSpinner = document.getElementById('screeningInfoLoadingSpinner_' + index);
    const inputStyleSpinner = (inputSpinner as HTMLInputElement).style;
    inputStyleSpinner.display = 'block';

    if (inputStyle.display === 'block'){
      inputStyle.display = 'none';
    } else {
      inputStyle.display = 'block';

      //load student list
      this.screeningService.getScreeeningStudents(studentIds).subscribe(r => {
        console.log(r);

        console.log('assigning students, index: ', index);
        console.log(r);

        this.currentlyDisplayedStudents[index] = r;

        console.log(this.currentlyDisplayedStudents);
        inputStyleSpinner.display = 'none';
      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage('Fetching students failed ', err);
        this.isLoading = false;
        inputStyleSpinner.display = 'none';
      });
    }
  }

  collapseInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'none';
  }
}
