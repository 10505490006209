import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {VisitorService} from '../../services/visitor.service';
import {FileData} from '../shared/filedata.model';
import {ImageBrowserComponent} from '../shared/image-browser/image-browser.component';
import {LectureService} from '../../services/lecture.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-lecture-subject-new',
  templateUrl: './lecture-subject-new.component.html',
  styleUrls: ['./lecture-subject-new.component.scss'],
})
export class LectureSubjectNewComponent implements OnInit, OnDestroy {
  formName = 'LectureSubjectNewForm' + this.coreService.generateFormNamePostfix();
  selectedGenders = [];
  subject_code = '';
  subject_name = '';

  private selectedGenderChanged: Subscription;
  private submitSectionSub: Subscription;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private lectureService: LectureService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.selectedGenderChanged) {
      this.selectedGenderChanged.unsubscribe();
    }

    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.selectedGenderChanged = this.coreService.selectedGenderChanged
        .subscribe(g => {
              this.selectedGenders = g;
            }
        );

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.subject_name = '';
              this.subject_code = '';
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.lectureService.addLectureSubject(this.subject_code, this.subject_name, this.selectedGenders).subscribe(r => {
                console.log(r);
                this.coreService.broadcastToastMessage('Subject Added', false);

                this.clearForm();

                this.router.navigateByUrl('/lecture-subjects');
              }, err => {
                console.log(err);
                this.coreService.broadcastErrorMessage('Adding Subject failed ', err);
              });
            }
          }
        }
    );
  }

  clearForm() {
    this.subject_code = '';
    this.subject_name = '';
    this.selectedGenders = [];
  }

}
