import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {environment} from '../../environment';
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

    documentsChanged = new EventEmitter<string>();


    constructor(private coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    public FORM_TYPES = {
        SMS: 'SMS',
        HTML:  'HTML'
    };

    broadcastDocumentsUpdate() {
        this.documentsChanged.emit('');
    }

    getSmsFormTemplates() {
        console.log('getSmsFormTemplates');
        return this.httpService.get(environment.URL_ROOT + '/TSmsTemplates')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getHtmlFormTemplates() {
        console.log('getHtmlFormTemplates');
        return this.httpService.get(environment.URL_ROOT + '/FormTemplates')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getFormsets() {

        console.log('getHtmlFormTemplates');
        return this.httpService.get(environment.URL_ROOT + '/GetFormsets')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getFormTemplates(formType: string) {


        // console.log('getHtmlFormTemplates');
        // return this.httpService.get(environment.URL_ROOT + '/GetFormsets')
        //     .pipe(map(r => {
        //         console.log(r);
        //         return r;
        //     }));

        if (formType === this.FORM_TYPES.SMS) {
            return this.getSmsFormTemplates();
        } else if (formType === this.FORM_TYPES.HTML) {
            return this.getHtmlFormTemplates();
        }
    }

    getSmsTemplate(id: string){
        console.log('getSmsTemplate');
        return this.httpService.get(environment.URL_ROOT + '/TSmsTemplates?id=eq.' + id)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getHtmlTemplate(id: string){
        console.log('getHtmlTemplate');
        return this.httpService.get(environment.URL_ROOT + '/FormTemplates?template=eq.' + id)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }
    //
    // updateHtmlTemplate(this.id, this.template_name, base64, this.template_language, this.template_notes)


    updateHtmlTemplate_htmlOnly(id: string,  base64: string ) {
        const postData = {
            _template: id,
            _base64: base64
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/FormTemplateUpdateHtml', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    updateHtmlTemplate_notesOnly(id: string,  template_notes: string ) {
        const postData = {
            notes: template_notes
        };

        console.log(postData);
        return this.httpService.patch(environment.URL_ROOT + '/FormTemplates?template=eq.' + id, postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    updateSmsTemplate(id: string, name: string, message: string, notes: string ) {
        const postData = {
            name,
            message,
            notes
        };

        console.log(postData);
        return this.httpService.patch(environment.URL_ROOT + '/TSmsTemplates?id=eq.' + id, postData)
        .pipe(map(r => {
            console.log(r);
            return r;
        }));
    }

    addSmsTemplate(name: string, message: string, notes: string ) {
        const postData = {
            name: name.replace(' ', '').toUpperCase(),
            message,
            notes
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/TSmsTemplates', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    addHtmlTemplate(name: string, base64: string, language: string, notes: string ) {
        const postData = {
            _base64: base64,
            _name: name.replace(' ', '').toUpperCase(),
            _language: language,
            _notes: notes
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/FormTemplateUpload', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }
}
