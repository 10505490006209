import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class VaccineService {

    constructor(private coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    public SYRINGE_COLORS = {
        RED: 'red',
        ORANGE:  'orange',
        WHITE: 'white',
        GREEN: 'green'
    };

    get vaccinations() {
        return this._vaccinations.asObservable();
    }

    private tokenCookie: string;
    selectedSchoolCode = null;

    vaccineLotSelectedChanged = new EventEmitter<any>();
    vaccineLotsAvailableChanged = new EventEmitter<any>();

    // tslint:disable-next-line:variable-name
    private _vaccinations = new BehaviorSubject<[]>([]);

    broadcastInfoAboutAddedLot(lot: any) {

        // this.getVaccineLots().subscribe(x => {
        //     this.vaccineLotSelectedChanged.emit(x);
        // });

        this.vaccineLotsAvailableChanged.emit(lot);
    }

    broadcastSelectedLot(lot: any) {
        this.vaccineLotSelectedChanged.emit(lot);
    }

    setSchool(schoolCode: string) {
        this.selectedSchoolCode = schoolCode;
    }

    getVaccines() {
        console.log('getVaccines');
        return this.httpService.get(environment.URL_ROOT + '/GetVaccines')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getVaccineTypes() {
        console.log('getVaccineTypes');
        return this.httpService.get (environment.URL_ROOT + '/GetVaccines')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getVaccineProducers() {
        console.log('GetVaccinesProducers');
        return this.httpService.get (environment.URL_ROOT + '/GetVaccinesProducers')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getVaccineLots(urlParameters: string = '') {
        console.log('getVaccineLots');
        return this.httpService.get(environment.URL_ROOT + '/GetVaccinesLots' + urlParameters)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    getStudentsSyringes(studentIds: string[], lot_id: string) {
        console.log('getStudentsSyringes');
        const postData = {
            _students: studentIds,
            _lot: lot_id
        };

        const urlToUse = environment.URL_ROOT + '/rpc/getStudentSyringes';
        console.log(urlToUse);

        return this.httpService.post(urlToUse, postData)
            .pipe(map(syringes => {
                console.log('syringes:');
                console.log(syringes);
                return syringes;
            }));
    }

    addProducer(producer: string, name: string) {

        const postData = {
            _producer: producer,
            _name: name
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/AddVaccinesProducer', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addVaccineLot(lot: string, expDate: string, producer: string, vaccines: string[]) {

        const vaccinesString = this.coreService.buildIdsString(vaccines);
        const postData = {
            _lot_id: lot,
            _exp_dt: expDate,
            _producer: producer,
            _vaccines: vaccinesString
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/AddVaccineLot', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }




    vaccineSaveShotNEW(student: string, lot_id: string, notes: string) {

        // let statusToUse = 'COMPLETED';
        // if (action === 'SKIP'){
        //     statusToUse = 'SKIP';
        // }

        const postData = {
            _student: student,
            _lot_id: lot_id,
            _notes: notes
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/AddVaccineShot', postData)
            .pipe(
                map(r => {
                    console.log('vaccineSaveShot RESULT:');
                    console.log(r);

                    return r;
                })
            );
    }
}
