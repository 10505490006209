import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationsCommonService {


    TRNSL_HOMEPAGE_SCREENING = '';




    TRNSL_DATEPICKER_DAY_LETTERS = [];

    TRNSL_DATA_SAVED_SUCCESSFULLY = '';
    TRNSL_DATA_SAVING_FAILED = '';
    TRNSL_DATA_LOADING_FAILED = '';

    TRNSL_SCREENING_MESSAGE_SAVE_SUCCESS = '';
    TRNSL_SCREENING_MESSAGE_SAVE_FAIL = '';
    TRNSL_SCREENING_MESSAGE_FIX_DATA = '';
    TRNSL_MESSAGE_CONFIRM_DELETE_STUDENT = '';

    TRNSL_SENT_SUCCESSFULLY = '';
    TRNSL_SENDING_FAILED = '';


    TRNSL_CALENDAR_PAGE_TITLE = '';
    TRNSL_LECTURES_PAGE_TITLE = '';
    TRNSL_SCREENING_PAGE_TITLE = '';

    TRNSL_DATE = '';

    TRNSL_STUDENTS_IMPORT = '';

    TRNSL_VACCINE_LOTS_ADD = '';


    constructor(private coreService: CoreService,
                private http: HttpClient,

                private router: Router,
                private httpService: HttpService,
                private translate: TranslateService) {


        this.translate.get('menu_icons.SCREENING').subscribe(t => { this.TRNSL_HOMEPAGE_SCREENING = t;  });

        this.translate.get('common.DATEPICKER_DAY_LABELS_sunday').subscribe(t => { this.TRNSL_DATEPICKER_DAY_LETTERS.push(t);  });
        this.translate.get('common.DATEPICKER_DAY_LABELS_monday').subscribe(t => { this.TRNSL_DATEPICKER_DAY_LETTERS.push(t);  });
        this.translate.get('common.DATEPICKER_DAY_LABELS_tuesday').subscribe(t => { this.TRNSL_DATEPICKER_DAY_LETTERS.push(t);  });
        this.translate.get('common.DATEPICKER_DAY_LABELS_wednesday').subscribe(t => { this.TRNSL_DATEPICKER_DAY_LETTERS.push(t);  });
        this.translate.get('common.DATEPICKER_DAY_LABELS_thursday').subscribe(t => { this.TRNSL_DATEPICKER_DAY_LETTERS.push(t);  });
        this.translate.get('common.DATEPICKER_DAY_LABELS_friday').subscribe(t => { this.TRNSL_DATEPICKER_DAY_LETTERS.push(t);  });
        this.translate.get( 'common.DATEPICKER_DAY_LABELS_saturday').subscribe(t => { this.TRNSL_DATEPICKER_DAY_LETTERS.push(t);  });

        this.translate.get('common.DATA_SAVED_SUCCESSFULLY').subscribe(t => { this.TRNSL_DATA_SAVED_SUCCESSFULLY = t; });
        this.translate.get('common.DATA_SAVING_FAILED').subscribe(t => { this.TRNSL_DATA_SAVING_FAILED = t; });
        this.translate.get('common.DATA_LOADING_FAILED').subscribe(t => { this.TRNSL_DATA_LOADING_FAILED = t; });

        this.translate.get('screening.MESSAGE_SAVE_SUCCESS').subscribe(t => { this.TRNSL_SCREENING_MESSAGE_SAVE_SUCCESS = t; });
        this.translate.get('screening.MESSAGE_SAVE_FAIL').subscribe(t => { this.TRNSL_SCREENING_MESSAGE_SAVE_FAIL = t; });
        this.translate.get('screening.MESSAGE_FIX_DATA').subscribe(t => { this.TRNSL_SCREENING_MESSAGE_FIX_DATA = t; });

        this.translate.get('common.MESSAGE_CONFIRM_DELETE_STUDENT').subscribe(t => { this.TRNSL_MESSAGE_CONFIRM_DELETE_STUDENT = t; });

        this.translate.get('messages.SENT').subscribe(t => { this.TRNSL_SENT_SUCCESSFULLY = t; });

        this.translate.get('menu_icons.CALENDAR').subscribe(t => { this.TRNSL_CALENDAR_PAGE_TITLE = t; });
        this.translate.get('header_titles.CALENDAR').subscribe(t => { this.TRNSL_LECTURES_PAGE_TITLE = t;  });
        this.translate.get('header_titles.CALENDAR').subscribe(t => { this.TRNSL_SCREENING_PAGE_TITLE = t;  });


        this.translate.get('common.DATE').subscribe(t => { this.TRNSL_DATE = t;  });
        this.translate.get('header_titles.STUDENTS_IMPORT').subscribe(t => { this.TRNSL_STUDENTS_IMPORT = t;  });


        this.translate.get('header_titles.VACCINE_LOTS_ADD').subscribe(t => { this.TRNSL_VACCINE_LOTS_ADD = t;  });

    }
}
