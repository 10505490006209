import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../services/core.service';
import {environment} from '../../../../environment';
import {version} from '../../../../version';
import {HttpService} from '../../../services/http.service';
import {IdentityService} from '../../../services/identity.service';

@Component({
selector: 'app-header',
templateUrl: './header.component.html',
styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

@Input() title = '';
@Input() closeUrl = '';
@Input() BACKBUTTON_URL = '';
@Input() showNewVersionInfo = false;


showNewVersionInfo_internal = false;
currentUrl: string;
isStaging = false;

currentUsername = '';

refreshClasses = 'refreshButton colorMaleOnBg';


versionFromDownloadSite = '';

constructor(private route: ActivatedRoute,
private router: Router,
private navCtrl: NavController,
private coreService: CoreService,
private httpService: HttpService,
private sanitizer: DomSanitizer,
private identityService: IdentityService,
private location: Location) {

// location.onUrlChange((url, state) => {
//   this.ngOnInit();
// });

if (environment.URL_ROOT.indexOf('sapi') > -1 || environment.AUTH_ROOT_URL.indexOf('spublic') > -1 ) {
this.isStaging = true;
}
}


ngOnDestroy() {

}

ngOnInit() {
setInterval(() => {
//this.versionFromDownloadSite = '';
this.checkVersionFromDownloadSite();
}, 15000);


if (!this.httpService.isLoggedIn()) {
} else {


this.identityService.profile().subscribe(res => {
if (res !== null){
if (res.length > 0) {
this.currentUsername = res[0].username;
}
        }
}, err => {
console.log(err);
});
}
}


downloadNewVersion() {
if (this.isStaging) {
window.location.href = 'https://scoolmed.com/apk/app-debug.apk';
} else {
window.location.href = 'https://scoolmed.com/apk/scoolmed.apk';
}
}

openQuickMenu() {
this.router.navigateByUrl( '/quick-menu');
}

openSettings() {
this.router.navigateByUrl( '/settings');
}

titleSanitized(title) {
return this.sanitizer.bypassSecurityTrustHtml(title);
}

goback() {
this.location.back();
}

returnHome() {
this.router.navigateByUrl('/home' );
}


refresh() {
this.refreshClasses = 'refreshButtonClicked colorMaleOnBg';
setTimeout(() => {
this.refreshClasses = 'refreshButton colorMaleOnBg';
} , 800);


this.coreService.broadcastRefreshRequest();
}


redirectBack(){
this.router.navigateByUrl(this.BACKBUTTON_URL);
}


checkVersionFromDownloadSite() {
//if (this.versionFromDownloadSite === '') {
this.coreService.getVersionFromDownloadServer(this.isStaging).subscribe(r => {
this.versionFromDownloadSite = r.replace(' ', '').replace('\'', '').replace('\'', '').toString();


//      console.log('versionFromDownloadSite: ' + +this.versionFromDownloadSite);

//      console.log('appVersion: ' +  +version.app_version);


// if (this.versionFromDownloadSite.toString().indexOf(version.app_version.toString()) > -1) {
//     this.showNewVersionInfo = false;
// }else{
//     this.showNewVersionInfo = true;
// }


const versionFromDownloadSiteNumber = +this.versionFromDownloadSite;
const app_versionNumber = +version.app_version;

if (Number.isNaN(versionFromDownloadSiteNumber)) {
this.showNewVersionInfo = false;
} else {
if (versionFromDownloadSiteNumber > app_versionNumber) {
this.showNewVersionInfo = true;
} else {
this.showNewVersionInfo = false;
}
        }
});
//}
}

        }
