import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {CoreService} from '../../../services/core.service';
import {SubmitSectionData} from '../../shared/submitsectiondata.model';
import {TileTypeVisibility} from '../../shared/tiletestvisibility.model';

@Component({
  selector: 'app-tile-chroma',
  templateUrl: './tile-chroma.component.html',
  styleUrls: ['./tile-chroma.component.scss'],
})
export class TileChromaComponent implements OnInit {
  @Input() testForm_CHROMA_Visible: boolean;
  @Input() testForm_VISION_Visible: boolean;
  @Input() testData: any;
  @Input() test_vision_eye_left: string;
  @Input() test_vision_eye_right: string;

  TILE_TYPE = 'vision';

  constructor(
      private location: Location,
      private coreService: CoreService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {
    console.log('chroma');
    console.log(this.testData);

  }

  toggle_VISION_FormVisibility() {
    if (this.testForm_VISION_Visible === false) {
      this.testForm_VISION_Visible = true;
    } else {
      this.testForm_VISION_Visible = true;
    }

    this.coreService.brodcastTestTileVisibiltyChange(new TileTypeVisibility(this.testForm_VISION_Visible, 'VISION'));
  }

  toggle_CHROMA_FormVisibility() {
    if (this.testForm_CHROMA_Visible === false) {
      this.testForm_CHROMA_Visible = true;
    } else {
      this.testForm_CHROMA_Visible = true;
    }
    this.coreService.brodcastTestTileVisibiltyChange(new TileTypeVisibility(this.testForm_CHROMA_Visible, 'CHROMA'));
  }
}
