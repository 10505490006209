import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {Calendar} from '@ionic-native/calendar/ngx';
import {CoreService} from '../../services/core.service';
import {HttpService} from '../../services/http.service';
import {CachingService} from "../../services/caching.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  lectures = [];

  platformMobile = true;
  constructor(private router: Router,
              public navCtrl: NavController,
              //     private deeplinks: Deeplinks,
              private calendar: Calendar,
              private plt: Platform,
              private coreService: CoreService,
              private location: Location,
              private httpService: HttpService,
              private platform: Platform,
              private cachingService: CachingService) {
    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });

    if (this.platform.is('desktop') || this.platform.is('mobileweb')  ) {
      this.platformMobile = false;
    } else {
      this.platformMobile = true;
    }
  }

  ngOnInit() {
    this.httpService.logout();
    // localStorage.clear();
  //  this.cachingService.clearCache();

    if (this.platformMobile){
      window.location.href = '/login';
    } else {
      this.router.navigateByUrl('/login');
    }
  }
}
