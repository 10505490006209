import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {CoreService} from '../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {CookieService} from 'ngx-cookie-service';
import {CommonModule, Location} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {VisitorService} from '../../services/visitor.service';
import {RegistrationService} from '../../services/registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {

  countryCode = '';
  organizationId = '';
  message = '';
  firstName = '';
  lastName = '';
  idCard = '';
  email = '';
  phonePrefix = '';
  phone = '';

  userType = '';

  resultNote = '';

  joinMode = false;
  joinOrgId = '';
  joinOrgName = '';

  newOrgName = '';

  stage1visible = true;
  stage2visible = false;
  stage3visible = false;

  constructor(
      private coreService: CoreService,
      public router: Router,
      private route: ActivatedRoute,
      private loadingCtrl: LoadingController,
      private httpService: HttpService,
      private visitorService: VisitorService,
      private location: Location,
      private registrationService: RegistrationService,
      private cookieService: CookieService
      //    private deviceAccounts: DeviceAccounts
  ) {
    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {
  //  if (this.httpService.isLoggedIn()) {
     // this.router.navigateByUrl('/students');
  //  }

    // if (!this.httpService.isLoggedInPublic()) {
    //   this.httpService.loginPublicFunctions().subscribe(x => {
    //     console.log(x);
    //   });
    // }

    this.countryCode = this.route.snapshot.paramMap.get('countryCode');
    this.organizationId = this.route.snapshot.paramMap.get('orgCode');

    if (this.organizationId === '0'){
      this.joinMode = false;
    } else {
      this.joinMode = true;
      this.joinOrgId = this.organizationId;
      this.getOrganizationDetails();
    }
  }

  getOrganizationDetails() {
      this.registrationService.getOrganizationDetails(this.joinOrgId).subscribe(res => {
        this.joinOrgName = res[0].name;
      }, err => {
        console.log(err);
        this.message = err.message;
      });
  }

  onStage1Submit() {

    if (this.joinMode) {

      this.visitorService.registerInExistingOrg(this.countryCode,
          this.organizationId,
          this.firstName,
          this.lastName,
          this.email,
          this.phonePrefix,
          this.phone,
          this.idCard).subscribe(res => {

        this.stage1visible = false;
        this.stage2visible = true;
        this.stage3visible = false;

        this.message =  res[0].msg; ///this.joinOrgName.toUpperCase() + ' manager was notified about your registration';

      }, err => {
        console.log(err);
        this.message = err.message;
      });

    } else {

      this.visitorService.registerInNewOrg(
          this.newOrgName,
          this.countryCode,
          this.organizationId,
          this.firstName,
          this.lastName,
          this.email,
          this.phonePrefix,
          this.phone,
          this.idCard).subscribe(res => {

        this.stage1visible = false;
        this.stage2visible = true;
        this.stage3visible = false;

        this.message =  res[0].msg; ///this.joinOrgName.toUpperCase() + ' manager was notified about your registration';

      }, err => {
        console.log(err);
        this.message = err.message;
      });
    }
  }
}
