import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {Location} from '@angular/common';
import {LectureService} from '../../services/lecture.service';
import {SchoolsService} from "../../services/schools.service";

@Component({
  selector: 'app-schools-add',
  templateUrl: './schools-add.component.html',
  styleUrls: ['./schools-add.component.scss'],
})
export class SchoolsAddComponent implements OnInit, OnDestroy {
  formName = 'SchoolAddForm' + this.coreService.generateFormNamePostfix();
  code = '';
  name = '';
  type = 'P';
  phone = '';
  address = '';
  post_code = '';
  fax = '';
  email = '';
  city = 1;

  private submitSectionSub: Subscription;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private schoolsService: SchoolsService,
              private location: Location,
              private lectureService: LectureService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }



    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.clearForm();
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.schoolsService.addSchool(  this.code.toUpperCase(),
              this.name,
              this.type,
              this.phone,
              this.address,
              this.post_code,
              this.fax,
              this.email,
              this.city.toString()).subscribe(r => {
                console.log(r);
                this.coreService.broadcastToastMessage('School Added', false);

                this.clearForm();

                this.router.navigateByUrl('/schools');
              }, err => {
                console.log(err);
                // this.coreService.broadcastErrorMessage('Adding School failed ', err);
              });
            }
          }
        }
    );
  }

  clearForm() {
    this.code = '';
    this.name = '';
    this.type = 'P';
    this.phone = '';
    this.address = '';
    this.post_code = '';
    this.fax = '';
    this.email = '';
    this.city = 1;
  }

}
