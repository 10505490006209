import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';

// import {TRANSLATIONS_EL} from './shared/resources/resources.el';
// import {TRANSLATIONS_EN} from './shared/resources/resources.en';

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {
    private langCookie = 'en';

    constructor(private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {}

    public getLangCode() {

        this.langCookie = this.cookieService.get('lang');

        if (this.langCookie === undefined || this.langCookie === null) {
            this.langCookie = 'en';
        }

        return this.langCookie;
    }
}
