import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {Visitor} from '../components/shared/visitor.model';
import {Event} from '../components/shared/event.model';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {School} from '../components/shared/school.model';
import {ScolioDetails} from '../models/scolio.details.model';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class ScreeningService {
    TEST_TYPES = {
        SCOLIO: 'SCOLIO',
        AUDIO:  'AUDIO',
        CHROMA: 'CHROMA',
        VISION: 'VISION',
        BMI:    'BMI'
    };

    DOCTOR_REFERENCE_COLORS = { GREEN: 'GREEN', ORANGE: 'ORANGE', RED: 'RED', WHITE: 'WHITE' };

    scolioSettingsChanged = new EventEmitter<ScolioDetails>();
    doctorReferenceCreated = new EventEmitter<string>();
    doctorReferencesUpdated = new EventEmitter<string>();

    CalendarSyncChanged = new EventEmitter<boolean>();
    constructor(private coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    getReference(referenceId: string, showClosed = false) {
        console.log('getReference');
        return this.httpService.get(environment.URL_ROOT + '/GetReferrals?referral=eq.' + referenceId)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getStudentsReferences(studentId: string, showClosed = false, school: string = '') {
        console.log('getStudentsReferences');

        let parameter = '';
        if (studentId !== '' && studentId !== null) {
            parameter = 'student=eq.' + studentId;
        }

        if (showClosed) {
        } else {
            parameter = parameter + '&status=neq.CLOSED';
        }

        if (school !== '' && school !== null) {
            parameter = '&school=eq.' + school;
        }

        parameter = parameter.replace('&&', '&');

        return this.httpService.get(environment.URL_ROOT + '/GetReferrals?' + parameter)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getScreeningsPerSchool() {
        console.log('getScreeningsPerSchool');
        return this.httpService.get (environment.URL_ROOT + '/ScreeningsPerSchool')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getStudentsTestResult(studentId: string) {
        console.log('getStudentsTestResult');

        const postData = {
            student_id: studentId
        };

        return this.httpService.post(environment.URL_ROOT + '/rpc/GetStudentTestResults', postData)
            .pipe(map(testData => {
                console.log(testData);
                return testData;
            }));
    }

    setScolioSettings(scolioDetails: ScolioDetails){
        this.scolioSettingsChanged.emit(scolioDetails);
    }

    getScreeeningStudents(ids: string[]) {

        console.log('ids:');
        console.log(ids);
        const numIds = [];
       // ids.map(item => { numIds.push(+item); });

        const postData = {
            _students: ids
        };

        console.log('getScreeeningStudents.postData:');
        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/GetStudents', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getScreenings(filterData: string) {
        console.log('1.getScreenings:');
        console.log('1.filterData:');
        console.log(filterData);
        return this.httpService.get (environment.URL_ROOT + '/Screenings?' + filterData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    screening_createEvent(start_dt: string,
                          end_dt: string,
                          selectedClasses: string[],
                          notes: string) {

        console.log('screening_createEvent');

        const classesString = this.coreService.buildIdsString(selectedClasses, '[]');
        const postData = {
            _start_dt: start_dt,
            _end_dt: end_dt,
            _notes: notes,
            _classes: classesString
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/EventScreeningAdd', postData)
            .pipe(map(r => {
                console.log(r);

                this.CalendarSyncChanged.emit(true);
                return r;
            }));
    }

    getScreeningReports(scRepSchool: string, scRepClass: string, scRepDate: string) {
        const urlParameters = 'school=' + scRepSchool + '&class=' + scRepClass + '&date=' + scRepDate;
        return this.httpService.get(environment.URL_ROOT + '/getScreeningReports?' + urlParameters)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getAllScreeningReports() {
        return this.httpService.get(environment.URL_ROOT + '/getScreeningReports')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getMissingScreenings() {
        return this.httpService.get(environment.URL_ROOT + '/MissingScreeningTests')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    screening_addScolio(student: string,
                        need_doctor: boolean,
                        neck_dir: string,
                        neck_deg: string,
                        chest_dir: string,
                        chest_deg: string,
                        lumb_dir: string,
                        lumb_deg: string,
                        notes: string) {

        const postData = {
            _student: student,
            _neck_dir: neck_dir,
            _neck_deg: neck_deg,
            _chest_dir: chest_dir,
            _chest_deg: chest_deg,
            _lumb_dir: lumb_dir,
            _lumb_deg: lumb_deg,
            _notes: notes
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/AddScolioScreeningTest', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    screening_addVision(student: string,
                        left_eye_row: string,
                        right_eye_row: string,
                        notes: string,
                        has_glasses: boolean) {

        const postData = {
            _student: student,
            _left_eye_row: left_eye_row,
            _right_eye_row: right_eye_row,
            _has_glasses: has_glasses,
            _notes: notes
        };

        console.log('screening_addVision');
        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/AddVisionScreeningTest', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    screening_addChroma(student: string,
                        notes: string,
                        doctor: boolean) {
        const postData = {
            _student: student,
            _notes: notes
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/AddChromaScreeningTest', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    screening_addBmi(student: string,
                     weight: string,
                     height: string,
                     bmi_notes: string) {
        const postData = {
            _student: student,
            _weight: weight,
            _height: height,
            _notes: bmi_notes
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/AddBMIScreeningTest', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    screening_addAudio(student: string,
                       left_ear_hz: string,
                       right_ear_hz: string,
                       left_ear_db: string,
                       right_ear_db: string,
                       notes: string,
                       need_doctor: boolean) {
        const postData = {
            _student: student,
            _left_ear_hz: left_ear_hz,
            _right_ear_hz: right_ear_hz,
            _left_ear_db: left_ear_db,
            _right_ear_db: right_ear_db,
            _notes: notes
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/AddAudioScreeningTest', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    screening_reference_create(student: string, testType: string, doctorReferenceNotes: string,
                               test: string, is_urgent: boolean, is_followup: boolean) {

        console.log('screening.service: TEST: ' + test);

        console.log('screening.service: testType: ' + testType);
        console.log('screening_reference_create');
        if (testType == '') {
            const postData = {
                _test: 'OTHER',
                _notes: doctorReferenceNotes,
                data: { student }
            };

            console.log(postData);
            return this.httpService.post(environment.URL_ROOT + '/TReferences', postData)
                .pipe(map(r => {
                    console.log(r[0]);
                    this.doctorReferenceCreated.emit(student);
                    return r[0];
                }));
        } else {
            const postData = {
                _test: test,
                _notes: doctorReferenceNotes,
                // _test: testType,
                _is_urgent: is_urgent,
                _is_followup: is_followup
            };

            console.log(postData);
            return this.httpService.post(environment.URL_ROOT + '/rpc/AddReferralFromScreeningTest', postData)
                .pipe(map(r => {
                    console.log(r);
                    this.doctorReferenceCreated.emit(student);
                    return r;
                }));
        }
    }

    saveDoctorReferenceFile(reference: string, fileName: string, fileType: string, description: string, base64String: string) {
        const postData = {
            _referral: reference,
            _filename: fileName,
            _description: description,
            _blob: base64String
        };

        console.log('saveDoctorReferenceFile:');
        console.log('reference: ', reference);
        console.log('blob: ', base64String);

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/AddReferralFile', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    doctorReferenceAddNote(reference: string, note: string) {
        const postData = {
            _referral: reference,
            _note: note
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/AddReferralNote', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    doctorReferenceUpdateNote(noteId: string, note: string) {
        const postData = {
            _id: noteId,
            _note: note
        };

        console.log(postData);
        return this.httpService.post(environment.URL_ROOT + '/rpc/SetReferralNote', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getDoctorReferenceFiles(reference: string) {
        console.log('getDoctorReferenceFile');
        return this.httpService.get(environment.URL_ROOT + '/GetReferralFiles?referral=eq.' + reference)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                error => {
                    console.log('error during getDoctorReferenceFile: ', error.message);
                    return error.message;
                }));
    }

    closeReference(reference: string) {
        console.log('closeReference: ' + reference);

        const postData = {
            _referral: reference
        };

        return this.httpService.post(environment.URL_ROOT + '/rpc/ReferralClose', postData)
            .pipe(map(r => {
                    console.log(r);
                    this.doctorReferencesUpdated.emit('');
                    return r;
                },
                error => {
                    console.log('error during closeReferral: ', error.message);
                    return error.message;
                }));
    }


    getStudentsScreeningIcons(schoolCode: string) {
        console.log('getStudentsScreeningIcons, schoolCode:' + schoolCode);
        // const postData = {
        //     _students: studentIds
        // };
        //
        // console.log('getStudentsScreeningIcons postData._students.length: ', postData._students.length);
        // console.log(postData);

        let urlParameter = '';
        if (schoolCode != null) {
            urlParameter = '?school=eq.' + schoolCode;
        }

        console.log(urlParameter);

        return this.httpService.get(environment.URL_ROOT + '/getClassTestIcons' + urlParameter)
            .pipe(map(icons => {
                console.log('icons [not printing table], icons length:', icons.length);
//                 console.log(icons);
                return icons;
            }));
    }
}
