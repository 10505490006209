import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {Observable, Subscription} from 'rxjs';
import {ImageBrowserComponent} from '../shared/image-browser/image-browser.component';
import {FilesystemDirectory, FilesystemEncoding, Plugins} from '@capacitor/core';
import {FileData} from '../shared/filedata.model';
import {ResourcesService} from '../../services/resources.service';
import {Location} from '@angular/common';
import {FileUploadStatus} from "../shared/fileuploadstatus.model";
const { Filesystem } = Plugins;

@Component({
    selector: 'app-student-documents',
    templateUrl: './student-documents.component.html',
    styleUrls: ['./student-documents.component.scss'],
})
export class StudentDocumentsComponent implements OnInit, OnDestroy {
    studentId = '';
    message = '';

    fileName = '';
    fileType = '';
    base64String = '';

    description = '';

    studentFiles = [];

    uploadVisible = false;

    isLoading = false;
    isUploaded = false;

    TRANSLATIONS = null;
    private fileLoadedChangedSub: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private coreService: CoreService,
                private modalCtrl: ModalController,
                private location: Location,
                private httpService: HttpService) {

        // location.onUrlChange((url, state) => {
        //     this.ngOnInit();
        // });
    }

    ngOnDestroy() {
        if (this.fileLoadedChangedSub) {
            this.fileLoadedChangedSub.unsubscribe();
        }
    }

    ngOnInit() {
        if (!this.httpService.isLoggedIn()){
            this.router.navigateByUrl('/login');
        }

        this.studentId = this.route.snapshot.paramMap.get('id');

        // this.message = 'Files for studentId: ' + this.studentId;
        this.loadFiles();

        this.fileLoadedChangedSub = this.coreService.fileLoadedChanged.subscribe(file => {
            console.log('StudentDocumentsFileComponent.fileLoadedChangedSub');
            this.uploadFileToDb(this.studentId, file);
        });
    }


    loadFiles() {
        this.coreService.getStudentFiles(this.studentId).subscribe(r => {
            this.studentFiles = r.reverse().slice();
        });
    }

    deleteFile(itemData: any){
        console.log('deleting file');
        this.loadFiles();
        this.coreService.broadcastToastMessage('Deleting file (not implemented)', false);
    }

    uploadFileToDb(studentId: string, uploadedFile: FileData) {
        this.isLoading = true;

        this.coreService.saveStudentFile(
                                            this.studentId,
                                            uploadedFile.file_name,
                                            uploadedFile.file_type,
                                            uploadedFile.file_description,
                                            uploadedFile.base64string).subscribe(r => {
            this.isLoading = false;

            //this.uploadVisible = false;
            setTimeout(() => {
                this.uploadVisible = false;
            }, 2000);

            this.base64String = '';
            this.fileType = '';
            this.fileName = '';
            this.description = '';

            this.loadFiles();
            this.coreService.broadcastFileUploadStatus(new FileUploadStatus(this.fileName, 'File uploaded', true));

        }, err => {
            console.log(err);
            this.coreService.broadcastFileUploadStatus(new FileUploadStatus(this.fileName, 'File upload failed', false));
            this.isLoading = false;
        });
    }

    openImageBrowser(fileId: string) {
        this.modalCtrl
            .create({
                component: ImageBrowserComponent,
                componentProps: { fileId, section: 'student' },
                cssClass: 'bg-none'
            })
            .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
            });
    }
}
