import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {environment} from '../../../../environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-quick-menu',
  templateUrl: './quick-menu.component.html',
  styleUrls: ['./quick-menu.component.scss'],
})
export class QuickMenuComponent implements OnInit {


  platformMobile = true;
  constructor(
      private modalCtrl: ModalController,
      private router: Router,
      private route: ActivatedRoute,
      private platform: Platform,
      private navCtrl: NavController,
      private location: Location) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });

    if (this.platform.is('desktop') || this.platform.is('mobileweb')  ) {
      this.platformMobile = false;
    } else {
      this.platformMobile = true;
    }
  }

  ngOnInit() {}

  loadPage(pageName: string){
    console.log('loading page: ' + pageName);
    this.router.navigateByUrl( '/' + pageName );
  }

  goback() {
    this.location.back();
  }

  openAppInSystemBrowser(){
    const url = environment.WEB_APP_URL;
    window.open(url, '_system', 'location=yes');
  }
}
