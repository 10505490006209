import {Component, Input, OnInit} from '@angular/core';
import {CoreService} from '../../../services/core.service';
import {DatePickerData} from '../datepickerdata.model';
import {SubmitSectionData} from '../submitsectiondata.model';
import {Location} from '@angular/common';

@Component({
  selector: 'app-submit-section',
  templateUrl: './submit-section.component.html',
  styleUrls: ['./submit-section.component.scss'],
})
export class SubmitSectionComponent implements OnInit {


@Input() isDisabled: boolean;
@Input() sectionName: string;
@Input() cancelVisible: boolean;
constructor(
    private location: Location,
    private coreService: CoreService) {

   this.cancelVisible = true;
  // location.onUrlChange((url, state) => {
  //   this.ngOnInit();
  // });
}

ngOnInit() {}

broadcastCancel() {
  console.log('broadcastCancel');
  this.coreService.setSubmitSectionUpdate(new SubmitSectionData(this.isDisabled, true, false, this.sectionName));
}

broadcastSave() {
  console.log('broadcastSave');
  this.coreService.setSubmitSectionUpdate(new SubmitSectionData(this.isDisabled, false, true, this.sectionName));
}

}
