import { Component, ElementRef, ViewChild } from '@angular/core';
import {LoadingController, ModalController, NavController, Platform} from '@ionic/angular';
import {Plugins, Capacitor} from '@capacitor/core';
import {Calendar} from '@ionic-native/calendar/ngx';
import {CalendarComponent} from './components/calendar/calendar.component';
import {QuickMenuComponent} from './components/shared/quick-menu/quick-menu.component';
import {HttpService} from './services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';


import * as jspreadsheet from 'jspreadsheet';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isLoading = true;

  constructor(
    private platform: Platform,
    private calendar: Calendar,
    private httpService: HttpService,
    private translate: TranslateService,
    private router: Router
/*    private splashScreen: SplashScreen,
    private statusBar: StatusBar*/
  ) {
    this.initializeApp();

    translate.setDefaultLang('en');

    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  // https://stackoverflow.com/questions/37069609/show-loading-screen-when-navigating-between-routes-in-angular-2
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }

    if (event instanceof NavigationEnd) {
      //setTimeout(() => {
        this.isLoading = false;
      //}, 3000);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      //setTimeout(() => {
        this.isLoading = false;
      //}, 3000);
    }

    if (event instanceof NavigationError) {
      //setTimeout(() => {
        this.isLoading = false;
      //}, 3000);
    }
  }

  initializeApp() {
    console.log(this.platform);
    console.log('platform android: ',    this.platform.is('android'));
    console.log('platform ios: ',    this.platform.is('ios'));
    console.log('platform desktop: ',    this.platform.is('desktop'));
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
      }
    });

    console.log('checkingAuth...');
  }



}
