import { Component, OnInit } from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {CachingService} from '../../services/caching.service';

import {environment} from '../../../environment';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  simulateFailedPostCached = false;

  URL_ROOT = '';
  AUTH_ROOT_URL = '';

  isStaging = false;

  constructor(
      private modalCtrl: ModalController,
      private router: Router,
      private route: ActivatedRoute,
      private navCtrl: NavController,
      private location: Location,
      private cachingService: CachingService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
    this.URL_ROOT = environment.URL_ROOT;
    this.AUTH_ROOT_URL = environment.AUTH_ROOT_URL;

    if (environment.URL_ROOT.indexOf('sapi') > -1 || environment.AUTH_ROOT_URL.indexOf('spublic') > -1 ) {
      this.isStaging = true;
    }
  }

  ngOnInit() {
    const SFPC_LS_SETTING_VALUE = localStorage.getItem(this.cachingService.SFPC_LS_SETTING_NAME);
    if (SFPC_LS_SETTING_VALUE !== null) {
      if (SFPC_LS_SETTING_VALUE === '1') {
        this.simulateFailedPostCached = true;
      } else if (SFPC_LS_SETTING_VALUE === '0'){
        this.simulateFailedPostCached = false;
      } else {
        localStorage.setItem(this.cachingService.SFPC_LS_SETTING_NAME, '0');
        this.simulateFailedPostCached = false;
      }
    } else {
      localStorage.setItem(this.cachingService.SFPC_LS_SETTING_NAME, '0');
      this.simulateFailedPostCached = false;
    }
  }

  loadPage(pageName: string) {
    console.log('loading page: ' + pageName);
    this.router.navigateByUrl('/' + pageName);
  }

  goback() {
    this.location.back();
  }

  alterSFPC() {
    this.simulateFailedPostCached = !this.simulateFailedPostCached;
    const valueForLs = this.simulateFailedPostCached ? '0' : '1';
    localStorage.setItem(this.cachingService.SFPC_LS_SETTING_NAME, valueForLs);
  }


}
