import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {CoreService} from "../../services/core.service";
import {HttpService} from "../../services/http.service";
import {ResourcesService} from "../../services/resources.service";
import {TranslateService} from "@ngx-translate/core";
import {CachingService} from "../../services/caching.service";
import {Subscription} from "rxjs";
import {Location} from "@angular/common";

@Component({
  selector: 'app-cache-test',
  templateUrl: './cache-test.component.html',
  styleUrls: ['./cache-test.component.scss'],
})
export class CacheTestComponent implements OnInit {
  keyName = 'key1';
  txt1='xx';
  cres = '';
  cres2 = '';

  version = '';
  langCode = '';
  isLoggedIn = false;

  TRANSLATIONS = null;

  constructor(private router: Router,
              private coreService: CoreService,
              private httpService: HttpService,
              private resourcesService: ResourcesService,
              private translate: TranslateService,
              private location: Location,
              private cacheService: CachingService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  private loginStateSub: Subscription;
  private languageSub: Subscription;

  ngOnDestroy() {
    if (this.loginStateSub) {
      this.loginStateSub.unsubscribe();
    }

    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.langCode = this.resourcesService.getLangCode();

    console.log(this.TRANSLATIONS);
    console.log('HOME PAGE ONINIT');
    this.version = this.coreService.version;
    this.isLoggedIn = this.httpService.isLoggedIn();

    this.loginStateSub = this.httpService.loginStateChanged.subscribe( r => {
          console.log('home: received loginState update event: ', r);
          this.isLoggedIn = this.httpService.isLoggedIn();
        }
    );

    this.languageSub = this.httpService.languageChanged.subscribe(l => {
      console.log('home: received languageChanged update event: ', l);
      this.langCode = this.resourcesService.getLangCode();

      this.translate.use(this.langCode);
    });
  }

  loadPage(pageName: string) {
    console.log('loading page: ' + pageName);
    this.router.navigateByUrl('/' + pageName);
  }




  async saveCacheSql1() {
    await this.cacheService.deleteCacheItem(this.keyName);
    console.log('saveCacheSql1');
    await this.cacheService.createCache(this.keyName, this.txt1);
  }


  clearCache() {
    this.cacheService.clearCache();
  }


  async getCacheSql1(){
    this.cacheService.getCacheValue(this.keyName).then(res => {
      console.log(res);
      this.cres = res;
      this.cres2 = res.rows.item(0).data;
    })

  }
}
