import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {VaccineService} from '../../services/vaccine.service';
import {ModalVaccineLotsAddComponent} from '../shared/modal-vaccine-lots-add/modal-vaccine-lots-add.component';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';

@Component({
  selector: 'app-vaccine-lots',
  templateUrl: './vaccine-lots.component.html',
  styleUrls: ['./vaccine-lots.component.scss'],
})
export class VaccineLotsComponent implements OnInit, OnDestroy {

  vaccineLots = [];
  vaccineLotsFiltered = [];

  searchPhrase = '';

  isLoading = false;

  private vaccineLotsAvailableSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private vaccineService: VaccineService,
              private modalCtrl: ModalController,
              private location: Location,
              private httpService: HttpService,
              private changeRef: ChangeDetectorRef,
              private translate: TranslateService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.vaccineLotsAvailableSub) {
      this.vaccineLotsAvailableSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = true;
    this.loadLots();

    this.vaccineLotsAvailableSub = this.vaccineService.vaccineLotsAvailableChanged.subscribe(r => {
      this.loadLots();
    });
  }

  loadLots() {
    this.vaccineService.getVaccineLots().subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.vaccineLots = r;
      this.vaccineLotsFiltered = this.vaccineLots.slice();
      this.changeRef.detectChanges();
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching vaccine lots failed ', err);
      this.isLoading = false;
    });
  }

  filterLots() {
    const f1 = this.vaccineLots.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f2 = this.vaccineLots.filter(x => x.producer.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f3 = this.vaccineLots.filter(x => x.lot_id.toLowerCase().includes(this.searchPhrase.toLowerCase()));

    this.vaccineLots.map(x => {
      x.vaccines.map(v => {
        if (v.toLowerCase().includes(this.searchPhrase.toLowerCase())) {
          f3.push(x);
        }
      });
    });

    const f4 = f1.concat(f2).concat(f3);

    const uniqueItems = [...new Set(f4)];

    this.vaccineLotsFiltered = uniqueItems.slice();
    console.log('lots filtered');
    console.log(this.vaccineLotsFiltered);
  }

  expandVaccineInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'block';
  }

  collapseVaccineInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'none';
  }

  openAddLotModal() {
    this.modalCtrl
        .create({
          component: ModalVaccineLotsAddComponent,
          componentProps: {  showHeader: false  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
