import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CoreService} from "../../services/core.service";
import {ModalController} from "@ionic/angular";
import {Location} from "@angular/common";
import {HttpService} from "../../services/http.service";
import {FileData} from "../shared/filedata.model";
import {FileUploadStatus} from "../shared/fileuploadstatus.model";
import {ImageBrowserComponent} from "../shared/image-browser/image-browser.component";

@Component({
  selector: 'app-student-forms',
  templateUrl: './student-forms.component.html',
  styleUrls: ['./student-forms.component.scss'],
})
export class StudentFormsComponent implements OnInit {
  studentId = '';

  FORMS = [];

  TRANSLATIONS = null;
  private fileLoadedChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private location: Location,
              private httpService: HttpService) {

    // location.onUrlChange((url, state) => {
    //     this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.fileLoadedChangedSub) {
      this.fileLoadedChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.studentId = this.route.snapshot.paramMap.get('id');

    // this.message = 'Files for studentId: ' + this.studentId;
    this.getHomeStudentForms();


  }

  getHomeStudentForms() {
    this.coreService.getHomeStudentForms(this.studentId).subscribe((r: any[]) => {
      console.log('getHomeStudentForms: ' + this.studentId);

      this.FORMS = r;
    });
  }

  openLinkInSystemBrowser(url: string){
    window.open(url, '_system', 'location=yes');
  }

}
