import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../../services/core.service';
import {ModalController, Platform, ToastController} from '@ionic/angular';
import {HttpService} from '../../../services/http.service';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {FileChooser} from '@ionic-native/file-chooser/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {FilesystemDirectory, FilesystemEncoding, Plugins, CameraResultType, Capacitor,
    CameraPhoto, CameraSource } from '@capacitor/core';
import {FileData} from '../filedata.model';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {FileUploadStatus} from '../fileuploadstatus.model';
const { Camera, Filesystem, Storage } = Plugins;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Input() cameraUploadAvailable = true;
  @Input() isDisabled = false;
  @Input() buttonText = '';

  platformMobile = true;

  formName = 'fileUpload' + this.coreService.generateFormNamePostfix();
  subjectId = '';
  message = '';
  fileName = '';
  fileType = '';
  base64String = '';
  description = '';
  subjectFiles = [];
  uploadVisible = false;
  isLoading = false;
  isUploaded = false;

  uploadedFile: FileData;

  DIAG_fn = '';
  DIAG_path = '';
  DIAG_uri = '';

  DIAG_loadingImgMsg = '';


  fileSavingDialogVisible = false;
  fileSavingProgressVisible = false;
  fileSavingResultVisible = false;

  fileSavingResultMessage = '';

  private submitSectionSub: Subscription;
  private fileSavingResultSub: Subscription;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private androidPermissions: AndroidPermissions,
              private fileChooser: FileChooser,
              private platform: Platform,
              private filePath: FilePath) {

      this.buttonText = 'Upload!';
      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });


      console.log('INITIALIZING file-upload component');
      console.log(this.platform);
      console.log('platform android: ',    this.platform.is('android'));
      console.log('platform ios: ',    this.platform.is('ios'));
      console.log('platform desktop: ',    this.platform.is('desktop'));


      // if (this.platform.is('android')) { // using android
      //     this.platformMobile = true;
      // }else{
      //     this.platformMobile = false;
      // }

      if (this.platform.is('desktop') || this.platform.is('mobileweb')  ) {
          this.platformMobile = false;
      } else {
          this.platformMobile = true;
      }

      this.platform.ready().then(() => {
          if (Capacitor.isPluginAvailable('SplashScreen')) {
              Plugins.SplashScreen.hide();
          }
      });
  }

  ngOnDestroy() {
      if (this.submitSectionSub) {
          this.submitSectionSub.unsubscribe();
      }
  }

  ngOnInit() {

      this.fileSavingResultSub = this.coreService.fileSavingResultSub.subscribe((fileSavingRes: FileUploadStatus) => {
           this.fileSavingDialogVisible = false;
           this.fileSavingProgressVisible = false;
           this.fileSavingResultVisible = true;

           this.fileSavingResultMessage = fileSavingRes.message;

           // if (fileSavingRes.uploaded) {
           //
           //
           //
           //  } else {
           //      this.fileSavingDialogVisible = false;
           //      this.fileSavingProgressVisible = true;
           //      this.file
           //  }

      });


      this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
          submitSectionDataItems  => {
              console.log('submitSectionSub: ');
              console.log(submitSectionDataItems);

              const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

              if (submitSectionItem != null) {
                  this.isLoading = true;
                  this.broadcastFile();
              }
          }
      );
  }


  // https://eliteionic.com/tutorials/handling-file-uploads-in-ionic-web/
    onDesktopFileChange(fileChangeEvent) {
        const f = fileChangeEvent.target.files[0];

        console.log(f);


        this.isLoading = true;
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = () => {

            let base64String = reader.result.toString();
            base64String = base64String.replace('data:', '').replace(f.type + ';', '').replace('base64,', '');

            this.uploadedFile = new FileData(f.name, f.type, 'd1', base64String);

            //console.log(this.uploadedFile);

            console.log(reader.result);

            //this.broadcastFile();

            this.isUploaded = true;
            this.isLoading = false;

            this.fileSavingDialogVisible = true;
        };
    }

    public async loadImageFromCamera() {
      this.isLoading = true;
        // Take a photo
        const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 60
        });

        console.log('capturedPhoto.base64String:',  capturedPhoto.base64String);
        console.log('capturedPhoto.base64String:',  capturedPhoto.dataUrl);
        console.log('capturedPhoto.dataUrl:',  capturedPhoto.dataUrl);
        console.log('capturedPhoto.exif:',  capturedPhoto.exif);
        console.log('capturedPhoto.path:',  capturedPhoto.path);
        console.log('capturedPhoto.webPath:',  capturedPhoto.webPath);

        this.DIAG_uri = capturedPhoto.path;

        this.filePath.resolveNativePath(capturedPhoto.path)
            .then(async fileP => {

                // https://stackoverflow.com/questions/48638248/base64-plugin-not-working
                // split file path to directory and file name
                const fn = fileP.split('/').pop();
                console.log('fn: ', fn);

                const filePath = fileP.substring(0, fileP.lastIndexOf('/') + 1);
                console.log('filePath: ', filePath);

                this.DIAG_fn = fn;
                this.DIAG_path = filePath;

               this.loadFile(this.DIAG_path + this.DIAG_fn, this.DIAG_fn, this.DIAG_uri);
/// LOADING WITH BUTTON:
                // try {
                //     console.log('attempting to readFile:');
                //     const contents = await Filesystem.readFile({
                //         path: filePath +  fn,
                //         // directory: FilesystemDirectory.Cache, //FilesystemDirectory.Documents,
                //         // encoding: FilesystemEncoding.UTF8
                //     });
                //
                //     console.log('contents.data:');
                //     console.log(contents.data);
                //     this.base64String = contents.data;
                //
                //     this.fileName = fn;
                //     this.uploadedFile = new FileData(fn, 'image', '', this.base64String);
                //     this.isUploaded = true;
                //     this.isLoading = false;
                // } catch (ex) {
                //     console.log('readFile exception');
                //     console.log(ex.message);
                //     console.log(ex.name);
                //     console.log(ex);
                //     console.log(ex.toString());
                //     this.isLoading = false;
                // }
            })
            .catch(err => {
                console.log('error1:');
                console.log(err);
                console.log(err.message);
                this.isLoading = false;
            });
    }

  async loadImageFromDeviceNEW() {
    // choose your file from the device
    this.isLoading = true;
    this.fileChooser.open().then(uri => {
      console.log('1uri' + JSON.stringify(uri));
      // get file path


      this.DIAG_uri = uri;
      this.filePath.resolveNativePath(uri)
          .then(fileP => {

            // https://stackoverflow.com/questions/48638248/base64-plugin-not-working
            // split file path to directory and file name
              const fn = fileP.split('/').pop();
              console.log('fn: ', fn);

              const filePath = fileP.substring(0, fileP.lastIndexOf('/') + 1);
              console.log('filePath: ', filePath);


              const pathToUse = filePath + fn;

              this.DIAG_fn = fn;
              this.DIAG_path = filePath;

              try {
                  //this.loadFile(pathToUse, fn);
                  this.loadFile(this.DIAG_path + this.DIAG_fn, this.DIAG_fn, this.DIAG_uri);
              } catch (ex) {
                  console.log('readFile exception');
                  console.log(ex.message);
                  console.log(ex.name);
                  console.log(ex);
                  console.log(ex.toString());
                  this.isLoading = false;
              }

          })
          .catch(err => {
            console.log('error1:');
            console.log(err);
            console.log(err.message);
              this.coreService.broadcastToastMessage('Upload failed', true);
            this.isLoading = false;
          });
    })
        .catch(e => {
          console.log('error2:');
          console.log(e.error);
          console.log('uri' + JSON.stringify(e));
          this.isLoading = false;
          this.coreService.broadcastToastMessage('Upload failed', true);
        });
  }

  async loadFile(path: string, fn: string, uri: string) {

      console.log('attempting to readFile:');

      this.DIAG_loadingImgMsg = 'attempting to readFile: ';
      const contents = await Filesystem.readFile({
         // path: path
          path: uri
      });

      console.log('contents.data:');
      console.log(contents.data);
      this.base64String = contents.data;

      this.DIAG_loadingImgMsg = this.DIAG_loadingImgMsg + 'contents.data:' + this.base64String.substring(0, 100);

      this.fileName = fn;
      this.uploadedFile = new FileData(fn, 'image', '', this.base64String);
      this.isUploaded = true;
      this.isLoading = false;

      this.fileSavingDialogVisible = true;
  }

  broadcastFile() {
    if (this.uploadedFile !== null && this.uploadedFile !== undefined) {
      this.isLoading = true;
      this.coreService.registerFile(new FileData(
                                                   this.uploadedFile.file_name,
                                                   this.uploadedFile.file_type,
                                                   this.description,
                                                   this.uploadedFile.base64string));
        this.isLoading = false;

        this.fileSavingDialogVisible  = false;

        this.fileSavingProgressVisible = true;
      //this.coreService.broadcastToastMessage('File uploaded', false);
    } else {
        this.coreService.broadcastToastMessage('File not available', true);
        console.log('file not available');
    }
  }
}
