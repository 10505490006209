export class Event {
    constructor(
        public start_dt: string,
        public end_dt: string,
        public duration: string,
        public type: string,
        public visitor: string,
        public name: string,
        public location: string,
        public notes: string,
        public event: string,
        public editable: boolean
    ) {}
}
