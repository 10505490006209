import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {VisitorService} from '../../services/visitor.service';
import {Location} from '@angular/common';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {CallModalComponent} from '../shared/call-modal/call-modal.component';
import {DocumentsService} from '../../services/documents.service';

@Component({
  selector: 'app-visitor-publicforms-index',
  templateUrl: './visitor-publicforms-index.component.html',
  styleUrls: ['./visitor-publicforms-index.component.scss'],
})
export class VisitorPublicformsIndexComponent implements OnInit, OnDestroy  {

  phoneLists = [];

    formVisible = false;
    formCompleted = false;

    msgOperationResult = '';
    newPhoneListVisible = false;
    isAdded = false;
    formName = 'addPublicForm' + this.coreService.generateFormNamePostfix();
    form_name = '';
    html_tpl_id = '0';
    requires_email = false;
    requires_sms = false;
    email_subject = '';
    is_public = false;
    list_id = '0';



    templatesAvailable = [];
    templatesAll = [];
    templateSelectionMode = false;

    addressListsAvailable = [];
    addressListsAll = [];
    contactlistSelectionMode = false;


  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private visitorService: VisitorService,
              private location: Location,
              private modalCtrl: ModalController,
              private documentsService: DocumentsService,
              private httpService: HttpService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.list_id = this.route.snapshot.paramMap.get('listId');

    if (this.list_id != '0'){
        this.formVisible = true;
    }

    this.formCompleted = false;
    this.loadFormTemplates();
    this.loadAddressLists();

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {

          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null) {
            // if (submitSectionItem.cancelBroadcasted === true) {
            //   this.router.navigateByUrl('/student-details/' + this.studentId);
            // }

            if (submitSectionItem.submitBroadcasted === true) {
              this.onAdd();
            }
          }
        }
    );
  }

  onAdd() {
    this.visitorService
        .addNewPublicForm(this.form_name, this.html_tpl_id, this.requires_email, this.requires_sms, this.email_subject, this.is_public, this.list_id)
        .subscribe(res => {
          this.newPhoneListVisible = false;
          this.msgOperationResult = 'Operation complete!';
       //   this.getVisitorPhoneNumberLists();
          this.coreService.broadcastToastMessage('List added', false);

          this.form_name = '';
          this.html_tpl_id = '0';
          this.requires_email = false;
          this.requires_sms = false;
          this.email_subject = '';
          this.is_public = false;
          this.list_id = '';

          this.formCompleted = true;
          this.formVisible = false;
        }, err => {
          console.log(err);
          this.coreService.broadcastErrorMessage('Adding list failed ', err);
        });
  }

  isFormValid() {

      let res = (this.form_name.length > 0 &&
          this.email_subject.length > 0 && this.html_tpl_id != '0' && this.list_id != '');
      //
      // console.log('res: ' + res);
      // console.log('tpl id: ' + this.html_tpl_id);

      return res;
  }

  showSucessBadge() {
    this.isAdded = true;
    setTimeout(() => {
      this.isAdded = false;
    }, 2000);
  }


    loadFormTemplates() {
        this.documentsService.getFormTemplates(this.documentsService.FORM_TYPES.HTML).subscribe(r => {
           // this.templatesAvailable = r;
            this.templatesAll = r;

            this.hideTemplatesSelection();

            console.log('loadFormTemplates');
            console.log(r);
        });
    }

    loadAddressLists() {
        this.visitorService.getVisitorPhoneNumberLists().subscribe(r => {
            this.addressListsAvailable = r;
            this.addressListsAll = r;

            this.hideContactlistSelection();
            console.log('loadAddressLists');
            console.log(r);
        });
    }

    toggle_email(){
      this.requires_email = !this.requires_email;
    }

    toggle_sms(){
        this.requires_sms = !this.requires_sms;
    }

    toggle_ispublic() {
      this.is_public = !this.is_public;
    }

    hideTemplatesSelection(){

        console.log(this.templatesAvailable);
        if (this.html_tpl_id != '0'){
            console.log('hiding templates');
            this.templatesAvailable = this.templatesAvailable.filter(x => x.template == this.html_tpl_id);
        }

        console.log(this.templatesAvailable);
    }

    selectTemplateAction(template: string) {
      if (this.html_tpl_id == template){

          this.templatesAvailable = this.templatesAll;
          this.templateSelectionMode = true;
      } else {
          this.html_tpl_id = template;
          this.hideTemplatesSelection();
          this.templateSelectionMode = false;
      }
    }





    hideContactlistSelection(){

        console.log(this.addressListsAvailable);
        if (this.list_id != '0'){
            console.log('hiding contact lists');
            this.addressListsAvailable = this.addressListsAvailable.filter(x => x.address_list == this.list_id);
        }

    }

    selectContactlistAction(address_list: string) {
        if (this.list_id == address_list) {

            this.addressListsAvailable = this.addressListsAll;
            this.contactlistSelectionMode = true;
        } else {
            this.list_id = address_list;
            this.hideContactlistSelection();
            this.contactlistSelectionMode = false;
        }
    }
}
