import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {Subscription} from 'rxjs';
import {CallModalComponent} from '../shared/call-modal/call-modal.component';
import {Location} from '@angular/common';

@Component({
  selector: 'app-student-parents',
  templateUrl: './student-parents.component.html',
  styleUrls: ['./student-parents.component.scss'],
})
export class StudentParentsComponent implements OnInit, OnDestroy {
  studentId = '';
  isLoading = false;
  parents = [];
  msgOperationResult = '';
  newParentVisible = false;
  isAdded = false;
  formName = 'addParent' + this.coreService.generateFormNamePostfix();

  first_name = '';
  last_name = '';
  email = '';
  phonePrefix = '';
  phone = '';
  notes = '';

  private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private location: Location,
              private modalCtrl: ModalController,
              private httpService: HttpService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {

    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }
    this.studentId = this.route.snapshot.paramMap.get('id');

    this.loadParents();

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.router.navigateByUrl('/student-details/' + this.studentId);
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.onAddParent();
            }
          }
        }
    );
  }

  onAddParent() {
    this.coreService
        .addParent(this.studentId,
                   this.first_name,
                   this.last_name,
                   this.email,
                   this.phonePrefix,
                   this.phone,
                   this.notes
            )
        .subscribe(res => {
              this.newParentVisible = false;
              this.msgOperationResult = 'Operation complete!';
              this.loadParents();
              this.coreService.broadcastToastMessage('Parent added', false);


              this.first_name = '';
              this.last_name = '';
              this.email = '';
              this.phone = '';
              this.notes = '';
            }, err => {
            console.log(err);
            this.coreService.broadcastErrorMessage('Adding parent failed ', err);
            this.isLoading = false;
        });
  }

  isFormValid() {
    return this.first_name.length > 0 &&
     this.last_name.length > 0 &&
     this.email.length > 0 &&
     this.phone.length > 0 &&
     this.notes.length > 0;
  }

  showSucessBadge() {
    this.isAdded = true;
    setTimeout(() => {
      this.isAdded = false;
    }, 2000);
  }

  loadParents() {
    this.coreService.getParents(this.studentId).subscribe((r: any[]) => {
      console.log('getStudentsTestResult');
      console.log(r[0]);
      this.isLoading = false;
      this.parents = r.reverse().slice();
    });
  }

  showCallOptions(phoneNumber: string, firstName: string, lastName: string) {
    console.log('showCallOptions', phoneNumber);

    this.modalCtrl
        .create({
          component: CallModalComponent,
          componentProps: { phoneNumber, firstName, lastName }
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }

  showEmailOptions(email: string) {
    console.log('showEmailOptions', email);
  }
}
