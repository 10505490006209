import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {DocumentsService} from '../../services/documents.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-documents-forms',
  templateUrl: './documents-forms.component.html',
  styleUrls: ['./documents-forms.component.scss'],
})
export class DocumentsFormsComponent implements OnInit, OnDestroy{
  forms = [];
  formsFiltered = [];
  formType: string;

  searchPhrase = '';

  // private fileLoadedChangedSub: Subscription;
  private documentsChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private documentsService: DocumentsService,
              private changeRef: ChangeDetectorRef) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.documentsChangedSub) {
      this.documentsChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.formType = this.route.snapshot.paramMap.get('formType');
    this.loadFormTemplates();

    this.documentsChangedSub = this.documentsService.documentsChanged.subscribe(studentId => {
        this.loadFormTemplates();
    });
  }

  loadFormTemplates() {
    this.documentsService.getFormTemplates(this.formType).subscribe(r => {
      this.forms = r;
      this.formsFiltered = r;

      console.log('loadFormTemplates');
      console.log(r);

      this.changeRef.detectChanges();
    });
  }

  filterItems() {
    if (this.searchPhrase === '') {
      this.formsFiltered = this.forms;
    } else {
      if (this.formType === this.documentsService.FORM_TYPES.SMS) {
        const f1 = this.forms.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
        const f2 = this.forms.filter(x => x.message.toLowerCase().includes(this.searchPhrase.toLowerCase()));
        const f4 = f1.concat(f2);
        const uniqueItems = [...new Set(f4)];
        this.formsFiltered = uniqueItems.slice();
      } else if (this.formType === this.documentsService.FORM_TYPES.HTML) {
        const f1 = this.forms.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
        const f2 = this.forms.filter(x => x.html.toLowerCase().includes(this.searchPhrase.toLowerCase()));
        const f4 = f1.concat(f2);
        const uniqueItems = [...new Set(f4)];

        this.formsFiltered = uniqueItems.slice();
      }
    }
  }

  editTemplate(id: number) {
    if (this.formType === this.documentsService.FORM_TYPES.HTML) {
      this.router.navigateByUrl('/documents-forms-html-add/' + id);
    } else {
      this.router.navigateByUrl('/documents-forms-sms-add/' + id);
    }
  }

  toggleDetails(arrayIndex: number) {
    console.log('expandDetails');
    const elementId = arrayIndex + '_form';

    console.log('expandDetails');
    const input = document.getElementById(elementId);
    const inputStyle = (input as HTMLInputElement).style;

    if (inputStyle.display === 'block') {
      inputStyle.display = 'none';
    } else {
      inputStyle.display = 'block';
    }
  }
}
