import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {SchoolSelectorComponent} from '../school-selector/school-selector.component';
import {PickerDateTimeComponent} from '../picker-date-time/picker-date-time.component';
import {School} from '../school.model';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../services/core.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {SchoolsService} from '../../../services/schools.service';
import {HttpService} from "../../../services/http.service";
import {IdentityService} from "../../../services/identity.service";

@Component({
  selector: 'app-school-selector-container',
  templateUrl: './school-selector-container.component.html',
  styleUrls: ['./school-selector-container.component.scss'],
})
export class SchoolSelectorContainerComponent implements OnInit, OnDestroy {
  @Input() isMultipleSelection = true;
  @Input() immediateOpen = true;
  @Input() preSelectedSchool = '';

  buttonText = '';

  private selectedSchoolsSub: Subscription;

  constructor(
      private modalCtrl: ModalController,
      private  router: Router,
      private location: Location,
      private coreService: CoreService,
      private httpService: HttpService,
      private identityService: IdentityService,
      private schoolsService: SchoolsService
  ) {

      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });
  }

    ngOnDestroy() {
        if (this.selectedSchoolsSub) {
            this.selectedSchoolsSub.unsubscribe();
        }
    }

  ngOnInit() {
      this.selectedSchoolsSub = this.schoolsService.schoolsSelectedChanged
          .subscribe(
              (selectedSchools: School[]) => {
                  // this.selectedSchools = selectedSchools;
                  console.log('class-selector schoolsSelectedChanged - in subscription response');
                  console.log(selectedSchools);

                  if (selectedSchools.length > 0){
                      const schoolCode = selectedSchools[0].school_code;
                      console.log('SCHOOL_CODE: ', schoolCode);
                      this.buttonText = schoolCode;
                  }
              }
          );


      if (this.immediateOpen === true) {
          this.openClassSelectModal();
      }

      if (this.preSelectedSchool !== '') {
          this.buttonText = this.preSelectedSchool;
      }


      if (!this.httpService.isLoggedIn()) {
      } else {


          this.identityService.profile().subscribe(res => {
              if (res !== null){
                  if (res.length > 0) {
                      this.schoolsService.loadSchools(res[0].username);
                  }
              }
          }, err => {
              console.log(err);
          });
      }
  }

  openClassSelectModal() {
    console.log('openClassSelectModal');

    this.modalCtrl
        .create({
          component: SchoolSelectorComponent,
          componentProps: { isMultipleSelection: this.isMultipleSelection  },
          cssClass: 'bg-none'
        })
        .then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        });
  }
}
