import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoreService} from '../../../services/core.service';
import {ToastController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CommonService} from "../../../services/common.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent implements OnInit, OnDestroy {

  toastMessageChangedSub: Subscription;
  constructor(private coreService: CoreService,
              private location: Location,
              private toastController: ToastController) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.toastMessageChangedSub) {
      this.toastMessageChangedSub.unsubscribe();
    }

  }

  ngOnInit() {
    this.toastMessageChangedSub = this.coreService.toastMessageChanged.subscribe(
        toastData  => {
          console.log('toast-notification.toastMessageChangedSub: ');
          console.log(toastData);
          this.presentToast(toastData.message, toastData.isError);
        }
    );
  }

  async presentToast(toastMessage: string, isError: boolean = false) {

    let colorToUse = 'primary';
    if (isError) {
      colorToUse = 'danger';
    }

    const toast = await this.toastController.create({
      position: 'top',
      message: toastMessage,
      duration: 2000,
      color: colorToUse
    });

    toast.present();
  }
}
