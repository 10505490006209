import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents-reports',
  templateUrl: './documents-reports.component.html',
  styleUrls: ['./documents-reports.component.scss'],
})
export class DocumentsReportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
