import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {ScreeningService} from '../../services/screening.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {School} from '../shared/school.model';
import {Location} from '@angular/common';
import {SchoolsService} from '../../services/schools.service';

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
})
export class SchoolsComponent implements OnInit, OnDestroy {

  schools = [];
  schoolsFiltered = [];
  searchPhrase = '';
  isLoading = false;

  //private schoolsAvailableChangedSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private schoolsService: SchoolsService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private screeningService: ScreeningService,
              private translate: TranslateService) {
    location.onUrlChange((url, state) => {
      this.ngOnInit();
    });
  }

  ngOnDestroy() {
    // if (this.schoolsAvailableChangedSub) {
    //   this.schoolsAvailableChangedSub.unsubscribe();
    // }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = true;

    this.schoolsService.getSchools().subscribe(r => {


      console.log('getSchools');
      console.log(r);
      this.isLoading = false;
      this.schools = r;
      this.schoolsFiltered = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching schools failed ', err);
      this.isLoading = false;
    });

    // this.schoolsAvailableChangedSub = this.schoolsService.schoolsAvailableChanged
    //     .subscribe(
    //         (availableSchools: School[]) => {
    //           this.schools = availableSchools;
    //           this.schoolsFiltered = this.schools.slice();
    //
    //           console.log('schoolsFiltered:');
    //           console.log(this.schoolsFiltered);
    //         }
    //     );
  }

  showStudents(school_code: string) {
    console.log('showStudents');
    this.router.navigateByUrl('/students/school/' + school_code);
  }

  showLectures(school_code: string) {
    console.log('showLectures');
    this.router.navigateByUrl('/calendar/LECTURE/' + school_code);
  }
  // formatSchoolName(name: string) {
  //   let newName = name;
  //   if(name.length >10){
  //     new
  //   }
  // }

  filterItems() {
    //console.log(this.schools);
    const f1 = this.schools.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));
    const f2 = [];
    //const f2 = this.schools.filter(x => x.name.toLowerCase().includes(this.searchPhrase.toLowerCase()));

    // this.schools.map(x => {
    //   x.vaccines.map(v => {
    //     if (v.toLowerCase().includes(this.searchPhrase.toLowerCase())) {
    //       f3.push(x);
    //     }
    //   });
    // });

    const f4 = f1.concat(f2);

    const uniqueItems = [...new Set(f4)];

    this.schoolsFiltered = uniqueItems.slice();
    console.log('screenings filtered');
    console.log(this.schoolsFiltered);
  }

  expandInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'block';
  }

  collapseInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'none';
  }
}
