import { Component, OnInit } from '@angular/core';
import {CoreService} from '../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from "@angular/common";

@Component({
  selector: 'app-screening-menu',
  templateUrl: './screening-menu.component.html',
  styleUrls: ['./screening-menu.component.scss'],
})
export class ScreeningMenuComponent implements OnInit {

  constructor(private coreService: CoreService,
              private route: ActivatedRoute,
              public router: Router,
              private location: Location,
              private httpService: HttpService,
              private translate: TranslateService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }
  }

}
