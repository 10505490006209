import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {ResourcesService} from '../../services/resources.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-student-vbook',
  templateUrl: './student-vbook.component.html',
  styleUrls: ['./student-vbook.component.scss'],
})
export class StudentVbookComponent implements OnInit {
  studentId = '';
  vaccines = [];
  isLoading = false;
  studentData = null;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private location: Location,
              private translate: TranslateService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.studentId = this.route.snapshot.paramMap.get('id');

    this.isLoading = true;

    this.coreService.getStudent(this.studentId).subscribe(r => {
      console.log(r);
      this.studentData = r[0];

      this.isLoading = false;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching getStudent failed ', err);
      this.isLoading = false;
    });

    this.loadVBook();
  }

  loadVBook(){
    this.coreService.getStudentVBookNEW(this.studentId).subscribe(r => {
      console.log(r);
      this.isLoading = false;
      this.vaccines = r;
    }, err => {
      console.log(err);
      this.coreService.broadcastErrorMessage('Fetching student vbook failed ', err);
      this.isLoading = false;
    });
  }

  expandVaccineInfo(itemId: string){
    console.log('expandVaccineInfo');
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'block';
  }

  collapseVaccineInfo(itemId: string) {
    const input = document.getElementById(itemId);
    const inputStyle = (input as HTMLInputElement).style;

    inputStyle.display = 'none';
  }
}
