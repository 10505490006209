import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CoreService} from '../../../services/core.service';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {HttpService} from '../../../services/http.service';
import {Router} from '@angular/router';
import {MessageService} from '../../../services/message.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsCommonService} from '../../../services/translations.common.service';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import {Location} from "@angular/common";

@Component({
  selector: 'app-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss'],
})
export class SmsModalComponent implements OnInit, OnDestroy {
  @Input() phoneNumber: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() sendFrom: string;

  message = '';
  formName = 'smsForm' + this.phoneNumber  + this.coreService.generateFormNamePostfix();


  submitSectionChangedSub: Subscription;
  constructor(private modalCtrl: ModalController,
              private coreService: CoreService,
              private messageService: MessageService,
              private callNumber: CallNumber,
              private clipboard: Clipboard,
              private location: Location,
              private httpService: HttpService,
              private router: Router,
              private translate: TranslateService,
              private translatationsCommonService: TranslationsCommonService,
              private sms: SMS//https://ionicframework.com/docs/native/sms

  ) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.submitSectionChangedSub) {
      this.submitSectionChangedSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }


    this.submitSectionChangedSub = this.coreService.submitSectionsChanged.subscribe(update => {
      update.map(i => {
        if (i.sectionName === this.formName){
          if (i.submitBroadcasted) {
            this.sendSms();
            // this.messageService.sendSms(this.phoneNumber, this.message).subscribe(r => {
            //   this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SENT_SUCCESSFULLY, false);
            // }, err => {
            //   console.log(err);
            //   this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SENDING_FAILED, err);
            // });
          }

          if (i.cancelBroadcasted) {
            this.modalCtrl.dismiss();
          }
        }
      });
    });
  }


  sendSms() {
    console.log('sending sms...' + this.phoneNumber + ' ' + this.message);
    //  this.sms.send(this.phoneNumber, this.message).then(r => {
    //    console.log('sms sending res:');
    //    console.log(r);
    // });

    if (this.sendFrom === 'phone') {
      console.log('sending from phone');
      this.sms.send(this.phoneNumber, this.message)
          .then(() => {
            this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SENT_SUCCESSFULLY, false);
          }, () => {
            this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SENDING_FAILED, 'to number: ' + this.phoneNumber);
          });
    } else {

      console.log('sending from db');
      this.messageService.sendSms(this.phoneNumber, this.message).subscribe(r => {

        console.log('Saving contact feature NOT IMPLEMENTED yet');
        this.coreService.broadcastToastMessage('Saving contact feature NOT IMPLEMENTED yet', true);
      }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage('Sending sms failed ', err);
      });
    }
    this.modalCtrl.dismiss();
  }


  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }


}
